
import {  IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import AppIcon from '@/uicomponents/AppIcon.vue'

export default defineComponent({
    name: 'AppButtonIcon',

    components:{
        IonButton, 
        AppIcon,
    },

    props:{
        id:{
            type: String,
            required: false
        },
        icon:{
            type: String,
            required: true,
        },
        disabled:{
            type:Boolean,
            required:false,
            default: false
        },
        slot:{
            type:String,
            required:false
        },
        color:{
            type: String,
            required: false
        }
    },

    emits:["click"],

    setup(){
        return {
        }
    }
})
