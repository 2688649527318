
import { IonLabel, IonItem, IonList, IonProgressBar, loadingController, modalController,IonButton, IonButtons, isPlatform } from '@ionic/vue';    
import { computed, defineComponent, onMounted } from 'vue';
import { Device, getStatusText, STATUS_WORKING, STATUS_NOTHING, STATUS_PENDING, STATUS_ERROR, STATUS_DONE } from '@/core/interfaces/Device';
import useDeviceStore from '@/core/stores/deviceStore';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import AppPopoverMenu from '@/uicomponents/AppPopoverMenu.vue';
import ModalDeviceCopyItems from '@/views/ModalDeviceCopyItems.vue';
import DeviceService from '@/core/services/DeviceService';
import ModalPreview from '@/views/ModalPreview.vue';
import AppIcon from '@/uicomponents/AppIcon.vue'
import { useI18n } from 'vue-i18n'
import { shortenTitle } from '@/core/utils'

export default defineComponent({
    name: 'DeviceList',

    components: {
        //IonSkeletonText,
        IonLabel,
        IonItem, IonList,
        IonProgressBar,
        //DevicePopoverMenu,
        AppPopoverMenu,
        AppIcon,
        IonButton, IonButtons
    },

    emits:['onItemClick', 'onView', 'onPublish'],
    
    setup(props, context){
        const ismobile = isPlatform('mobile')  
        const deviceStore = useDeviceStore();

        const devices = computed(() =>{ return deviceStore.selected==null? deviceStore.all:[deviceStore.selected] });
        //const devices_status = computed(() =>{ return deviceStore.deviceStatus });
        const deviceSelectedId= computed(() => { return deviceStore.deviceSelectedId });
        const menu_items:AppPopoverMenuItem[]=[
            {title:'menu_properties', key:'view', icon: 'tune', disabled:false  },
            {title:'menu_preview', key:'preview', icon: 'visibility', disabled:false},
            {title:'menu_on', key:'on', icon: 'power_settings_new', disabled: false  },
            {title:'menu_off', key:'off', icon: 'power_settings_new', disabled: false  },
            {title:'menu_restart', key:'restart', icon: 'restart_alt', disabled: false  },
            {title:'menu_publish', key:'publish', icon: 'publish', disabled:false},
            //{title:'menu_copy', key:'copy', icon: 'content_copy', disabled:false},
        ];

        const openPreview = async(device:Device) => {
            const deviceSlides = await deviceStore.fetchItemSlides(device.id_device)
            const modal = await modalController.create({
                component: ModalPreview,
                componentProps: {
                    'slides' : deviceSlides,
                    'title': device.name,
                },
                backdropDismiss:false,
                cssClass: ['modal-preview', ismobile?'mpreview-mobile':'mpreview-desktop']
            });
            modal.present();            
        };

        const { t } = useI18n({ useScope: 'global' })

        const handleCopyItems = async(device:Device) => {
            const loading = await loadingController.create({
                message: t("loader.copy")
            });
            loading.present();            
            //
            const items = await DeviceService.getDeviceContent(device.id_device)
            loading.dismiss()
            const modal = await modalController.create({
                component: ModalDeviceCopyItems,
                componentProps: {
                    'device' : device,
                    'items': items
                },
                cssClass: 'small-modal',
                backdropDismiss:false,
            });
            modal.present();
            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
                const loading = await loadingController.create({
                    message: t("loader.save")
                });
                loading.present();
                await deviceStore.addContentsToDevices(items,data)
                loading.dismiss()
            }
        };

        const handleClickMenu = (menu:string, device: Device) => {
            //console.log(menu, id_device)
            switch (menu) {
                case 'view':
                    context.emit('onView', device.id_device)
                    break;

                case 'publish':
                    context.emit('onPublish', device.id_device)
                    break;

                case 'preview':
                    openPreview(device)
                    break;
                    
                case 'on':
                    DeviceService.updateControl(device.id_device, 'DisplayOn')
                    showLoaderControl(t("loader.on"))
                    break;

                case 'off':
                    DeviceService.updateControl(device.id_device, 'DisplayOff')
                    showLoaderControl(t("loader.off"))
                    break;

                case 'restart':
                    DeviceService.updateControl(device.id_device, 'Reboot')
                    showLoaderControl(t("loader.restart"))
                    break;

                case 'copy':
                    handleCopyItems(device);
                    break;
            
                default:
                    break;
            }
        };

        const showLoaderControl=async(text:string)=>{
            const loading = await loadingController.create({
                message: text,
                duration: 1000 // seg 
            });
            loading.present();
        }
		onMounted(() =>{
            deviceStore.fetchDeviceStatus()
        })
        return {
            ismobile,
            shortenTitle,
            getStatusText,
            STATUS_WORKING,

            STATUS_NOTHING,
            STATUS_PENDING,
            STATUS_ERROR,
            STATUS_DONE,

            deviceStore,
            devices,
            deviceSelectedId,
            //devices_status,
            menu_items,
            handleClickMenu,
        }
    }
});

