
import { defineComponent, ref } from 'vue';
//import { GestureDetail } from '@ionic/core';
    
    export default defineComponent({
        name: 'DroppableArea',
    
        components: {
        },
        props: {
            isEmpty: {
                type: Boolean,
                required: true
            }
        },    
        emits:['onDropp'],
        
        setup(props, context){
            const isDragging = ref(false)
            const droppZone = ref();

            const handleDragover = (e:DragEvent) => {
                e.preventDefault();
                isDragging.value = true;
                droppZone.value.classList.add('dropScreens');
            };
            
            const handleDragLeave = () => {
                isDragging.value = false;
                droppZone.value.classList.remove('dropScreens');
            };

            const isInsideDroppableArea = (x:number, y:number) => {
                if(droppZone.value == null){
                    return false
                }
                const droppableArea = (droppZone.value as HTMLElement).getBoundingClientRect();
                if (x < droppableArea.left || x >= droppableArea.right) {
                    return false;
                }
                if (y < droppableArea.top || y >= droppableArea.bottom) {
                    return false;
                }
                return true;
            };

            const complete = async(ev: DragEvent, data:any) => {
                //console.log('complete', ev)
                droppZone.value.classList.remove('dropScreens');
                if (isInsideDroppableArea(ev.x, ev.y)) {
                    //console.log('emit')
                    context.emit('onDropp',data);
                }
            };
            return {
                droppZone,
                handleDragover,
                handleDragLeave,
                complete
            }
        }
    });
    
    