
import { IonContent, IonButton, IonPopover, IonModal, IonIcon, isPlatform } from '@ionic/vue';
import { ellipsisVertical, } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import AppListMenu from '@/uicomponents/AppListMenu.vue'

export default defineComponent({
    name: 'AppPopoverMenu',

    components:{
        IonContent,
        IonButton, IonPopover, 
        AppListMenu,
        IonIcon,
        IonModal
    },

    props:{
        idMenu:{
            type:  String,
            required: true,
        },
        data:{
            type: [String , Number, Object],
            required: false
        },
        items:{
            type:Array<AppPopoverMenuItem>,
            required: true
        },
    },

    emits: ['onClick'],

    setup(props, context){
        const ismobile = isPlatform('mobile')

        const modalMenuItem= ref()
        const handleMenuClose = () => {
            modalMenuItem.value.$el.dismiss();
        }

        const handleClickMenu = (menu_option:string) => {
            if(ismobile) 
                handleMenuClose()
            context.emit('onClick',menu_option, props.data);            
        }
        return {
            ismobile,
            ellipsisVertical,
            modalMenuItem,
            handleClickMenu
        }
    }
})
