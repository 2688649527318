
import useAuthStore from '@/core/stores/authStore';
import {
  IonSelect, IonSelectOption, IonItem } from '@ionic/vue';
import { defineComponent, } from 'vue';
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TheSelectLang',

  components: {
    IonSelect, 
    IonSelectOption, 
    IonItem
  },

  setup() {
    const class_lang =  {cssClass: 'lang-popover' }
    const { locale } = useI18n({ useScope: 'global' })
		const authStore = useAuthStore();
    locale.value = authStore.language

    const changeLanguage = ($event: any) => {
      const new_lang = $event.detail.value
      //const new_route = this.$route.path.replace("/"+this.$i18n.locale, "/"+new_lang)
      //this.ionRouter.replace(new_route) 
      //this.ionRouter.navigate(new_route, 'forward')
      locale.value = new_lang;
      authStore.setLang(new_lang)
    }
    return{
      changeLanguage,
      class_lang
    }
  }
});
