
import {  IonButton, IonLabel, IonList, IonItem, } from '@ionic/vue';
import { defineComponent } from 'vue';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import AppIcon from '@/uicomponents/AppIcon.vue'

export default defineComponent({
    name: 'AppListMenu',

    components:{
        IonButton,   
        IonList, IonItem, IonLabel,
        AppIcon
    },

    props:{
        items:{
            type:Array<AppPopoverMenuItem>,
            required: true
        }
    },

    emits: ['onClick'],

    setup(props, context){
        const handleClickMenu = (menu:AppPopoverMenuItem) => {
            context.emit('onClick',menu.key);            
        }
        return {
            handleClickMenu
        }
    }
})
