
import {
    IonContent, IonFooter, IonToolbar, IonGrid, IonButtons, IonIcon, IonText,
    IonList, IonItem, IonInput, IonTextarea, 
    IonLabel, IonImg, //IonThumbnail, 
    IonCol, IonRow, modalController, isPlatform
} from '@ionic/vue';
import { defineComponent, ref, reactive } from 'vue';
import { DeviceContent, baseImg, itemsToSlides } from '@/core/interfaces/Content';
import { getStrDateTime, getStrTime, getStrSize, getStrDate, contentIsActive, shortenTitle, bytesToGB } from '@/core/utils';
import { Device, getStatusText } from '@/core/interfaces/Device';
import { AppSegment, AppSlideItem } from '@/core/interfaces/AppComponents';
import AppSegments from '@/uicomponents/AppSegments.vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppModal from '@/uicomponents/AppModal.vue';
import AppSlides from '@/uicomponents/AppSlides.vue';
import { ellipse } from 'ionicons/icons';

export default defineComponent({
    name: 'ModalDeviceView',

    components: {
        IonContent, IonToolbar, IonLabel, IonImg, //IonThumbnail, 
        IonCol, IonRow, IonGrid, IonList, IonItem, IonFooter,
        IonInput, IonTextarea, IonText,
        AppSegments, AppButton, AppModal,
       // AppSlides,
       // IonSelect, IonSelectOption,
        IonButtons, IonIcon
    },

    props: {
        device: {
            type: Object as () => Device,  //Device
            required: true,
        },
        items: {
            type: Array<DeviceContent>,
            required: true,
            default: () => []
        },
    },

    setup(props){
        const imgdefault = "assets/default.png";
        const used_memory_gb =bytesToGB(props.device.used_memory)
        const total_memory = props.device.free_memory + used_memory_gb
        const device_data = ref<{ [key: string]: string }[]>([
            //{"key":"Schedule",          "value": "-"},
            { "key": "serial",      "label": "labels.serial", "value": props.device.serial_number },
            { "key": "mac_address", "label": "labels.mac", "value": props.device.mac_address },
            { "key": "device_model", "label": "labels.model", "value": props.device.device_model },
            { "key": "know_ip",     "label": "labels.ip", "value": props.device.know_ip },
            { "key": "capacity",    "label": "labels.memory", "value": total_memory+' GB' },
            { "key": "free_space", "label": "labels.free_space", "value": props.device.free_memory+' GB' },
            { "key": "screen_size", "label": "labels.resolution", "value": props.device.resolution },
            { "key": "last_connection", "label": "labels.last_conection", "value": props.device.last_connection == 0 ? '-' : getStrDateTime(props.device.last_connection) },
            { "key": "published_date", "label": "labels.publish_date", "value": props.device.published_date == 0 ? '-' : getStrDateTime(props.device.published_date) },
        ]);
        const segments: AppSegment[]=[
            {icon: 'info', value: 'info', title:'m_device_view.segment_info'},
            {icon: 'inventory_2', value: 'content', title:'m_device_view.segment_content', disabled: props.items.length == 0},
            {icon: 'history', value: 'logs', title:'m_device_view.segment_logs', disabled: true}
        ]
        const segment_select = ref('info');
        const set_segment = (t: string) => segment_select.value = t;
        const item_select = ref(props.items[0]);
        const show_item = (item: DeviceContent) => item_select.value = item;
        const edited = ref(false);
        const set_edited = () => edited.value = true;
        const slidesItems :AppSlideItem[]  = itemsToSlides(props.items)
        const total_duration = getStrTime(props.items.reduce((total, x)=> total + x.duration, 0))
        const ismobile = isPlatform('mobile');

        const form = reactive( {
            name: props.device.name,
            description: props.device.description
        });

        const handleSaveData = () => {
            //console.log(this.form)
            if (form.name != '') {
                modalController.dismiss(form, 'save');
            }
        };

        const handleCloseModal = () => {
            return modalController.dismiss(null, 'cancel');
        }
        return { 
            imgdefault,
            ellipse, //icon
            device_data, form,
            segments, segment_select, set_segment,
            item_select, show_item,
            edited, set_edited,
            baseImg,
            getStrTime, getStrSize, getStrDateTime, getStrDate, contentIsActive, shortenTitle,
            slidesItems,
            total_duration,
            ismobile,
            getStatusText,
            handleSaveData,
            handleCloseModal,
        }
    }
})

