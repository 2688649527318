
import {  IonCard, IonCardContent, IonImg, IonLabel } from '@ionic/vue';
import {  defineComponent } from 'vue';
import DraggableItem from '@/view_components/DraggableItem.vue'
import AppIcon from '@/uicomponents/AppIcon.vue'

export default defineComponent({
    name: 'AppCardMedia',

    components:{
        IonCard, IonCardContent, 
        IonImg, IonLabel,
        DraggableItem,
        AppIcon
    },

    props:{
        data:{
            type: Object,
            required: true
        },
        imgSrc:{
            type: String,
            required: true
        },
        label:{
            type: String,
            required: true
        },
        iconEditor:{
            type: Boolean,
            required: false
        },
        duration:{
            type: String,
            required: false
        },
        isVideo:{
            type: Boolean,
            required: false
        },
        validity:{
            type: Boolean,
            required: false,
            default: false
        },
        colorCard:{
            type: String,
            required:false
        },
        //item drag
        dropZoneRef:{
            type: Object,
            required: false
        },
        enableDrag:{
            type: Boolean,
            required: false,
            default: false
        },
        
    },

    emits:['onDblClick', 'onClick', 'onDragEnter'],

    setup(){
        const logoe4u = "assets/logo_small.svg"
        return {
            logoe4u
        }
    }
})
