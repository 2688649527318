import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("canvas", {
    id: "donutchart",
    ref: "donutchart",
    width: _ctx.canvas_width,
    height: _ctx.canvas_width
  }, null, 8, _hoisted_1))
}