
import { defineComponent, onMounted,  } from 'vue';
import { IonPage, IonContent, IonHeader, IonText, useIonRouter } from '@ionic/vue';
import TheManagerHeader from '@/view_components/TheManagerHeader.vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppIcon from '@/uicomponents/AppIcon.vue';
import { useRoute } from 'vue-router';
import { Network } from '@capacitor/network';

interface err_info{
    title: string,
    sub: string,
    text: string,
    btn_text: string,
    link: string,
    class:string
}

export default defineComponent({
    name: 'errorPage',

    components: {
        //IonButton,
        IonPage, IonContent, IonHeader,
        IonText,
        TheManagerHeader,
        AppButton, AppIcon
    },

    setup(){
        const errpages = {
            '404':{
                title: '404',
                sub: 'err_404.sorry',
                text: 'err_404.text',
                btn_text: 'err_404.btn',
                link: '/',
                class:'err-404'
            },
            'network':{
                title: 'err_internet.title',
                sub: 'err_internet.sorry',
                text: 'err_internet.text',
                btn_text: 'err_internet.btn',
                link: '/',
                class:'err-int'
            }
        }
        const route = useRoute()
        const { errtype } = route.params;
        const errparam = typeof errtype == 'string' ? errtype : errtype[0] ? errtype[0] : '404'

        const current_err: err_info = errpages[errparam as keyof typeof errpages];

        const ionRouter = useIonRouter();
		onMounted(async()=>{
        if(errparam=='network'){
            const status = await Network.getStatus();
            if(status.connected){
                ionRouter.navigate('/workspace', 'root', 'replace');
            }
        }
		})
        return {
            current_err
        }
    }
});
