
import useContentStore from '@/core/stores/contentStore';
import {
    IonContent, IonToolbar, IonLabel, IonThumbnail, IonSelect, IonSelectOption,
    IonFooter, IonButtons, IonList, IonItem, IonChip, IonButton, IonText,
    IonRow, IonCol, IonImg, modalController, isPlatform
} from '@ionic/vue';
import { computed, defineComponent, reactive, ref } from 'vue';
import { Content, Folder, baseImg,  order_types, reorderContents, reorderFolders } from '@/core/interfaces/Content';
import { getStrSize, getStrTime, getStrDateTime, shortenTitle } from '@/core/utils';
import TheContentMenuOrder from '@/view_components/TheContentMenuOrder.vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppButtonIcon from '@/uicomponents/AppButtonIcon.vue';
import AppSearchbar from '@/uicomponents/AppSearchbar.vue';
import AppModal from '@/uicomponents/AppModal.vue';
import AppCardMedia from '@/uicomponents/AppCardMedia.vue';
import AppIcon from '@/uicomponents/AppIcon.vue'

export default defineComponent({
    name: 'ModalDeviceAddContent',

    components: {
        IonContent, IonToolbar, IonImg, IonThumbnail,
        IonCol, IonRow, IonLabel,
        IonFooter, IonList, IonItem,
        AppButton, AppButtonIcon, IonButtons,
        AppSearchbar,
        AppModal,
        AppCardMedia,
        IonSelect, IonSelectOption,
        AppIcon, IonText,
        IonChip, IonButton,
        TheContentMenuOrder
    },

    props: {
        device: {
            type: String,
            required: true,
        },
    },

    setup() {
        const ismobile = isPlatform('mobile')
        const folder_img = "assets/folder.png"
        const folder_imgback = "assets/folder_back.png"
        const contentStore = useContentStore();
        const types_filter = computed(() => { return contentStore.getTypesFilter})
        const current_folder=ref('')
        const clickFolder = (folder:string) => current_folder.value = folder;
        const view_type = ref('grid');
        const change_viewtype = () => {
            view_type.value = view_type.value == 'grid' ? 'table' : 'grid';
        };
        const text_search = ref('');
        const set_text_search = (value: string) => {
            text_search.value = value.toLowerCase();
        }
        const type_search = ref('all');
        const set_type_search = (value: string | null | undefined) => {
            type_search.value = !value ? 'all' : value;
        }
        const handleChip = (option: string) =>{
            if(type_search.value == 'all'){
                if(option == 'video') set_type_search('image')
                else  set_type_search('video')
            }else{
                set_type_search('all')
            }
        };
        const order_select = ref('new');
        const set_order_select = (value: string | null | undefined) => {
            order_select.value = !value ? 'new': value;
        }
        const contents_selected = reactive<Content[]>([])
        const filteredContents = computed(() => {
            if(type_search.value == 'folder')
                return []
            let filtered = contentStore.$state.all
            const type_s = type_search.value 
            const text_s = text_search.value
            if (current_folder.value != ''){
                filtered = filtered.filter((content:Content) => content.folder == current_folder.value);
            }
            else if (text_s == '' && type_s == 'all' ){
                filtered = filtered.filter((content:Content) => content.folder == '')
            }
            if(type_s == 'video' || type_s == 'image' )
                filtered = filtered.filter((content:Content) => content.media_type == type_s)
            if(text_s.length > 0) 
                filtered = filtered.filter((content:Content) => content.title.toLowerCase().includes(text_s))
            return reorderContents(filtered, order_select.value)
        })
        const filteredFolders = computed(()=>{
            if (current_folder.value != '' || type_search.value == 'video' || type_search.value == 'image' ){
                return []
            }
            let filtered = contentStore.$state.folders
            if(text_search.value.length > 0) 
                filtered = filtered.filter((folder:Folder) => folder.Name.toLowerCase().includes(text_search.value))
            return reorderFolders(filtered, order_select.value)            
        })

        const handleClickContent = (content: Content) => {
            const index = contents_selected.findIndex(x => x.id_content == content.id_content);
            if (index == -1) { //si no está lo agrega
                contents_selected.push(content);
            } else {
                contents_selected.splice(index, 1);
            }
            //console.log(this.contents_selected)
        };
        const isSelected = (id_content: number) => {
            return contents_selected.findIndex(x => x.id_content == id_content) != -1 ;
        };

        const handleCloseModal = () => {
            return modalController.dismiss([], 'cancel');
        };

        const handleSave = () => {
            //console.log(this.contents_selected)
            return modalController.dismiss(contents_selected, 'save');
        }
        return {
            baseImg,folder_img,folder_imgback,
            ismobile,
            view_type, change_viewtype, handleChip,
            text_search, set_text_search,
            type_search, set_type_search,
            order_select, set_order_select,
            current_folder, clickFolder,
            contents_selected,
            filteredContents,
            filteredFolders,
            handleClickContent,
            isSelected,
            getStrSize, getStrTime, shortenTitle, getStrDateTime,
            types_filter, order_types,
            handleCloseModal,
            handleSave
        }
    }
});

