
import {  IonCheckbox, IonCol, IonRow,  } from '@ionic/vue';
import { computed, defineComponent, } from 'vue';
import { Content, Folder } from '@/core/interfaces/Content';
import { getStrTime, shortenTitle } from '@/core/utils'
import useContentStore from '@/core/stores/contentStore';
//import DraggableItem from '@/view_components/DraggableItem.vue'
import useDeviceStore from '@/core/stores/deviceStore';
import AppPopoverMenu from '@/uicomponents/AppPopoverMenu.vue';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import AppCardMedia from '@/uicomponents/AppCardMedia.vue';

export default defineComponent({
    name: 'ContentGrid',

    components: {
    IonCheckbox,
    //IonSkeletonText,
    IonCol,
    IonRow,
    AppPopoverMenu,
    AppCardMedia,
},

    props: {
        contentList: {
            type: Array<Content>, //Content
            required: true
        },
        folders: {
            type: Array<Folder>,
            required: true
        },
        isOpenFolder:{
            type: Boolean,
            required: false
        },
        menuItems:{
            type: Array<AppPopoverMenuItem>,
            required: true
        },
        folderMenuItems:{
            type: Array<AppPopoverMenuItem>,
            required: true
        }
    },

    emits:['onClickMenu', 'onView', 'onClickFolder', 'onClickMenuFolder'],
    
    setup(props, context){
        const folder_img = "assets/folder.png"
        const folder_imgback = "assets/folder_back.png"
        const contentStore = useContentStore();
        const deviceStore = useDeviceStore();
        const contentDrop = computed(()=>{ return contentStore.$state.dropRef });
        const deviceIsSelect = computed(()=>{ return deviceStore.deviceSelectedId != 0 });
        const check_enable= computed(()=>{ return contentStore.$state.check_enable });
        const handleDragEnter= (content:Content)=>{
            //console.log('is checked',content.check)    
            //si no está check
            if(!content.check){
                //descheckea todos
                contentStore.checkAllContents(false)
            }
        };

        const handleClickCard=(content:Content)=>{
            if(check_enable.value){
                content.check= !content.check
            }
        };
        const handleClickFolder=(folder:Folder)=>{
            if(check_enable.value){
                folder.check= !folder.check
            }
        };

        const handleClickMenu=(menu:string, id_content: number)=>{
            context.emit('onClickMenu', menu, id_content);
        };
        const handleClickMenuFolder=(menu:string, folder: Folder)=>{
            context.emit('onClickMenuFolder', menu, folder);
        };
        return {
            folder_img,folder_imgback,
            contentDrop,
            deviceIsSelect,
            getStrTime, shortenTitle,
            check_enable,
            handleDragEnter,
            handleClickCard,
            handleClickFolder,
            handleClickMenu,
            handleClickMenuFolder
        }
    },
});

