import { AppSlideItem } from "./AppComponents";

export const baseImg = process.env.VUE_APP_URL_API+"/core/webfile/"; 
export const baseEditor = baseImg+"editor/editor-exports/"; 

export const extension_accepted = 'image/png, image/jpeg, .mp4';//.avi, .mov,' //video/x-msvideo video/quicktime 
export const extension_valid= ['png', 'jpg', 'jpeg', 'mp4',/* 'avi', 'mov'*/];
//export const ext_valid_img = ['png', 'jpg', 'jpeg'];
//export const ext_valid_video=['mp4'/* 'avi', 'mov'*/ ];
//export const extension_valid= [...ext_valid_img, ...ext_valid_video]

export interface EditorExports{
    name:     string,
    size:     number,
    updated:  number,
    type:     string,
}

export interface Folder{
	Name:    string,
	Size:    number,
	Updated: number,
    check: boolean
}

export interface Content{
    id_content:     number,
	title:          string,
    description:    string,
	extension:      string ,
	media_type:     string,
    folder:         string,
	mute:           boolean,
    duration:       number,
    validity_start: number,
    validity_end:   number,
    validity_days:  string,
    days:           number[],
    transition:string,
	//IdUsermanager:  number,
	create_date:    number,
	last_update:    number, 
	id_file:        number,
    is_from_editor: boolean,
    local_path:         string,
    local_path_small:   string,
    path_temp: string,
	File:           {
        //remotePath:     string,
        //local_path:         string,
        //local_path_small:   string,
        name:               string,
        weight:             number,
        height:             number,
        width:              number,
        version:            number,
        id_author:          number,
        uploaded:           number,
    },    
    check: boolean
}

export interface  ContentUpdate{
	title:          string,
    description:    string,
	mute:           boolean,
    duration:       number,
    validity_start: number,
    validity_end:   number,
    validity_days:  string,
    days:           number[],
    transition:string,
}

export interface DeviceContent{
    create_date:        number,
    id_content:         number, 
    id_playlist:        number, 
    id_playlist_item:   number, 
    index:              number, 
    duration:           number,
    last_update:        number, 
    Content:            Content,    
    check: boolean
}

export interface ContentUses{
    id_content: number,
    devices:[
        {
            id_device:  number,
            name:       string
        }
    ],
}
/*
export const file_types=[
    {key: 'all', value: 'type_all', disabled:false },
    {key: 'folder', value: 'type_folder', disabled:false},
  //  {key: 'file', value: 'type_files'},
    {key: 'video', value: 'type_video', disabled:false},
    {key: 'image', value: 'type_image', disabled:false},
]*/

export const order_types=[
    {key: 'new', value: 'type_new'},// mas recientes
    {key: 'az', value: 'type_az'},// a-z
    {key: 'za', value: 'type_za'},// z-a
    {key: 'tipo', value: 'type_tipo'},// tipo
    {key: 'peso', value: 'type_peso'},// peso
]

export const itemsToSlides =(items: DeviceContent[]): AppSlideItem[] =>{
    return items.map(x=> ({
        id: x.id_playlist_item, 
        type: x.Content.media_type,
        duration: x.duration,
        url: x.Content.local_path,
        url_small: x.Content.local_path_small,
        transition: x.Content.transition,
    }))
}

export const reorderContents =(items: Content[], order_type: string) =>{
    switch (order_type) {
        case 'new':
            return items.sort((a,b) => a.create_date > b.create_date ? 1 : -1)    
        case 'az':
            return items.sort((a,b) => a.title.localeCompare(b.title))
        case 'za':
            return items.sort((a,b) => b.title.localeCompare(a.title))
        case 'peso':
            return items.sort((a,b) => a.File.weight > b.File.weight ? 1 : -1)
        case 'tipo':
            return items.sort((a,b) => a.media_type == b.media_type ? 
                    a.extension == b.extension ? 
                    a.title.localeCompare(b.title) :
                a.extension.localeCompare(b.extension) :
                a.media_type.localeCompare(b.media_type))
        default: 
            return items;
    }
}

export const reorderFolders =(items: Folder[], order_type: string) =>{
    switch (order_type) {
        case 'new':
            return items.sort((a,b) => a.Updated > b.Updated ? 1 : -1)    
        case 'az':
            return items.sort((a,b) => a.Name.localeCompare(b.Name))
        case 'za':
            return items.sort((a,b) => b.Name.localeCompare(a.Name))
        case 'peso':
            return items.sort((a,b) => a.Size > b.Size ? 1 : -1)
        case 'tipo':
            return items.sort((a,b) => a.Name.localeCompare(b.Name))
        default: 
            return items;
    }
}


/*
export const reorderItems =(items: any[], order_type: string) =>{
    switch (order_type) {
        case 'new':
            return items.sort((a,b) => getDate(a) > getDate(b) ? 1 : -1)    
        case 'az':
            return items.sort((a,b) => getName(a).localeCompare(getName(b)))
        case 'za':
            return items.sort((a,b) => getName(b).localeCompare(getName(a)))
        case 'peso':
            return items.sort((a,b) => getSize(a) > getSize(b) ? 1 : -1)
        case 'tipo':
            return items.sort((a,b) => {
                const type_a = getType(a)
                const type_b = getType(b)
                return type_a == type_b ? (
                    type_a == 'image'?
                     a.extension.localeCompare(b.extension) :
                        getName(a).localeCompare(getName(b)) ) :
                    type_a.localeCompare(type_b)
            })
        default: 
            return items;
    }
}
const getName=(obj: Content|Folder)=>{
    if ('File' in obj)  //es Contenido
        return obj.title
    return obj.Name
}
const getDate=(obj: Content|Folder)=>{
    if ('File' in obj)  //es Contenido
        return obj.create_date
    return obj.Updated
}
const getSize=(obj: Content|Folder)=>{
    if ('File' in obj)  //es Contenido
        return obj.File.weight
    return obj.Size
}
const getType=(obj: Content|Folder)=>{
    if('File' in obj)  //es contenido
        return obj.media_type
    return 'folder'
}*/

export const getPaths = (content: Content) =>{
	let basepath = baseImg + "manager/"
	if (content.folder) {
		basepath += content.folder+"/"
	}
	const path = basepath + content.File.name + "." + content.extension
	const small_path = basepath + content.File.name + "-small.png"
	return {
        path: path, 
        small_path: small_path}
}


export const getImgSize= (path: string)=>new Promise<{h:number, w:number}>(resolve=>{
    const img = new Image
    img.onload= ()=>{
        const height = img.height
        const width =img.width
        URL.revokeObjectURL(img.src)
        resolve({
            h: width, w: height
        })
    };
    img.src=path;
})

export const isExtensionValid=(filename: string): boolean=>{
    const ext =filename.split(".").pop()
    return ext ? extension_valid.includes(ext.toLowerCase()): false
}
