import { Content, DeviceContent, getPaths, itemsToSlides } from "@/core/interfaces/Content";
import { Device, DeviceUpdate, STATUS_DONE, STATUS_ERROR, STATUS_PENDING, STATUS_WORKING } from "@/core/interfaces/Device";
import DeviceService from "@/core/services/DeviceService";
import { defineStore } from 'pinia'
//import { ref, Ref } from "vue";
import { AppSlideItem } from "../interfaces/AppComponents";
import useContentStore from "./contentStore";
import { isConnected } from "../utils";

const time_sleep_publish_status=1000;

export interface State{
    all: Device[]
    selected: Device | null
    deviceItems: DeviceContent[],
    check: boolean,
    check_enable: boolean,
    //deviceStatus:{[key:number]:{publish:string}}
}

export const useDeviceStore=  defineStore('deviceStore',{
    state: (): State => {
        return{
            all: [],
            selected: null,
            deviceItems: [],
            check: false,
            check_enable: false,
            //deviceStatus: {}
        }
    },

    getters:{
        deviceSelectedId:(state): number=>{
            if(state.selected){
                return state.selected.id_device;
            } else{
                return 0
            }
        },
        
        deviceItemsCount:(state): number=>{
            return state.deviceItems.length;
        },

        isAnyChecked:(state):boolean =>{
            return state.deviceItems.some(x=>x.check)
        },

        getItemsChecked:(state):DeviceContent[]=>{
            return state.deviceItems.filter(x=>x.check)
        },

        getContentsNoChecked:(state):DeviceContent[]=>{
            return state.deviceItems.filter(x=>!x.check)
        },
        
        getAllIdName:(state):{id:number, name:string}[]=>{
            return state.all.map(x=> ({'id':x.id_device, 'name': x.name}))
        },

        getItemSlides:(state):AppSlideItem[] =>{            
            return itemsToSlides(state.deviceItems)            
        }

    },

    actions:{
        async fetchDevices(){
            const response = await DeviceService.getDevices();
            this.all = response;
            this.all.map(x=> x.connected = isConnected(x.last_connection));
        },

        async fetchDevicesConnection(){
            const response = await DeviceService.getDevicesConnection();
            this.all.forEach(x=>{
                const connection = response.find(y=> y.id_device == x.id_device)?.last_connection
                if (connection) {
                    x.last_connection = connection
                    x.connected = isConnected(connection)
                }
            })
        },

        fetchDeviceStatus(){
            setInterval(()=>this.fetchDevicesConnection(), 180000)
        },
    
        async fetchDeviceItems(id_device:number){
            const response = await DeviceService.getDeviceContent(id_device);
            response.map(x=> {
                x.check = false
                x.Content.days = x.Content.validity_days.split('').map((d:string)=>Number(d))
                const paths= getPaths(x.Content)
                x.Content.local_path=  paths.path
                x.Content.local_path_small= paths.small_path

            });
            this.deviceItems = response;
        },


        async fetchItemSlides(id_device:number) {
            const deviceItems = await DeviceService.getDeviceContent(id_device);
            return itemsToSlides(deviceItems)
        },
    
        async updateInfo(id_device:number, data: DeviceUpdate){
            const response = await DeviceService.updateDeviceInfo(id_device, data);
            if(response != null){
                this.all.map(x=> {
                    if(x.id_device == id_device){
                        x.name = data.name
                        x.description = data.description
                        if(this.deviceSelectedId == id_device)
                            this.selected = x
                        return true
                    }
                })
            }
        },

        async addContentsToDevices(contents:{id_content:number,duration:number}[], id_devices:number[]){
            const response = await DeviceService.addItemsToDevices(id_devices, contents)
            if(response ){                
                this.setStatusPending(id_devices)
                if(id_devices.includes(this.deviceSelectedId))
                    await this.fetchDeviceItems(this.deviceSelectedId)
            }
        },
    
        async addItemsToDevice(new_items:Content[]){
            const contentStore = useContentStore();
            contentStore.checkAllContents(false)
            const items = this.deviceItems.map(item =>  (
                { 
                    "id_content": item.Content.id_content,
                    "index": item.index,
                    "duration": item.duration
                }
            ));
            new_items.forEach((content:Content, index) =>  {
                items.push({ 
                    "id_content": content.id_content,
                    "index": this.deviceItemsCount + index,
                    "duration": content.duration
                })
            });
            const id = this.deviceSelectedId;
            const response = await DeviceService.updateDeviceContent(id, items);
            if(response){
                response['Items'].map(x=> {
                    x.check = false
                    x.Content.days = x.Content.validity_days.split('').map((d:string)=>Number(d))
                    const paths= getPaths(x.Content)
                    x.Content.local_path=  paths.path
                    x.Content.local_path_small= paths.small_path
                });
                this.deviceItems = response['Items'];
                this.setStatusPending([id])
            }
        },

        async reorderItems(from:number, to:number){
            const item_to= {...this.deviceItems[to], index: from}
            this.deviceItems[to] ={ ...this.deviceItems[from], index: to}
            this.deviceItems[from] = item_to
            const response = await DeviceService.updateDeviceContent(this.deviceSelectedId, [...this.deviceItems]);
            if(response){
                response['Items'].map(x=> {
                    x.check = false
                    const paths= getPaths(x.Content)
                    x.Content.local_path=  paths.path
                    x.Content.local_path_small= paths.small_path
                });
                this.deviceItems = response['Items'];
                this.setStatusPending([this.deviceSelectedId])
            }       
        },

        async removeItemsToDevice(item_ids: number[]){
            const items = this.deviceItems.filter(x => !item_ids.includes(x.id_playlist_item));
            items.forEach((item,index) =>{
                item.index = index
            })
            const id = this.deviceSelectedId;
            const response = await DeviceService.updateDeviceContent(id, items);
            this.check=false
            if(response){
                response['Items'].map(x=> {
                    x.check = false
                    const paths= getPaths(x.Content)
                    x.Content.local_path=  paths.path
                    x.Content.local_path_small= paths.small_path
                });
                this.deviceItems = response['Items'];
                this.setStatusPending([id])
            }       
        },

        async readPublishStatus(id_device:number){
            let status = {
                text:   "Pending",
                number: -1,
            };
            let calls= 0;
            const index = this.all.findIndex(x=>x.id_device == id_device);
            this.all[index].publish_status= STATUS_WORKING
            while (status.text != 'Done' && (status.number > 0 ||calls <10)) {
                status = await DeviceService.getPublishStatus(id_device);
                this.all[index].percentage_number= status.number;
                this.all[index].percentage_text= status.text;
                //this.deviceStatus[id_device].publish = status
                calls+=1
                await new Promise(f => setTimeout(f, time_sleep_publish_status));
            }
            if (status.number == -1 || status.number == 0){
                this.all[index].publish_status= STATUS_ERROR
                this.all[index].percentage_number= -1;
            }
            else if (status.text == 'Done'){
                this.all[index].publish_status= STATUS_DONE
                this.all[index].percentage_number= 100;
            }
        },

        setDeviceSelected(data: Device){
            this.selected= data;
            this.check= false
            this.check_enable= false
        },

        unSelectDevice(){
            this.selected=null
            this.deviceItems= []
            this.check= false
            this.check_enable= false
        },

        checkItem(id : number){
            this.deviceItems.forEach(x=>{
                if(x.id_playlist_item==id){
                    x.check= !x.check;
                    return 0
                }
            })

        },

        checkAllItems(value:boolean){  
            this.deviceItems.forEach(x=>x.check = value)
            //this.check= value          
        },

        changeStateSelect(){
            this.checkAllItems(false);
            this.check_enable= !this.check_enable;
        },

        setStatusPending(ids: number[]){
            this.all.forEach(x=> {
                if( ids.includes(x.id_device)){
                    x.publish_status= STATUS_PENDING
                }
            })
        }

    }
});

export default useDeviceStore;