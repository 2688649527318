import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */

import '@/theme/main.scss';

import 'material-icons/iconfont/material-icons.css';
//import 'material-symbols';
import 'material-symbols/outlined.css';

/*  i18n  */

import { createI18n } from 'vue-i18n';
//
import {en} from '@/locales/en'
import {es} from '@/locales/es'
import pinia from './core/stores';
//import store, { key } from './store';

import { AuthSrc } from './core/directives/auth-src';
import { Thumb } from './core/directives/thumb'

import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/themes/translucent.css'

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'es', 
  globalInjection:true,
  legacy: false, // si no cae en un looop, para composition api  
  messages: {
    en, 
    es
  }, 
},
);

const tooltipConf ={
  directive: 'tooltip', // => v-tippy
  defaultProps: {
    placement: 'bottom',
    allowHTML: false,
    theme: 'translucent',
  }
};

const app = createApp(App)
  .use(IonicVue,  {
    mode: 'md',
    platform: {
      desktop: (win:Window) => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
        return !isMobile;
      },
    }
  })
  .use(router)
  .use(pinia)
  .use(i18n)
  .use(VueTippy, tooltipConf) //tooltip directive
  .directive('auth-src', AuthSrc)
  .directive('thumb', Thumb);

 /* 
router.beforeEach((to, from, next) =>{
  let language = to.params.lang;
  if(!language){
    language = 'es'
  }
  i18n.global.locale locale = language
  next();
})
*/
  
router.isReady().then(() => {
  app.mount('#app');
});

//app.config.compilerOptions.isCustomElement = (tag:string) => tag == 'swiper-cont'

//Vue.config.ignoredElements = ['plastic-']