import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "main-card" }
const _hoisted_2 = { class: "container-img" }
const _hoisted_3 = {
  key: 0,
  class: "videotime"
}
const _hoisted_4 = { class: "card-icons" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_DraggableItem = _resolveComponent("DraggableItem")!
  const _directive_thumb = _resolveDirective("thumb")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "start"),
    _createVNode(_component_DraggableItem, {
      "drop-zone-ref": _ctx.dropZoneRef,
      "drop-data": _ctx.data,
      "disable-drag": !_ctx.enableDrag,
      class: "card_media",
      onOnDragEnter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onDragEnter', _ctx.data)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card, {
          button: true,
          color: _ctx.colorCard,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick'))),
          onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onDblClick')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createVNode(_component_ion_img, {
                  src: _ctx.imgSrc,
                  draggable: "false"
                }, null, 8, ["src"]), [
                  [_directive_thumb, _ctx.data]
                ])
              ]),
              (_ctx.duration)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.duration), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.iconEditor)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.logoe4u,
                      class: "card-editor-icon"
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true),
                (_ctx.validity)
                  ? (_openBlock(), _createBlock(_component_AppIcon, {
                      key: 1,
                      icon: "access_alarm",
                      class: "icon-alarm"
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                  ]),
                  _: 1
                }),
                _renderSlot(_ctx.$slots, "end")
              ]),
              _: 3
            })
          ]),
          _: 3
        }, 8, ["color"])
      ]),
      _: 3
    }, 8, ["drop-zone-ref", "drop-data", "disable-drag"])
  ]))
}