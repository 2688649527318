
import {  IonInput, } from '@ionic/vue';
import { defineComponent, reactive, ref } from 'vue';
import { timeOutline } from 'ionicons/icons';
import { getStrTime } from '@/core/utils';
import { computed } from '@vue/reactivity';

export default defineComponent({
    name: 'AppTimePicker',

    components:{
        IonInput, //IonIcon, 
    },

    props:{
        value:{
            type: Number,
            required: true
        },
        slot:{
            type:String,
            required:false
        },
        disabled:{
            type: Boolean
        }
    },

    emits:["onChange"],

    setup(props, context){
        /*const time_str= getStrTime(props.value)
        const time_arr = time_str.split(':')
        const time = reactive({
            hours: time_arr[0],
            mins: time_arr[1],
            secs: time_arr[2],
        })*/
        const time_value = ref(props.value);
        /*const zero_sixty = []
        for (let index = 0; index < 60; index++){
            const num_str = index < 10 ? '0'+index : index.toString()
            zero_sixty.push({text: num_str, value: index})         
        }

        const time_formatted = computed(()=>{ 
            if(!time_value.value) return undefined
            return getStrTime(time_value.value)
        });*/

        const handleInputChange = (e: CustomEvent) => {
            //const time_arr = e.detail.value.split(':');
            //const value= Number(time_arr[0]) * 3600 + Number(time_arr[1]) * 60 + Number(time_arr[2]);
            const value = Number(e.detail.value)
            time_value.value= value
            context.emit('onChange', value);
        }
        return {
            timeOutline, //icon
           // time,
            time_value,
           // time_formatted,
           // zero_sixty,
            handleInputChange
        }
    }
})
