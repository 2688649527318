
import { IonApp, IonRouterOutlet, isPlatform, IonMenu, } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
//import { Device, DevicePlugin } from '@capacitor/device';
import HttpService from '@/core/services/HttpService';
import { useI18n } from 'vue-i18n';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import TheMobileMenu from '@/view_components/TheMobileMenu.vue'
import { Network } from '@capacitor/network';
import { useIonRouter } from '@ionic/vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonMenu,
    TheMobileMenu
  },

  setup(){
    HttpService.init();
    const {t} = useI18n();
    const ismobile = isPlatform('mobile')
    const ismobileweb = isPlatform('mobileweb')
    if(ismobile &&  !ismobileweb){
      ScreenOrientation.lock({orientation:'portrait'})
    }
    /*------------------------------------------------------------------------ */
    const ionRouter = useIonRouter();
    Network.addListener('networkStatusChange', status =>{
      if(!status.connected){
        ionRouter.navigate('/err/network', 'root', 'replace');
      }else{
        ionRouter.navigate('/dashboard', 'forward', 'replace');
      }
    })
    onMounted(async()=>{
      const status = await Network.getStatus();
      if(!status.connected){
        ionRouter.navigate('/err/network', 'root', 'replace');
      }
    })
    return {
      t,
      ismobile
    }
  },
  
   /*onMounted(){
   
    const device: DevicePlugin = Device;
    device.getLanguageCode().then((res) => {
      console.log('Default lang', res.value);
      if (res.value.includes('-')) {
        const language = res.value.split('-')[0];
        this.$i18n.locale = language;
      } else {
        this.$i18n.locale = res.value;
      }
    })
  }*/
});
