 
import {  defineComponent } from 'vue';
import { IonContent, modalController, IonToolbar, IonButtons, IonButton, isPlatform } from '@ionic/vue';
import AppSlides from '@/uicomponents/AppSlides.vue';
import { AppSlideItem } from '@/core/interfaces/AppComponents';
import AppIcon from '@/uicomponents/AppIcon.vue'

export default defineComponent({
    name: 'ModalPreview',

    components: {
        IonContent,
        IonToolbar, 
        IonButtons, 
        IonButton, 
        AppIcon, 
        AppSlides,
    },

    props:{
        title:{
            type: String,
            required: false
        },
        slides:{
            type:  Array<AppSlideItem>,
            required: true,
        },
    },

    setup(){
        const ismobile = isPlatform('mobile')
        const closeModal = () => {
            return modalController.dismiss([], 'cancel');
        }
        return {
            ismobile,
            closeModal
        }
    }
})

