import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    id: _ctx.id,
    fill: "outline",
    disabled: _ctx.disabled,
    class: "btn-icon",
    color: _ctx.color,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppIcon, { icon: _ctx.icon }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["id", "disabled", "color"]))
}