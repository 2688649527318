
import {  IonDatetime, IonIcon, IonPopover, IonInput,
    DatetimeCustomEvent   } from '@ionic/vue';
import { calendarClearOutline } from 'ionicons/icons';
import { computed } from '@vue/reactivity';
import {  defineComponent, ref, reactive } from 'vue';
import { format } from 'date-fns';
import AppIcon from '@/uicomponents/AppIcon.vue';


export default defineComponent({
    name: 'AppDatePicker',

    components:{
        IonDatetime, IonPopover, 
        IonInput,  
        AppIcon
    },

    props:{
        idPicker:{
            type: String,
            required: true
        },
        value:{
            type: String,
            required: false
        },
        min:{
            type: String,
            required: false
        },
        max:{
            type: String,
            required:false
        },
        slot:{
            type: String,
            required: false
        },
        disabled:{
            type: Boolean
        }
    },

    emits:['onChange'],

    setup(props, context){
        const popover_data= reactive<{event:Event|null,is_open:boolean}>({
            event: null,
            is_open: false
        })
        const datepicker= ref()
        const date_value = ref(props.value)
        const date_formatted = computed(()=>{ 
            if(!date_value.value) return undefined
            return format(new Date(date_value.value), 'dd/MM/yyyy') 
        });

        const handleOpenPopover = (e: Event) => {
            popover_data.event = e;
            popover_data.is_open = true;
        };
        
        const handleClose = (event: Event) => {
            popover_data.event = event;
            popover_data.is_open = false;
            context.emit('onChange', date_value)
        };

        const handleChangeDate = (event:DatetimeCustomEvent  ) => {
            //console.log(event.detail.value)
            const value= event.detail.value
            if(value)
                date_value.value= typeof value == 'string' ? value : value [0];
            handleClose(event)
        };
        return {
            calendarClearOutline,
            datepicker,
            popover_data,
            date_value, 
            date_formatted,
            handleOpenPopover,
            handleClose,
            handleChangeDate
        }
    }
})
