import { Playlist, PlaylistItem, PlaylistUpdate } from "@/core/interfaces/Playlist";
import PlaylistService from "@/core/services/PlaylistService";
import { defineStore } from 'pinia'

interface State{
    all: Playlist[],
    selected: Playlist | null
    items: PlaylistItem[],
    //check: boolean,
    //check_enable: boolean
}

export const usePlaylistStore= defineStore('playlistStore',{
    state: (): State => {
        return {
            all: [],
            selected: null,
            items: [],
        }
    },

    getters:{
        playlistSelectedId:(state): number=>{
            if(state.selected){
                return state.selected.id_playlist;
            } else{
                return 0
            }
        },

        playlistItemsCount:(state): number=>{
            return state.items.length;
        },

        getPlaylistCount: (state): number => {
            return state.all.length;
        },
        
    },

    actions:{
        async fetchPlaylists(){
            const response = await PlaylistService.getPlaylists();
            this.all = response;
        },

        async fetchPlaylistItems(id_playlist:number){
            const response = await PlaylistService.getPlaylistItems(id_playlist);
            this.items = response;
        },

        async createPlaylist(data: PlaylistUpdate){
            const response = await PlaylistService.createPlaylist(data);
            if (response != null){
                this.all = this.all.concat(response);
            }
        },
        async updateInfo(playlist:Playlist, data: PlaylistUpdate){
            const response = await PlaylistService.updatePlaylistInfo(playlist.id_playlist, data);
            if(response!= null){
                this.all.map(x=>{
                    if(x.id_playlist == playlist.id_playlist){
                        x.name = data.name
                        x.shuffle = data.shuffle
                        x.color = data.color
                    }
                })
            }

        },

        setPlaylistSelected(data: Playlist){
            this.selected = data
        }
    

    }
});

export default usePlaylistStore;