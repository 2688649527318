import AuthService from "@/core/services/AuthService";
import { defineStore } from 'pinia'
import { User } from "../interfaces/User";
import router from "@/router";
import { getStrDate, getStrDateTime } from "../utils";
//import { todayUnix } from "../utils";

interface State{
    authenticating: boolean,
    accessToken: string,
    userLogged: User | undefined,
    expiration: number,
    plan_type: number, // 1 = basic, 2 = medio, 3 = pro
    language: string,
    last_login: number
}

export const useAuthStore= defineStore('authStore',{
    state: (): State => {
        const token= AuthService.getToken();
        let user= undefined
        let exp= -1
        let lang = 'en'
        if(token != ''){
            const datatoken = AuthService.jwtDecrypt(token);
            if(!AuthService.tokenExpired(datatoken.exp)){
                user= datatoken.user
                exp= datatoken.exp
                const lang_saved= AuthService.getLang()
                if(lang_saved){
                    lang= lang_saved
                }
            }
        }
        return {
            authenticating: false,
            accessToken: token,
            userLogged: user,
            expiration: exp,
            language: lang,
            last_login: -1,
            plan_type: 1  // <-------------------------------------------
        }
    },

    getters:{
        isLogged:(state)=>{
            return state.accessToken != '' && !AuthService.tokenExpired(state.expiration)
        },

        getUser:(state)=>{
            return state.userLogged
        }
    },

    actions:{
        async logout(){
            await AuthService.logout();
            this.clearAuth()
            router.push({path: '/auth'})
        },

        clearAuth(){
            this.accessToken = ''
            this.userLogged= undefined
            this.expiration= -1
        },

        async setLastConnection(){
            if(this.last_login == -1){
                this.last_login = await AuthService.getLastConnection();
            }
            return this.last_login
        },

        async setAuth(token:string){
            this.accessToken=token;
            let lang = 'en'
            if(token != ''){
                AuthService.saveToken(token);
                const datatoken = AuthService.jwtDecrypt(token);
                lang= datatoken.user.Language.split("-")[0]
                console.log(lang)
                this.userLogged = datatoken.user
                this.expiration = datatoken.exp
                this.language = lang
                //console.log(lang)
                AuthService.saveLang(lang)
            }
        },
        
        setLang(lang:string){
            this.language = lang
            AuthService.saveLang(lang)
        },

    async sesionexpired(){
        const overlays = document.querySelectorAll('ion-loading')
        const overlaysArr = Array.from(overlays) as HTMLIonLoadingElement[];
        overlaysArr.forEach(o => o.dismiss());
        await this.logout();
    }

    }
})
export default useAuthStore;