
import { Content, baseImg, ContentUpdate, getPaths, extension_accepted, } from '@/core/interfaces/Content';
import ContentService from '@/core/services/ContentService';
import {
    IonContent, IonToolbar, IonFooter, IonButtons,
    IonRow, IonCol, IonCheckbox, IonTextarea,  IonCard, IonCardContent,
    IonList, IonItem, IonLabel, IonInput, IonButton, 
    IonTitle, IonImg, modalController, loadingController, isPlatform, InputCustomEvent   
} from '@ionic/vue';
import { defineComponent, onMounted, reactive, ref, } from 'vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppSegments from '@/uicomponents/AppSegments.vue';
import AppModal from '@/uicomponents/AppModal.vue';
import AppDatePicker from '@/uicomponents/AppDatePicker.vue';
import { computed } from '@vue/reactivity';
import { getStrTime, getStrDateTime, getStrSize, todayInMonthISO, todayISO, unixToISO, ISOtoUnix, shortenTitle } from '@/core/utils';
import AppTimePicker from '@/uicomponents/AppTimePicker.vue';
import useDeviceStore from '@/core/stores/deviceStore';
import AppSnackbar from '@/uicomponents/AppSnackbar.vue';
import useContentStore from '@/core/stores/contentStore';
import { AppSegment } from '@/core/interfaces/AppComponents';
import AppDayPicker from '@/uicomponents/AppDayPicker.vue';
import AppIcon from '@/uicomponents/AppIcon.vue'
import { useI18n } from 'vue-i18n'
import CacheService from '@/core/services/CacheService';

export default defineComponent({
    name: 'ModalContentView',

    components: {
        IonContent,
        IonToolbar,
        IonFooter,
        IonTitle,
        IonImg,
        IonCol,
        IonRow,
        IonLabel,
        IonItem,
        IonInput,
        IonButton,
        IonCheckbox,
        AppButton,
        AppSegments,
        AppModal,
        AppDatePicker,
        AppTimePicker,
        IonTextarea,
        AppSnackbar,
        IonList,
        AppDayPicker,
        IonButtons,
        AppIcon,
        IonCard,
        IonCardContent,
    },

    props: {
        content: {
            type: Object as () => Content,
            required: true
        },
        uses: {
            type: Array<number>, //ids de devices
            required: true
        }
    },

    setup(props) {
        const logoe4u= "./assets/icon_logo.png"
        //console.log(props.content)
        const ismobile = isPlatform('mobile')
        const content_show = ref<Content>(props.content);
        const title = shortenTitle(props.content.title, false) + '.' + props.content.extension;
        //const videoThumb= props.content.media_type == 'video' ? CacheService.getCachedTumb(props.content.local_path_small):'';
        const getVideoThumb = (path:string)=>{
            const cachedThumb = CacheService.getCachedTumb(props.content.local_path_small);
            return cachedThumb == null? '': cachedThumb;
        }
        const segments: AppSegment[] = [
            { icon: 'info', value: 'info', title: 'm_content_view.segment_info' },
            { icon: 'settings', value: 'display', title: 'm_content_view.segment_props' },
            {icon: 'animation', value: 'transition', title:'m_content_view.transition', disabled: props.content.media_type=='video'},
        ]
        const transitions=[
            {image:'vector1.png', value: '', title: 'transition.none'},
            {image:'t_slide.png', value: 'Slide_Left', title: 'transition.slide'},
            {image:'t_zoom.png', value: 'Zoom_Out', title: 'transition.zoom'},
            {image:'t_fade.png', value: 'Fade_In', title: 'transition.fade'},
        ] 
        const segment_select = ref('info');
        const set_segment_select = (t: string) => segment_select.value = t;
        const show_snackbar = ref(true);
        const hide_snackbar = () => show_snackbar.value = false;
        const form_state = reactive({
            edited: false,
            edituse: false,
            validname: true,
            errorname:'',
            validity: props.content.validity_start > 0
        })

        const contentStore = useContentStore();
        const contentsFolder= computed(()=>{ return contentStore.getContentCurrentFolder });

        /*------------------form--------------------*/
        // si es 0 entonces hoy
        const start = props.content.validity_start == 0 ? todayISO() : unixToISO(props.content.validity_start)
        // si es 0 entonces hoy + month
        const end = props.content.validity_end == 0 ? todayInMonthISO() : unixToISO(props.content.validity_end)
        const form = reactive({
            title: props.content.title,
            description: props.content.description,
            mute: props.content.mute,
            transition: props.content.transition,
            v_start: start,
            v_end: end,
            v_days: props.content.days,
            duration: props.content.duration,
            //  validity: o no hay, o siempre inicio y fin
        });
        const isFormInvalid = computed(() => {
            return (
                !form_state.validname ||
                (!form_state.edited &&
                    !form_state.edituse) ||
                form.duration < 7
            )
        });

        const checkName= (ev:InputCustomEvent)=>{
            let new_name = ev.target.value
            form_state.errorname=''
            form_state.validname=false;
            if(new_name){ //no vacio
                new_name= new_name.toString().trim();
                if(new_name.length>0){ //no espacios es blanco                    
                    form_state.edited = true
                    if(new_name.charAt(0)=="."){
                        form_state.errorname= 'msg_err.startdot'
                    }
                    else if(new_name.match(/[*:<>|/\\]+/g)){
                        form_state.errorname= 'msg_err.badchars'
                    }
                    else if(contentsFolder.value.some(x => x.title == new_name && x.extension == props.content.extension && x.id_content!= props.content.id_content)){
                        form_state.errorname= 'msg_err.existname'
                    }else{
                        form_state.validname= true

                    }
                    //console.log(new_name)
                }
            }
        }

        const onChangeTransition = (transition:string) => {
            form_state.edited=true
            form.transition = transition
        };

        const changeValidity = () => {
            form_state.edited=true
            form_state.validity = !form_state.validity
            const day_value = form_state.validity ? 1 : 0
            for (let i = 0; i < 7; i++) {
                form.v_days[i] = day_value
                
            }
        };
        const changeValidityStart = (new_date: string) => {
            form.v_start = new_date
            form_state.edited = true
        };
        const changeValidityEnd = (new_date: string) => {
            form.v_end = new_date
            form_state.edited = true
        };
        const changeValidityDays = (value:number, day:number) => {
            form_state.edited = true
            form.v_days[day]= value
        };

        const changeDuration = (time: number) => {
            form_state.edited = true;
            form.duration = time
        };


        const deviceHasContent = (id: number) => props.uses.includes(id)
        const deviceStore = useDeviceStore();
        const devices = deviceStore.getAllIdName.map(x => ({ ...x, checked: deviceHasContent(x.id) }));

        const handleSave = () => {
            //console.log(this.form)
            if (form.title != '') {
                var data: ContentUpdate | null = null
                if (form_state.edited) {
                    data = {
                        title: form.title,
                        description: form.description,
                        mute: form.mute,
                        validity_start: form_state.validity ? ISOtoUnix(form.v_start) : 0,
                        validity_end: form_state.validity ? ISOtoUnix(form.v_end) : 0,                        
                        validity_days: form_state.validity ? form.v_days.reduce((t,x)=>t+x,'') : '0000000',
                        days: form.v_days,
                        duration: form.duration,
                        transition: form.transition
                    }
                }
                var addTo: any[] = []
                if (form_state.edituse)
                    addTo = devices.filter(x => x.checked && !deviceHasContent(x.id))
                //console.log(data)
                //console.log(addTo)
                modalController.dismiss({ form: data, addto: addTo }, 'save');
            }
        };

        const handleCloseModal = () => {
            modalController.dismiss(null, 'cancel');
        };

        //----file------
        const fileUpdate= ref();
        const fileUpdateClick = () => {
            // activa el click del input
            const fileInputElement = fileUpdate.value as HTMLInputElement
            fileInputElement.click();
        };
        const { t } = useI18n({ useScope: 'global' })
        const handleFileUpload = async() =>  {
            const fileInputElement = fileUpdate.value as HTMLInputElement
            console.log(fileInputElement.files)
            if (fileInputElement.files && fileInputElement.files.length > 0) {
                const loading = await loadingController.create({
                message: t("loader.load")
                });
                loading.present();
                const thefile = fileInputElement.files[0]
                await ContentService.updateContentFile(thefile, props.content.id_content, (event: { loaded: number; total: number; }) => {
                    console.log(Math.round((100 * event.loaded) / event.total) / 100)
                }).then(response => {
                    console.log(response)
                    content_show.value = response.data
                    const paths= getPaths(response.data)
                    content_show.value.local_path=  paths.path
                    content_show.value.local_path_small= paths.small_path     
                    const contentStore = useContentStore();
                    contentStore.updateFile(response.data)
                }).catch(err => {
                    console.log(err)
                });
                loading.dismiss();
            }
        };

        const handleDownloadFile = (content: Content) => {
            ContentService.downloadFile(content)
        };

        onMounted(()=>{
           /* if(ismobile){
            // si es mobile primero muestra la imagen y luego la info
                const rowinfo= document.getElementsByClassName('rowInfo')[0]
                const cols = rowinfo.children
                rowinfo.appendChild(cols[0])
            }*/
        })
        return {
            logoe4u,
            ismobile,
            content_show,
            getVideoThumb,
            show_snackbar, hide_snackbar,
            title,
            extension_accepted,
            segments,
            segment_select, set_segment_select,
            baseImg,
            form_state, form,
            isFormInvalid,
            checkName,
            transitions,
            getStrTime, getStrDateTime, getStrSize,
            devices, deviceHasContent,
            fileUpdate,
            onChangeTransition,
            changeValidity,
            changeValidityStart,
            changeValidityEnd,
            changeValidityDays,
            changeDuration,
            handleSave,
            handleCloseModal,
            handleFileUpload,
            fileUpdateClick,
            handleDownloadFile,
            
        }
    },

    methods: {



    }
});

