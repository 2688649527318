
import { DeviceContent, baseImg } from '@/core/interfaces/Content';
import { computed, defineComponent } from 'vue';
import { getStrTime, shortenTitle, getStrSize } from '@/core/utils'
import { IonItem, IonThumbnail, IonList, IonReorder, IonReorderGroup, 
    IonLabel, IonCheckbox, ItemReorderEventDetail, isPlatform} from '@ionic/vue';
import AppPopoverMenu from '@/uicomponents/AppPopoverMenu.vue';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import useDeviceStore from '@/core/stores/deviceStore';
import AppIcon from '@/uicomponents/AppIcon.vue';

export default defineComponent({
    name: 'DeviceContentsList',

    components: {
        IonItem, IonThumbnail, IonList, 
        IonReorder, IonReorderGroup,
        IonLabel,
        AppPopoverMenu,
        IonCheckbox,
        AppIcon
    },

    props: {
        deviceItems: {
            type: Array<DeviceContent>, 
            required: true,
            default: () =>[]
        },
        menuItems:{
            type: Array<AppPopoverMenuItem>,
            required: true
        }
    },

    emits:['onClickMenu', 'onReorder'],
    
    setup(props, context){
        const logoe4u = "assets/logo_small.svg"
        const ismobile = isPlatform('mobile')  
        const deviceStore = useDeviceStore();
        const check_enable= computed(()=>{ return deviceStore.$state.check_enable });

        const handleClickMenu = (menu:string, item: DeviceContent) => {
            context.emit('onClickMenu', menu, item);
        };

        const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
            context.emit('onReorder', event);
        };

        const handleClickCard = (item:DeviceContent) => {
            if(check_enable.value){
                item.check= !item.check
            }
        }
        return {
            ismobile,
            getStrTime, shortenTitle,getStrSize,
            baseImg,
            check_enable,
            logoe4u,
            handleClickMenu,
            handleReorder,
            handleClickCard
        }
    },
});

