import { Content, ContentUpdate, EditorExports, Folder, extension_valid, getPaths, reorderContents,  reorderFolders } from "@/core/interfaces/Content";
import ContentService from "@/core/services/ContentService";
import { defineStore } from 'pinia'
import { ref, Ref } from "vue";
import useDeviceStore from "./deviceStore";
import HttpService from "../services/HttpService";
import { todayUnix } from "../utils";

interface State{
    all: Content[],
    folders: Folder[],
    current_folder: string,
    check: boolean,
    check_enable: boolean
    max_file_size: {[key:string]:number}
    uploading: {file: File, path: string, thumb:string, folder:string, progress: number, abortCtrl: AbortController}[]
    show_sheet: boolean,
    dropRef: Ref<any>  // device content section o playlist section
}

export const useContentStore= defineStore('contentStore',{
    state: (): State => {
        return {
            all: [],
            folders: [],
            current_folder: "",
            check: false,
            check_enable: false,
            max_file_size: {
                'image': 0,
                'video':0
            },
            uploading: [],
          //  thumbs_temp: [],
            show_sheet: false,
            dropRef: ref(),
        }
    },

    getters:{
        getContentCount: (state): number => {
            return state.all.length;
        },

        getUploadingCount: (state): number => {
            return state.uploading.length;
        },

        getUploadingPercent: (state): number => {
            const total= state.uploading.reduce((total, x) => total + x.progress, 0);
            return total/state.uploading.length
        },

        getUploadedCount: (state): number => {
            return state.uploading.filter(x=>x.progress>1).length;
        },

        getUploadedError: (state): number => {
            return state.uploading.filter(x=>x.progress==-1).length;
        },

        isAnyChecked:(state):boolean =>{
            return state.all.some(x=>x.check) || state.folders.some(x=>x.check)
        },

        getContentsChecked:(state):Content[]=>{
            return state.all.filter(x=>x.check)
        },

        getFilesChecked:(state):Folder[]=>{
            return state.folders.filter(x=>x.check)
        },

        getContentsNoChecked:(state):Content[]=>{
            return state.all.filter(x=>!x.check)
        },

        getContentsEditor:(state):Content[] => {
            return state.all.filter(x=> x.is_from_editor )
        },

        getContentCurrentFolder: (state): Content[]=>{
            return state.all.filter((content:Content) => content.folder == state.current_folder);
        },

        /*filteredItems:(state)=>{
            return (text_search:string, type_search: string, order: string)=>{
                let filtered_content = state.all
                let filtered_folder = state.folders
                if(state.current_folder != ''){
                    filtered_content = filtered_content.filter((content:Content) => content.folder == state.current_folder);
                    filtered_folder = []
                }
                //filtra por tipo
                filtered_content = type_search == 'folder' ? [] : state.all
                filtered_folder = ['folder', 'all'].includes(type_search) ? state.folders : []
                if( ['video', 'image'].includes(type_search))
                    filtered_content = filtered_content.filter((content:Content) => content.media_type == type_search)
                // filtra por texto 
                if(text_search.length > 0) {
                    filtered_content = filtered_content.filter((content:Content) => content.title.toLowerCase().includes(text_search))
                    filtered_folder = filtered_folder.filter((folder:Folder) => folder.Name.toLowerCase().includes(text_search))
                }
                //
                if (state.current_folder == '' && text_search== '' && type_search == 'all')
                    filtered_content = filtered_content.filter((content:Content) => content.folder == '')
                return reorderItems([...filtered_content, ...filtered_folder], order)
            }
        },*/

        getTypesFilter:(state) => {
            return [
                {key: 'all', value: 'type_all', disabled:false },
                {key: 'folder', value: 'type_folder', disabled: state.current_folder != ''},
                {key: 'video', value: 'type_video', disabled:false},
                {key: 'image', value: 'type_image', disabled:false},
            ]
        },

        filteredContents:(state) => {
            return (text_search:string, type_search: string, order: string)=>{
                if(type_search == 'folder')
                    return []
                let filtered = state.all
                if (state.current_folder != ''){
                    filtered = filtered.filter((content:Content) => content.folder == state.current_folder);
                }
                else if (text_search== '' && type_search == 'all' ){
                    filtered = filtered.filter((content:Content) => content.folder == '')
                }
                if(type_search == 'video' || type_search == 'image' )
                    filtered = filtered.filter((content:Content) => content.media_type == type_search)
                if(text_search.length > 0) 
                    filtered = filtered.filter((content:Content) => content.title.toLowerCase().includes(text_search))
                return reorderContents(filtered, order)
            }
        },

        filteredFolders:(state) => {
            return (text_search:string, type_search: string, order: string)=>{
                if (state.current_folder != '' || type_search == 'video' || type_search == 'image' ){
                    return []
                }
                let filtered = state.folders
                if(text_search.length > 0) 
                    filtered = filtered.filter((folder:Folder) => folder.Name.toLowerCase().includes(text_search))
                return reorderFolders(filtered, order)
            }
        },
    
    },

    actions:{
        async GetMaxFileSize(){
            const response = await ContentService.getMaxFileSize();
            this.max_file_size = response
            //console.log(response)
        },

        async fetchContents(){
            const response = await ContentService.getContents();
            response.map(x=> {
                x.check = false;
                x.days = x.validity_days.split('').map(x=>Number(x))
                const paths= getPaths(x)
                x.local_path=  paths.path
                x.local_path_small= paths.small_path
            });
            this.all = response;
            const folders_loaded = await ContentService.getFolders();
            folders_loaded.map(x=> x.check = false)
            this.folders = folders_loaded
        },

        async folderCreate(name:string){
            const response = await ContentService.folderCreate(name);
            if(response != null){ 
                const new_folder:Folder ={
                    Name: name,
                    Size: 0,
                    Updated: todayUnix(),
                    check: false
                }
                this.folders = this.folders.concat(new_folder);                 
            }
        },

        async folderRename(foldername:string, new_name:string){
            const response = await ContentService.folderRename(foldername, new_name);
            if(response != null){ 
                await this.fetchContents()
            }
        },

        selectFolder(folder:string){  
            this.current_folder= folder
        },
    
        async updateContent(content:Content, data: ContentUpdate){
            const response = await ContentService.updateContentInfo(content.id_content, data);
            if(response != null){ 
                //si no hay errores
                this.all.map(x=> {
                    if(x.id_content == content.id_content){
                        x.title= data.title;
                        x.description = data.description;
                        x.mute = data.mute;
                        x.validity_start = data.validity_start;
                        x.validity_end = data.validity_end;        
                        x.validity_days =data.validity_days;
                        x.days = x.validity_days.split('').map(x=>Number(x))
                        x.duration= data.duration;        
                        x.transition = data.transition;
                    }
                });
            }
            const deviceStore = useDeviceStore();
            if(deviceStore.selected){                
                await deviceStore.fetchDeviceItems(deviceStore.selected.id_device)
            }
        },
    
        async deleteContents(id_contents: number[], folders: string[]){
            const response_delete = await ContentService.deleteContents(id_contents,folders);
            if(response_delete){
                this.all = this.all.filter(x=> !id_contents.includes(x.id_content) && !folders.includes(x.folder))
                this.folders = this.folders.filter(x=> !folders.includes(x.Name))
                this.check=false
                const deviceStore = useDeviceStore();
                if(deviceStore.selected){
                    await deviceStore.fetchDeviceItems(deviceStore.selected.id_device)
                }
            }
        },

        async uploadFile(index:number){
            const thefile= this.uploading[index].file;
            const ext =thefile.name.split('.').pop()?.toLowerCase()
            if(!ext || !extension_valid.includes( ext )){
                this.uploading[index].progress=-1
            } else{
                const abortctrl = this.uploading[index].abortCtrl;
                ContentService.uploadContent(thefile, this.uploading[index].folder, (event: { loaded: number; total: number; }) =>{
                    this.uploading[index].progress= Math.round((100 * event.loaded) / event.total) / 100
                }, abortctrl).then(response =>{
                    if(response.status == "Success"){
                        //console.log(response)
                        this.uploading[index].progress=2
                        // ----Agregar contenido a la vista
                        response.data.path_temp= this.uploading[index].thumb
                        this.pushContents([response.data])  
                    }else{
                        this.uploading[index].progress=-1
                    }
                }).catch(err=>{
                    //console.log(err)
                    if(err.code!="ERR_CANCELED"){
                        //cancelado por el usuario
                        this.uploading[index].progress=-1
                    }
                });  
            }

        },

        cancelUpload(index:number){
            this.uploading[index].abortCtrl.abort()
            this.uploading[index].progress=-2
        },

        async importFromEditor(files: EditorExports[]){
            const new_contents= await ContentService.importFromEditor(files.map(x=>x.name))
            this.pushContents(new_contents.Created)  
            if(new_contents.Errors)
                console.log('error', new_contents.Errors)
        },

        addFilesUpload(files : File[]){
            const new_uploads= []
            for (let i = 0; i < files.length; i++) {
                const abortctrl = HttpService.getAbortCtrl()
                new_uploads.push({file: files[i], path: URL.createObjectURL(files[i]), thumb: '',folder:this.current_folder, progress: 0,abortCtrl:abortctrl})                
            }
            this.uploading= this.uploading.concat(new_uploads);
            this.show_sheet=true;
        },

        pushContents(data:Content[]){
            data.forEach(x=> {
                x.check = false
                x.days = x.validity_days.split('').map(x=>Number(x))
                const paths= getPaths(x)
                x.local_path=  paths.path
                x.local_path_small= paths.small_path
            });
            this.all = this.all.concat(data);
        },

        checkContent(id : number){
            this.all.forEach(x=>{
                if(x.id_content==id){
                    x.check= !x.check;
                    return 0
                }
            })

        },

        checkAllContents(value:boolean){  
            this.all.forEach(x=>x.check = value)
            this.folders.forEach(x=>x.check = value)
            //this.check= value          
        },

        changeStateSelect(){
            this.checkAllContents(false);
            this.check_enable= !this.check_enable;
        },

        closeUploadSheet(){
            this.show_sheet = false;
            this.uploading = [];
        },

        updateFile(content:Content){
            this.all.map(x=> {
                if(x.id_content == content.id_content){
                    x.mute = content.mute;   
                    x.extension = content.extension;   
                    x.media_type = content.media_type;   
                    x.duration= content.duration;   
                    x.File= content.File;       
                    const paths= getPaths(x)
                    x.local_path=  paths.path
                    x.local_path_small= paths.small_path     
                }
            });
        },

        setDropRef(dropref:any){
            this.dropRef=dropref;
        },

    }
});

export default useContentStore;