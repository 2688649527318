
import { computed, defineComponent, onMounted, ref } from 'vue';
import { IonToolbar, IonButtons, IonLabel,IonList, IonItem, IonRow, loadingController, modalController,
    IonReorderGroup, IonReorder, IonThumbnail} from '@ionic/vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppIcon from '@/uicomponents/AppIcon.vue';
import AppPopoverMenu from '@/uicomponents/AppPopoverMenu.vue';
import usePlaylistStore from '@/core/stores/playlistStore';
import { Playlist } from '@/core/interfaces/Playlist';
import ModalPlaylist from '@/views/ModalPlaylist.vue';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import useContentStore from '@/core/stores/contentStore';
import DroppableArea from '@/view_components/DroppableArea.vue'
import { Content, baseImg } from '@/core/interfaces/Content';
import { getStrTime, shortenTitle } from '@/core/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    name: 'ThePlaylistSection',

    components: {
        IonToolbar,
        IonButtons,
        IonList, IonItem,
        IonLabel,
        AppButton,
        AppIcon,
        AppPopoverMenu,
        DroppableArea,
        IonRow,

        IonReorderGroup, 
        IonReorder, 
        IonThumbnail
    },

    setup(){
        const logoe4u = "assets/logo_small.svg"
        const menu_items: AppPopoverMenuItem[]=[            
            {title:'menu_properties', key:'view', icon: 'tune', disabled:false  },
        ]
        const dropRef= ref(null)
        const contentStore = useContentStore();
        const contentsSelected = computed(()=>{ return contentStore.getContentsChecked });
        const playlistStore = usePlaylistStore();
        const playlists_loaded = ref(false);        
        const playlists = computed(() =>{ return playlistStore.all });
        const items = computed(()=>{ return playlistStore.items });
        const { t } = useI18n({ useScope: 'global' })

        const handlePlaylistClick = async(playlist: Playlist) => {
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            playlistStore.setPlaylistSelected(playlist);
            await playlistStore.fetchPlaylistItems(playlist.id_playlist);
            loading.dismiss();
        };

        const handleClickMenu = (menu:string, playlist: Playlist) => {
            switch (menu) {
                case 'view':
                    openModalPlaylist(playlist)
                    break;
                default:
                    break;
            }
        };

        const openModalPlaylist = async(playlist: Playlist | null) => {
            const modal = await modalController.create({
                component: ModalPlaylist,
                componentProps: {
                'playlist' : playlist,
                },
                cssClass: 'big-modal',
                backdropDismiss:false
            });
            modal.present();
            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
                const loading = await loadingController.create({
                message: t("loader.save")
                });
                loading.present();
                //console.log(data)
                if(playlist == null){// crear
                    await playlistStore.createPlaylist(data);
                }
                else{ //actualizar
                    await playlistStore.updateInfo(playlist, data);
                }
                loading.dismiss();
            }
        };
        
        const handleDrop = async(content:Content) => {
            console.log('drop',content)
            /*let contents_to_add= []
            //si hay varios checks 
            if(contentsSelected.value.length>0){
                //entonces los agrega
                contents_to_add=[...contentsSelected.value]
            } else{
                //sino hay cheks
                // agrega el contenido
                contents_to_add=[content]
            }*/
        }

        onMounted(()=>{
            contentStore.setDropRef(dropRef.value)
        })

        const loadPlaylists = async() =>{
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            await playlistStore.fetchPlaylists();
            loading.dismiss()  
        }
        if(playlistStore.getPlaylistCount==0){
            loadPlaylists()
        }
        playlists_loaded.value = true;  
        return {
            menu_items,
            playlists,
            contentsSelected,
            dropRef,
            items,
            baseImg,
            logoe4u,
            playlists_loaded, 
            getStrTime, shortenTitle,
            handlePlaylistClick,
            handleClickMenu,
            openModalPlaylist,
            handleDrop
        }
    }

})
