import { Content } from "./Content"

export interface Playlist{
    id_playlist:    number,
    remote_id:      number,
    id_user_manager: number,
    name:           string,
    playlist_type:  string,
    color:          string,
    shuffle:        boolean,
    mute:           boolean,
    total_duration: number,
    total_size:     number,
    create_date:    number,
    last_update:    number
}

export interface PlaylistUpdate{
    name:       string,
    shuffle:    boolean,
    mute:       boolean,
    color:      string,
}

export interface PlaylistItem{
    id_playlist_item:   number,
    remote_id:          number,
    id_playlist:        number,
    Content:            Content,   
    index:              number, 
}

export const playlist_types=[
    'seq', 'sub'
]

