 
import { computed, defineComponent, onMounted, ref } from 'vue';
import { IonToolbar,  IonButtons,  IonRow, IonRefresher, IonRefresherContent,
    loadingController, modalController, isPlatform, RefresherCustomEvent, } from '@ionic/vue';
import { baseImg, getPaths,  } from '@/core/interfaces/Content';
import { Device } from '@/core/interfaces/Device';
import DeviceService from '@/core/services/DeviceService';
import DeviceList from '@/view_components/DeviceList.vue';
import ModalDeviceViewVue from '@/views/ModalDeviceView.vue';
import useDeviceStore from '@/core/stores/deviceStore';
import TheDeviceSectionContents from './TheDeviceSectionContents.vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppButtonIcon from '@/uicomponents/AppButtonIcon.vue';
//import TheMobileMenuToggle from '@/view_components/TheMobileMenuToggle.vue';
import ModalPreview from '@/views/ModalPreview.vue';
import { useI18n } from 'vue-i18n'

export default defineComponent({
    name: 'TheDeviceSection',

    components: {
    IonToolbar,
    IonButtons,
    IonRow,
    DeviceList,
    TheDeviceSectionContents,
    //AppButton,
    AppButtonIcon,
    IonRefresher, IonRefresherContent,
    //TheMobileMenuToggle
},

    setup(){
        const deviceStore = useDeviceStore();
        const devices_loaded = ref(false);        
        const publish_status = ref('');
        const deviceSelected = computed(()=>{ return deviceStore.selected });
        const deviceHasItems = computed(()=>{ return deviceStore.deviceItemsCount >0 });
        const idpreview='open-preview';

        const ismobile = isPlatform('mobile')       
        const { t } = useI18n({ useScope: 'global' }) 

        const handleSelectDevice = async(device: Device) => {
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            if(deviceSelected.value==null){
                deviceStore.setDeviceSelected(device);
                await deviceStore.fetchDeviceItems(device.id_device);
            }else{
                await deviceStore.unSelectDevice();
            }
            loading.dismiss();
        };

        const handleOpenPreview = async() => {
            const deviceSlides = computed(()=>{ return deviceStore.getItemSlides });
            const modal = await modalController.create({
                component: ModalPreview,
                componentProps: {
                    'slides' : deviceSlides.value,
                    'title': deviceSelected.value?.name
                },
                backdropDismiss:false,
                cssClass: ['modal-preview', ismobile?'mpreview-mobile':'mpreview-desktop']
            });
            modal.present();
        };

        const handleDeviceView = async(id_device: number| null) => {
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            if(id_device == null){
                id_device = deviceStore.deviceSelectedId
            }
            const response = await DeviceService.getDeviceInfo(id_device);
            response.items.map(x=> {
                x.check = false
                const paths= getPaths(x.Content)
                x.Content.local_path=  paths.path
                x.Content.local_path_small= paths.small_path

            });
            loading.dismiss();
            const modal = await modalController.create({
                component: ModalDeviceViewVue,
                componentProps: {
                    'device' : response.info,
                    'items': response.items, 
                    'backButton': ismobile
                },
                cssClass: 'big-modal properties',
                backdropDismiss:false
            });
            modal.present();
            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
                const loading = await loadingController.create({
                message: t("loader.save")
                });
                loading.present();
                //console.log(data)
                await deviceStore.updateInfo(id_device, data);
                loading.dismiss();
            }
        };

        const handlePublishDevice = async() => {
            handlePublish(deviceStore.deviceSelectedId);
        };

        const handlePublish = async(id_device: number) => {
            const response = await DeviceService.publishDevice(id_device);
            console.log(response);     
            //consultar estado de carga
            deviceStore.readPublishStatus(id_device);
        };

        const handleRefresh = async(event: RefresherCustomEvent) => {
            await deviceStore.fetchDevices();
            //await loadContents()
            event.detail.complete();
        }

        const loadDevices = async() =>{
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            await deviceStore.fetchDevices();
            loading.dismiss()  
        }
		onMounted(async() =>{
            if(deviceStore.deviceItemsCount==0){
                loadDevices();
                devices_loaded.value = true;  
            }
        })
        return {
            devices_loaded,
            baseImg,
            publish_status,
            deviceStore,
            deviceSelected,
            deviceHasItems,
            idpreview,
            ismobile,
            handleSelectDevice,
            handleOpenPreview,
            handleDeviceView,
            handlePublishDevice,
            handlePublish,
            handleRefresh
        }
    }
});

