
import { defineComponent } from 'vue';
import {  IonButton, IonMenuToggle, } from '@ionic/vue';
import { menu, } from 'ionicons/icons';
import AppIcon from '@/uicomponents/AppIcon.vue';
    
export default defineComponent({
    name: 'TheMobileMenuToggle',

    components: {
    IonButton,
    IonMenuToggle,
    AppIcon
},

    setup(){
        const logoe4u = "assets/logoeyecatch.png"
        return{
            menu,
            logoe4u
        }
    },
});

