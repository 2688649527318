import { add, format, formatISO, formatISO9075, fromUnixTime, getUnixTime } from 'date-fns';

export const getStrSize= function(bytes: number): string{
    if (bytes < 1000) {
        return bytes + " bytes"
    }else if(bytes < 1000000){
        return (Math.round(bytes/10) /100)  + " Kb"
    } else{
        return (Math.round(bytes/10000)/100) + " Mb"
    }
}

export const bytesToGB = function(bytes: number): number{
    return (Math.round(bytes/1000000)/1000)
}

export const shortenTitle= (title: string, very_short: boolean): string => {
    if(very_short)
        return title.length < 12 ? title : title.substring(0, 12)+'..'
    else
        return title.length < 32 ? title : title.substring(0, 32)+'..'
}


/*--------------------- TIME ------------------------- */

export const getStrDateTime= (date: number): string =>
    format(fromUnixTime(date), 'dd/MM/yyyy - HH:mm:ss')

export const getStrDate= (date: number): string =>
    format(fromUnixTime(date), 'dd/MM/yyyy')

export const getStrTime= (time:number): string =>{
    //('HH:mm:ss')
    if(time)
        return formatISO9075(new Date(2020, 7, 1, 0, 0, time), { representation: 'time' })
    return "0"
    }


export const unixToDate= (time:number): Date =>  
    new Date(time * 1000);

export const unixToISO= (time:number): string =>  
    formatISO(fromUnixTime(time));

export const ISOtoUnix = (iso: string): number =>
    getUnixTime(new Date(iso))

export const todayUnix = (): number =>
    getUnixTime(new Date())

export const todayISO= (): string =>
    formatISO(new Date())

export const todayInMonthISO= (): string =>
    formatISO(add(new Date(), { months: 1}))

export const contentIsActive= (start:number, end:number): boolean =>
{
    if (start == 0) return true
    const today = getUnixTime(new Date())
    return today < end && today > start 
}


export const isConnected = (last_conection: number) =>{
    const connected= (todayUnix() - last_conection)<180 // menor 3 min
    return connected
}

