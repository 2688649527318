
import { IonThumbnail,  IonCheckbox, IonList, IonItem, IonLabel} from '@ionic/vue';
import { computed, defineComponent } from 'vue';
import { Content, Folder, baseImg } from '@/core/interfaces/Content';
import { getStrTime,getStrDateTime, getStrSize, shortenTitle } from '@/core/utils'
import AppPopoverMenu from '@/uicomponents/AppPopoverMenu.vue';
import useContentStore from '@/core/stores/contentStore';
import useDeviceStore from '@/core/stores/deviceStore';
import DraggableItem from '@/view_components/DraggableItem.vue'
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import AppIcon from '@/uicomponents/AppIcon.vue'

export default defineComponent({
    name: 'ContentList',

    components: {
        IonCheckbox, IonThumbnail,  
        IonList, IonItem, IonLabel,      
        AppPopoverMenu,
        DraggableItem,
        AppIcon
    },

    props: {
        contentList: {
            type: Array<Content>,
            required: true
        },
        folders:{
            type: Array<Folder>,
            required: true
        },
        isOpenFolder:{
            type: Boolean,
            required: false
        },
        menuItems:{
            type: Array<AppPopoverMenuItem>,
            required: true
        },
        folderMenuItems:{
            type: Array<AppPopoverMenuItem>,
            required: true
        }
    },
    
    emits:['onClickMenu', 'onView', 'onClickFolder', 'onClickMenuFolder'],
    
    setup(props, context){
        const folder_img = "assets/folder.png"
        const folder_imgback = "assets/folder_back.png"
        const logoe4u = "assets/logo_small.svg"
        const contentStore = useContentStore();
        const deviceStore = useDeviceStore();
        const contentDrop = computed(()=>{ return contentStore.$state.dropRef });
        const deviceIsSelect = computed(()=>{ return deviceStore.deviceSelectedId != 0 });
        const handleDragEnter=(content:Content)=>{
            //console.log('is checked',content.check)
            //si no está check
            if(!content.check){
                //descheckea todos
                contentStore.checkAllContents(false)
            }
            console.log('init drag')
        };

        const check_enable= computed(()=>{ return contentStore.$state.check_enable });
        const handleClickItem = (content:Content)=>{
            if(check_enable.value){
                content.check= !content.check
            }
        };
        const handleClickFolder= (folder:Folder)=>{
            if(check_enable.value){
                folder.check= !folder.check
            }
        };

        const handleClickMenu = (menu:string, id_content: number)=>{
            context.emit('onClickMenu', menu, id_content);
        };
        const handleClickMenuFolder=(menu:string, folder: Folder)=>{
            context.emit('onClickMenuFolder', menu, folder);
        };
        return {
            folder_img, folder_imgback,
            logoe4u,
            baseImg,
            contentDrop,
            deviceIsSelect,
            getStrSize, getStrTime,getStrDateTime, shortenTitle,
            check_enable,
            handleDragEnter,
            handleClickItem,
            handleClickFolder,
            handleClickMenu,
            handleClickMenuFolder
        }
    },
});

