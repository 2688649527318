
import useContentStore from '@/core/stores/contentStore';
import AppBottomSheet from '@/uicomponents/AppBottomSheet.vue';
import { IonList, IonItem, IonLabel, IonThumbnail, IonProgressBar,
    IonButton, isPlatform, alertController } from '@ionic/vue';
import { computed } from '@vue/reactivity';
import { defineComponent, onUnmounted, } from 'vue';
import { baseImg, getImgSize } from '@/core/interfaces/Content';
import AppIcon from '@/uicomponents/AppIcon.vue'
import { useI18n } from 'vue-i18n';
import { shortenTitle } from '@/core/utils'
    
    export default defineComponent({
        name: 'ContentUploadSheet',
    
        components: {
            IonList,
            IonItem,
            IonLabel,
            IonThumbnail,
            AppBottomSheet,
            IonProgressBar,
            IonButton,
            AppIcon
        },

        setup(){  
            const ismobile = isPlatform('mobile')
            const contentStore = useContentStore();
            const files= computed(()=>{ return contentStore.$state.uploading });
            const uploaded = computed(()=>{ return contentStore.getUploadedCount })
            const upload_error = computed(()=>{ return contentStore.getUploadedError })
            const total = computed(()=>{ return contentStore.getUploadingCount });
            const total_percent = computed(()=>{ return contentStore.getUploadingPercent });
            const show_sheet = computed(()=>{ return contentStore.show_sheet });
            
            const finisherr = computed(()=>{ return contentStore.uploading.filter(x=>x.progress>1 || x.progress<=-1).length })

            const handleRetry = (index:number) =>{
                contentStore.uploadFile(index)
            }
            const handleCancel = (index:number) => {
                contentStore.cancelUpload(index)
            }
            const handleClose = () => {
                files.value.forEach(x=>{
                    URL.revokeObjectURL(x.path)
                })
                contentStore.closeUploadSheet()
            }
            const { t } = useI18n({ useScope: 'global' })
            const checkSize= async(h:number, w:number, name:string) => {
                const sides  = h > w ? [h,w] : [w,h]
                if(sides[0]<1280 || sides[1] <720 || sides[0]>4096 || sides[1]>2160){
                    const alert = await alertController.create({
                        //header: t('content_section.delete_title'),
                        message:  t('content_section.size_alert', {name: name}),
                        buttons: [t('button_ok')]
                    });
                    await alert.present();
                }
                //console.log('min', 1280,720)
                //console.log(sides)
                //console.log('max',4096,2160)

            }
            const onLoadedImg=async (item:any, index:number)=>{
                const {h, w} = await getImgSize(item.path)
                checkSize(h,w,item.file.name)
                //make thumbnail temp
                const img_el = document.getElementById('img-'+index) as HTMLImageElement;
                createThumbTemp(img_el, index)
            }
            const onLoadedVideo=(e: any, index:number)=>{
                const h= e.target.videoHeight
                const w = e.target.videoWidth
                checkSize(h, w, e.target.title)
                //make thumbnail temp
                const video_el = document.getElementById('video-'+index) as HTMLVideoElement;
                createThumbTemp(video_el, index)
            }

            const createThumbTemp=(media_el:HTMLVideoElement|HTMLImageElement, index:number)=>{
                const canvas = document.getElementById('canvas-'+index) as HTMLCanvasElement;
                if (media_el != null && canvas !=null){
                    const ctx = canvas.getContext("2d");
                    ctx?.drawImage(media_el, 0, 0, 128, 72);
                    const imgdata = canvas.toDataURL()
                    contentStore.uploading[index].thumb=imgdata
                } 
            }
            onUnmounted(() =>{
                files.value.forEach(x=>{
                    URL.revokeObjectURL(x.path)
                })
            })
            return {                
                ismobile,
                baseImg,
                files,
                uploaded,
                upload_error,
                total_percent,
                total,
                show_sheet,
                finisherr,
                shortenTitle,
                handleClose,
                handleCancel,
                handleRetry,
                onLoadedImg,
                onLoadedVideo
            }
        },
    });
    
    