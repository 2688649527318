
import {  IonSearchbar, SearchbarCustomEvent  } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'AppSearchbar',

    components:{
        IonSearchbar, 
        
    },
/*
    props:{
    },
*/
    emits:['onInput'],

    setup(props, context){
        const handleInput = (event:SearchbarCustomEvent ) => {
            const value = event.detail.value;
            context.emit('onInput', !value ? '': value.toLowerCase());
        }
        return {
            handleInput
        }
    }
})
