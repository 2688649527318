import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "content modal-content" }
const _hoisted_2 = { class: "container-media" }
const _hoisted_3 = { class: "content modal-content" }
const _hoisted_4 = {
  key: 1,
  controls: "",
  muted: ""
}
const _hoisted_5 = { class: "body-1" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSegments = _resolveComponent("AppSegments")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_AppModal = _resolveComponent("AppModal")!
  const _directive_auth_src = _resolveDirective("auth-src")!

  return (_openBlock(), _createBlock(_component_AppModal, {
    title: _ctx.device.name,
    icon: "ondemand_video",
    onOnClose: _ctx.handleCloseModal
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_AppSegments, {
        segments: _ctx.segments,
        active: _ctx.segment_select,
        onOnChange: _ctx.set_segment
      }, null, 8, ["segments", "active", "onOnChange"])
    ]),
    default: _withCtx(() => [
      (_ctx.segment_select == 'info')
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    (!_ctx.ismobile)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 0,
                          size: "5"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_ion_img, { src: _ctx.imgdefault }, null, 8, ["src"])
                            ]),
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("labels.total_time")) + ":", 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.total_duration), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t("labels.state")) + ":", 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.getStatusText(_ctx.device.publish_status))), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_col, {
                      "size-xs": "12",
                      "size-md": "7"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_list, {
                          class: _normalizeClass(['list'])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "item-input" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.name')) + ": ", 1),
                                    _createVNode(_component_ion_input, {
                                      id: "form-name",
                                      modelValue: _ctx.form.name,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
                                      required: true,
                                      "aria-label": _ctx.$t('labels.name'),
                                      "label-placement": "stacked",
                                      onIonInput: _ctx.set_edited
                                    }, null, 8, ["modelValue", "aria-label", "onIonInput"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "item-input" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.description')) + ": ", 1),
                                    _createVNode(_component_ion_textarea, {
                                      id: "form-description",
                                      modelValue: _ctx.form.description,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
                                      required: false,
                                      "aria-label": _ctx.$t('labels.description'),
                                      "label-placement": "stacked",
                                      onIonInput: _ctx.set_edited
                                    }, null, 8, ["modelValue", "aria-label", "onIonInput"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.device_data, (item) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                key: item.key,
                                class: _normalizeClass(['item-device-info'])
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(['label-item', _ctx.ismobile? 'mob' : 'desk'])
                                  }, [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t(item.label)), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createElementVNode("p", null, _toDisplayString(item.value ? item.value : '-'), 1)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }))
        : (_ctx.segment_select == 'content')
          ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "3"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.item_select.Content.media_type == 'image')
                            ? _withDirectives((_openBlock(), _createBlock(_component_ion_img, { key: 0 }, null, 512)), [
                                [_directive_auth_src, _ctx.item_select.Content.local_path]
                              ])
                            : _createCommentVNode("", true),
                          (_ctx.item_select.Content.media_type == 'video')
                            ? _withDirectives((_openBlock(), _createElementBlock("video", _hoisted_4, null, 512)), [
                                [_directive_auth_src, _ctx.item_select.Content.local_path]
                              ])
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_5, [
                            _createTextVNode(_toDisplayString(_ctx.item_select.Content.description) + " " + _toDisplayString(_ctx.$t('labels.validity')) + ": ", 1),
                            (_ctx.item_select.Content.validity_start > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.getStrDate(_ctx.item_select.Content.validity_start)) + " " + _toDisplayString(_ctx.getStrDate(_ctx.item_select.Content.validity_end)), 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('labels.undefined')), 1))
                          ])
                        ]),
                        _: 1
                      }),
                      (!_ctx.ismobile)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            "size-md": "9"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_grid, { class: "table" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_row, { class: "table-header" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_col, { size: "4" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('labels.content')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_col, { size: "2" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('labels.state')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_col, { size: "2" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('labels.type')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_col, { size: "2" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('labels.size')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_col, { size: "2" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('labels.duration')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                                    return (_openBlock(), _createBlock(_component_ion_row, {
                                      key: item.id_playlist_item,
                                      class: _normalizeClass( _ctx.item_select.id_playlist_item == item.id_playlist_item ? 'row-selected': 'row'),
                                      onClick: ($event: any) => (_ctx.show_item(item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_col, { size: "4" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.shortenTitle(item.Content.title, false)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_col, { size: "2" }, {
                                          default: _withCtx(() => [
                                            (_ctx.contentIsActive(item.Content.validity_start, item.Content.validity_end))
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                                  _createVNode(_component_ion_icon, {
                                                    color: "success",
                                                    icon: _ctx.ellipse
                                                  }, null, 8, ["icon"]),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.active')), 1)
                                                ]))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                                  _createVNode(_component_ion_icon, {
                                                    color: "secondary",
                                                    icon: _ctx.ellipse
                                                  }, null, 8, ["icon"]),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.inactive')), 1)
                                                ]))
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_col, { size: "2" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.Content.media_type == 'video' ? _ctx.$t('type_video'): _ctx.$t('type_image')), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_col, { size: "2" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getStrSize(item.Content.File.weight)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_col, { size: "2" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getStrTime(item.duration)), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["class", "onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_col, { size: "12" }, {
                        default: _withCtx(() => [
                          (_ctx.ismobile)
                            ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                                    return (_openBlock(), _createBlock(_component_ion_item, {
                                      key: item.id_playlist_item,
                                      button: "",
                                      class: _normalizeClass( _ctx.item_select.id_playlist_item == item.id_playlist_item ? 'item-selected': 'row'),
                                      onClick: ($event: any) => (_ctx.show_item(item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createElementVNode("h3", null, _toDisplayString(_ctx.shortenTitle(item.Content.title,false)), 1),
                                            _createElementVNode("p", null, [
                                              (_ctx.contentIsActive(item.Content.validity_start, item.Content.validity_end))
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                                    _createVNode(_component_ion_icon, {
                                                      color: "success",
                                                      icon: _ctx.ellipse
                                                    }, null, 8, ["icon"]),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.active')), 1)
                                                  ]))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                                    _createVNode(_component_ion_icon, {
                                                      color: "secondary",
                                                      icon: _ctx.ellipse
                                                    }, null, 8, ["icon"]),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.inactive')), 1)
                                                  ]))
                                            ]),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.getStrTime(item.Content.duration)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_label, { slot: "end" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(_ctx.getStrSize(item.Content.File.weight)), 1),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.item_select.Content.extension), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["class", "onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
      _createVNode(_component_ion_footer, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "modal-footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, {
                    fill: false,
                    color: "accent",
                    onClick: _ctx.handleCloseModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button_cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_AppButton, {
                    fill: true,
                    color: "accent",
                    disabled: _ctx.form.name == '' || !_ctx.edited,
                    onClick: _ctx.handleSaveData
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button_save')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "onOnClose"]))
}