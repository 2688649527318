export const en = {
    display: "Display",
    searchbar: "What are you looking for?",
    type_all: "All",
    type_files: 'Files',
    type_video: 'Video',
    type_image: 'Image',
    type_folder: "Folders",
    order_by: "Order by",
    type_new: 'Newest',
    type_az: 'Name A-Z',
    type_za: 'Name Z-A',
    type_tipo: 'Type',
    type_peso: 'Weight',
    button_add: "Add",
    button_cancel: "Cancel",
    button_retry: "Retry",
    button_close: "Close",
    button_accept: "Accept",
    button_ok: "Ok",
    button_save: "Save",
    button_save_changes: "Save Changes",
    button_select: "Select",
    lang: "Language",
    menu_open: "Open",
    menu_properties: "Properties",
    menu_rename: "Rename",
    menu_download: "Download",
    menu_delete: "Delete",
    menu_remove: "Remove",
    menu_on: "Control on",
    menu_off: "Control off",
    menu_restart: "Restart",
    menu_copy: "Copy contents to..",
    menu_publish: "Publish",
    menu_preview: "Preview",
    main_menu: {
        dashboard: "Dashboard",
        publish: "Publication",
        editor: "Editor"
    },
    state_cancel: "Canceled",
    state_error: "Error",
    state_process: "Processing",
    selected: "Selected:",
    time_sec: "seconds",
    //tuto
    tutorial:{
        header: "es muy fácil de usar",
        section: "Sección de {name}",
        btn_next: 'Siguiente',
        btn_skip: 'Saltar tutorial'
    },
    plan:{
        basic: 'Basic Plan'
    },
    dashboard:{
        greeting: "Welcome {name}",
        last_login: "Last login {date}",
        description: "This is your dashboard with all your information",
        profile_btn: "Go to my profile",
        my_displays: "My devices",
        display_info: "Select the device you want to view",
        display_info_no: "enAún no tienes ningún player o pantalla. \n Debes comprar una o activar tu licencia",
        activate_btn : "enActivar licencia",
        ad_title: "Do you want to add a new screen?",
        ad_info: "You can add a new display once you have your EC4Y player.",
        ad_btn: "Buy Display",
        displays: "Devices",
        plan_from: "Active from",
        plan_payment: "Payment date",
        plan_to: "Active until",
        plan_btn: "Modify suscription",
        memory: "Memory",
        memory_no: "You don't have available devices",
        info_title: "Device Information",
        data_transfer: "enTransferencia de datos último mes",
        m_videos: 'Videos',
        m_images:'Images',
        m_used: 'Used memory',
        m_free: 'Free space'
    },
    empty_txt:{
        files:'Drag your files, or use the add button',
        files_mob: 'To add files use the add button',
        search: 'Oops! We did not find matches with your search',
        playlist: 'Add content to your playlist'
    },
    msg_err:{
        startdot: "You can't use a name that begins with a dot "+'"."',
        badchars: "The name can't contain the following characters \\ / * : < > {'|'}",
        existname: 'The name "{name}" already exists. Please enter another name.'

    },
    content_section:{
        title: "Media",
        load_more: "Load more",
        upload_local: "enDesde el computador",
        upload_editor: "enImportar desde Eyecatch Editor",
        delete_title: "Delete content",
        delete_info: "Some selected content is being used. Do you really want to delete it?",
        delete_folder: "Delete folders",
        delete_alert: "Some folder you want to delete has contents, are you sure to delete them?",
        //size_alert: "The file {name} may not play/view correctly, we recommend that you upload files in HD or up to 4K quality",
        size_alert: "The file {name} may not be displayed correctly, we recommend that you upload a file with minimum HD quality and maximum 4K",
        max_size_alert: "File {name} exceeds the maximum size allowed.",
        ext_alert: "The selected file {name} has an unsupported format. Valid formats are {extension}",
    },
    folder:{
        title_new: "Create new folder",
        info_new: "Enter the name of the new folder to create",
        title_rename: "Rename folder ",
        info_rename: "Enter the new folder name",
    },
    sheet_upload:{
        title: "Uploading {cant} file(s) of {total}",
        //description: "Se admiten todos los tipos de jpg, png, mp4",
    },
    day_picker:{
        l: "Mo",
        m: "Tu",
        c: "We",
        j: "Th",
        v: "Fr",
        s: "Sa",
        d: "Su"
    },
    m_content_view:{
        button_download: "Download",
        button_update: "Update file",
        title_display: "Display properties",
        title_uses: "Content used on:",
        segment_info: "Info",
        segment_props: "Properties",
        transition: "Transition",
        trans_none: "None",
        uses_info:"When you select a screen you will be adding the selected content"
    },
    m_content_editor:{
        title: "_Importar desde Eyecatch Editor",
    },
    transition:{
        none: 'None',
        slide: 'Slide left',
        zoom: 'Zoom out',
        fade: 'Fade in'
    },
    playlist_section:{
        title: "Playlists",
    },
    m_playlist:{
        new: "Crear Playlist",
        shuffle: "Shuffle",
    },
    device_section:{
        title: "Devices",
        button_buy: "Buy displays",
        label_items: "Playlist",
        add_content: "Add contents to {device}"
    },
    device_state:{
        nothing: 'Nothing to publish',
        error: 'Connection error',
        pending: 'Pending publication',
        done: 'Published'
    },
    m_device_view:{
        segment_info: "Info",
        segment_content: "Contents",
        segment_logs: "Logs"
    },
    m_device_add_content:{
        title: "enAgregar contenido a ",
        label: "enSeleccionar media"
    },
    m_device_copy_items:{
        title: "Copy items to ",
        label: "Select the device(s) to which you want to copy"
    },
    labels:{
        name: "Name",
        description: "Description",        
        size: "Size",
        weight: "Weight",
        dimensions: "Dimensions",
        width: "Width",
        height: "Height",
        duration: "Duration",
        default_duration: "Default duration",
        mute: "Mute",
        version: "Version",
        create: "Created",
        update: "Updated",
        validity: "Validity date",
        undefined: "Undefined",
        start: "Start",
        end: "End",
        content: "Content",
        state: "State",
        active: "Active",
        inactive: "Inactive",
        type: "Type",

        seq: "Sequential",
        sub: "SubPlaylist",

        display: "Device",
        model: "Model",
        serial: "Serial",
        mac: "MAC address",
        ip: "IP",
        resolution: "Resolution",        
        last_conection: "Last conection",
        publish_date: "Publish date",
        publish_status: "Publish status",
        memory: "Total memory",
        free_space: "Free space",
        total_time: "Total duration",
        orientation: "Orientation",
        landscape: "Landscape",
        portrait: "Portrait",
        transition: "Transición",
        //user
        u_name: "_Nombres",
        u_lastname: "_Apellidos",
        u_email: '_Correo electrónico',
        u_birthdate: '_Fecha de nacimiento',
        u_document: '_Tipo de documento',
        u_docnumber: '_Número de documento',
        u_country: '_País',
        u_city: '_Ciudad',
        u_phone: '_Teléfono'
    },
    tooltip: {
        premium: "enCambiar a plan premium",
        profile: "View my profile",
        lang: "Change language",
        add_content: "Import contents",
        add_folder: "Create new folder",
        download: "Download files",
        btn_select: "Enable/disable selection",
        view_type: "Change view mode",
        delete: "Delete contents selected",
        search: "Search contents",
        hide: "Hide search",
        add_playlist: "Add new playlist",
        preview: "Preview",
        publish: "Publish",
        properties: "Properties",
        add_item: "Add content to playlist",
        remove: "Remove content to playlist",
        order: "Order by"
    },
    loader:{
        load: "Loading",
        delete: "Removing",
        copy: "Copy process",
        save: "Saving",
        add: "Adding",
        off: "Turning off",
        on: "Turning on",
        restart: "Restarting"
    },
    user_section:{
        // menus
        m_info: 'Account Information',
        t_info: 'Account Information',
        d_info: 'Edit your personal information',
        m_password: 'Change password',
        m_suscription: 'Suscription',
        m_logout: "Log out",
        //secciones
        s_account: 'My account'
    },
    sesion_expired: "Your session has expired, please log in again.",
    err_404:{
        sorry: 'Sorry',
        text: 'This page does not exist :(',
        btn: 'Return home'
    },
    err_internet:{
        title: 'No connection',
        sorry: 'Sorry',
        text: "You don't have internet connection",
        btn: 'Reload'
    }
}
