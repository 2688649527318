
import {
  IonContent, IonHeader, IonPage, IonSelect, IonSelectOption,
  IonToolbar, IonButtons, IonRow, IonCol, 
  isPlatform, loadingController, modalController, 
} from '@ionic/vue';
import { menu} from 'ionicons/icons';
import { defineComponent, ref, computed } from 'vue';
import TheManagerHeader from '@/view_components/TheManagerHeader.vue';
import AppSearchbar from '@/uicomponents/AppSearchbar.vue';
import { Content, order_types, baseImg } from '@/core/interfaces/Content';
import { getStrTime, shortenTitle, getStrSize } from '@/core/utils'
import useContentStore from '@/core/stores/contentStore';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import AppCardMedia from '@/uicomponents/AppCardMedia.vue';
import AppPopoverMenu from '@/uicomponents/AppPopoverMenu.vue';
import ContentService from '@/core/services/ContentService';
import ModalContentView from './ModalContentView.vue';
import useDeviceStore from '@/core/stores/deviceStore';
import TheMobileMenuToggle from '@/view_components/TheMobileMenuToggle.vue';
import { useI18n } from 'vue-i18n'

export default defineComponent({
    name: 'EditorPage',

    components: {
        IonContent,
        IonPage,
        IonHeader,
        TheManagerHeader, 
        IonToolbar, IonButtons, 
        AppSearchbar,
        IonRow, IonCol,
        IonSelect, IonSelectOption,
        AppCardMedia,
        AppPopoverMenu,
        TheMobileMenuToggle
    },

    setup() {   
        const logoe4u = "assets/logo_small.svg" 
        const menu_items:AppPopoverMenuItem[]=[
            {title:'menu_edit', key:'edit', icon: 'border_color', disabled:false  },
            {title:'menu_open', key:'open', icon: 'open_in_new', disabled:false  },
            {title:'menu_properties', key:'view', icon: 'tune', disabled:false  },
            {title:'menu_delete', key:'delete', icon: 'delete', disabled:false  }
        ]
        const deviceStore = useDeviceStore();
        const contentStore = useContentStore();
        const files_editor= computed(()=>{ return contentStore.getContentsEditor });
        const ismobile = isPlatform('mobile')    
        const types_filter = computed(() => { return contentStore.getTypesFilter})
        const text_search = ref('');
        const set_text_search = (value: string | null | undefined) => {
            text_search.value = !value ? '': value.toLowerCase();
        }
        const type_search = ref('all');
        const set_type_search = (value: string | null | undefined) => {
            type_search.value = !value ? 'all': value;
        }
        const order_select = ref('new');
        const set_order_select = (value: string | null | undefined) => {
            order_select.value = !value ? 'new': value;
        }
        
        const filteredEditorList= computed(()=>{ 
            let filtered = files_editor.value
            if (text_search.value.length > 0)
                filtered = filtered.filter((content: Content) =>
                    content.title.toLowerCase().includes(text_search.value))
            if (type_search.value != 'all')
                filtered=  filtered.filter((content: Content) => content.media_type == type_search.value)
            // order
            if (order_select.value == 'az')
                return filtered.sort((a,b) => a.title.localeCompare(b.title))
            else if (order_select.value == 'new')
                return filtered.sort((a,b) => a.create_date > b.create_date ? 1 : -1)
            else 
                return filtered.sort((a,b) => a.create_date < b.create_date ? 1 : -1)
            });

            const handleClickItem = () => {
            console.log('todo edicion rapida')
        };

        const { t } = useI18n({ useScope: 'global' })
        const handleViewContent = async(content: Content)=>{
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            const response = await ContentService.getContentUses([content.id_content]);
            const uses= response[0].devices.map(x=>x.id_device)
            loading.dismiss();
            const modal = await modalController.create({
                component: ModalContentView,
                componentProps: {
                    'content' : content,
                    'uses': uses
                },
                cssClass: 'big-modal properties',
                backdropDismiss:false,
            });
            modal.present();

            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
                const loading = await loadingController.create({
                    message: t("loader.save")
                });
                loading.present();
                if (data.form != null)
                    await contentStore.updateContent(content, data.form);
                if (data.addto.length > 0){
                    // si se va agregar a pantallas
                    //console.log(data.addto)
                    const device_ids = data.addto.map((x: {id: number, name:string}) => x.id)
                    const item= {id_content: content.id_content, duration: content.duration}
                    await deviceStore.addContentsToDevices([item],device_ids);
                }
                loading.dismiss();
            }
        };

        const handleClickMenu = (menu:string, content: Content)=> {
            switch (menu) {
                case 'edit':
                    // TODO
                    break;

                case 'open':
                    // TODO
                    break;

                case 'view':
                    handleViewContent( content)
                    break;

                case 'delete':
                    // TODO
                    break;
            
                default:
                    break;
            }
        };

        const loadContents = async()  =>{
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            await contentStore.fetchContents();
            loading.dismiss()   
        }
        // load content
        if(contentStore.getContentCount==0){
          loadContents()
        }
        const loadDevices = async() =>{
            const loading = await loadingController.create({
                    message: t("loader.load")
                });
                loading.present();
                await deviceStore.fetchDevices();
                loading.dismiss()  
            }
        if(deviceStore.deviceItemsCount==0){
            loadDevices();
        }
        return {
            logoe4u,
            filteredEditorList,
            ismobile,
            menu,
            menu_items,
            types_filter, order_types, 
            baseImg,      
            text_search, set_text_search,
            type_search, set_type_search,
            order_select, set_order_select,
            getStrTime, shortenTitle, getStrSize,
            handleClickMenu,
            handleClickItem,
            handleViewContent
        }
    },
})
