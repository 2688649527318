import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days_form, (day) => {
      return (_openBlock(), _createBlock(_component_ion_button, {
        key: day.id,
        mode: "ios",
        fill: 'solid',
        color: _ctx.value[day.id] ? 'accent':'medium',
        class: _normalizeClass(['days']),
        onClick: ($event: any) => (_ctx.handleDayClick(day.id))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("day_picker."+day.label)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["color", "onClick"]))
    }), 128))
  ]))
}