import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c112a0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content modal-content" }
const _hoisted_2 = {
  key: 0,
  class: "msg-err"
}
const _hoisted_3 = { class: "container-media" }
const _hoisted_4 = ["poster"]
const _hoisted_5 = ["accept"]
const _hoisted_6 = { class: "content modal-content" }
const _hoisted_7 = { class: "content modal-content" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "container-media" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSegments = _resolveComponent("AppSegments")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_AppTimePicker = _resolveComponent("AppTimePicker")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppDatePicker = _resolveComponent("AppDatePicker")!
  const _component_AppDayPicker = _resolveComponent("AppDayPicker")!
  const _component_AppSnackbar = _resolveComponent("AppSnackbar")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_AppModal = _resolveComponent("AppModal")!
  const _directive_auth_src = _resolveDirective("auth-src")!

  return (_openBlock(), _createBlock(_component_AppModal, {
    title: _ctx.title,
    icon: _ctx.content_show.media_type == 'video' ? 'movie' : 'image',
    onOnClose: _ctx.handleCloseModal
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_AppSegments, {
        segments: _ctx.segments,
        active: _ctx.segment_select,
        onOnChange: _ctx.set_segment_select
      }, null, 8, ["segments", "active", "onOnChange"])
    ]),
    default: _withCtx(() => [
      (_ctx.segment_select == 'info')
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_row, {
                  ref: "rowInfo",
                  class: _normalizeClass(['rowInfo', _ctx.ismobile? 'col-reverse': ''])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      "size-xs": "12",
                      "size-md": "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "item-input" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.name')) + ": ", 1),
                                    _createVNode(_component_ion_input, {
                                      id: "form-title",
                                      modelValue: _ctx.form.title,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event)),
                                      required: true,
                                      "label-placement": "stacked",
                                      maxlength: 20,
                                      "aria-label": _ctx.$t('labels.name'),
                                      onIonInput: _ctx.checkName
                                    }, null, 8, ["modelValue", "aria-label", "onIonInput"]),
                                    (_ctx.form_state.errorname)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.form_state.errorname, {name:_ctx.form.title})), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "item-input" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.description')) + ": ", 1),
                                    _createVNode(_component_ion_textarea, {
                                      id: "form-description",
                                      modelValue: _ctx.form.description,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
                                      required: false,
                                      rows: 1,
                                      "aria-label": _ctx.$t('labels.description'),
                                      "label-placement": "stacked",
                                      onIonInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.form_state.edited = true))
                                    }, null, 8, ["modelValue", "aria-label"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              lines: "none",
                              class: _normalizeClass(['item-info'])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.size')) + ":", 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.getStrSize(_ctx.content_show.File.weight)), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              lines: "none",
                              class: _normalizeClass(['item-info'])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.width')) + ":", 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.content_show.File.width) + " px", 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              lines: "none",
                              class: _normalizeClass(['item-info'])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.height')) + ":", 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.content_show.File.height) + " px", 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              lines: "none",
                              class: _normalizeClass(['item-info'])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.content_show.media_type == 'video' ? _ctx.$t('labels.duration') : _ctx.$t('labels.default_duration')) + ": ", 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.getStrTime(_ctx.content_show.duration)), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              lines: "none",
                              class: _normalizeClass(['item-info'])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.version')) + ":", 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.content_show.File.version), 1)
                              ]),
                              _: 1
                            }),
                            (_ctx.content_show.media_type == 'video')
                              ? (_openBlock(), _createBlock(_component_ion_item, {
                                  key: 0,
                                  lines: "none",
                                  class: _normalizeClass(['item-info'])
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, { for: "form-mute" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('labels.mute')) + ": ", 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_checkbox, {
                                      id: "form-mute",
                                      slot: "end",
                                      modelValue: _ctx.form.mute,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.mute) = $event)),
                                      onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.form_state.edited = true)),
                                      "aria-label": "mute"
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ion_item, {
                              lines: "none",
                              class: _normalizeClass(['item-info'])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.create')) + ":", 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.getStrDateTime(_ctx.content_show.create_date)), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              lines: "none",
                              class: _normalizeClass(['item-info'])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.update')) + ":", 1)
                                  ]),
                                  _: 1
                                }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.getStrDateTime(_ctx.content_show.last_update)), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      "size-xs": "12",
                      "size-md": "6"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          (_ctx.content_show.media_type == 'image')
                            ? _withDirectives((_openBlock(), _createBlock(_component_ion_img, { key: 0 }, null, 512)), [
                                [_directive_auth_src, _ctx.content_show.local_path]
                              ])
                            : _createCommentVNode("", true),
                          (_ctx.content_show.media_type == 'video')
                            ? _withDirectives((_openBlock(), _createElementBlock("video", {
                                key: 1,
                                controls: "",
                                muted: "",
                                poster: _ctx.getVideoThumb(_ctx.content_show.local_path_small)
                              }, null, 8, _hoisted_4)), [
                                [_directive_auth_src, _ctx.content_show.local_path]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(_component_ion_row, { class: "buttons ion-justify-content-around" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              fill: "clear",
                              mode: "ios",
                              color: "medium",
                              size: "small",
                              onClick: _ctx.fileUpdateClick
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AppIcon, {
                                  slot: "start",
                                  icon: "cloud_sync"
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('m_content_view.button_update')) + " ", 1),
                                _createElementVNode("input", {
                                  id: "fileUpdate",
                                  ref: "fileUpdate",
                                  type: "file",
                                  multiple: false,
                                  accept: _ctx.extension_accepted,
                                  style: {"display":"none"},
                                  onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleFileUpload()))
                                }, null, 40, _hoisted_5)
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_ion_button, {
                              fill: "clear",
                              mode: "ios",
                              color: "medium",
                              size: "small",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleDownloadFile(_ctx.content_show)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AppIcon, {
                                  slot: "start",
                                  icon: "file_download"
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('m_content_view.button_download')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.segment_select == 'display')
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('m_content_view.title_display')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, {
                                  class: _normalizeClass(['label-input']),
                                  for: "form-duration",
                                  position: "stacked"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.default_duration')) + ": ", 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_AppTimePicker, {
                                  id: "form-duration",
                                  value: _ctx.content.duration,
                                  disabled: _ctx.content.media_type == 'video',
                                  onOnChange: _ctx.changeDuration
                                }, null, 8, ["value", "disabled", "onOnChange"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.validity')) + ":", 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_AppButton, {
                                  fill: false,
                                  onClick: _ctx.changeValidity,
                                  slot: "end",
                                  color: "accent"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.form_state.validity ? _ctx.$t('menu_delete') : _ctx.$t('button_add')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, {
                              disabled: !_ctx.form_state.validity,
                              class: "float-item left"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, {
                                  class: _normalizeClass(['label-input']),
                                  position: "stacked"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.start')) + ": ", 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_AppDatePicker, {
                                  "id-picker": "picker-start",
                                  disabled: !_ctx.form_state.validity,
                                  value: _ctx.form.v_start,
                                  max: _ctx.form.v_end,
                                  onOnChange: _ctx.changeValidityStart
                                }, null, 8, ["disabled", "value", "max", "onOnChange"])
                              ]),
                              _: 1
                            }, 8, ["disabled"]),
                            _createVNode(_component_ion_item, {
                              disabled: !_ctx.form_state.validity,
                              class: "float-item right"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, {
                                  class: _normalizeClass(['label-input']),
                                  position: "stacked"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('labels.end')) + ": ", 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_AppDatePicker, {
                                  "id-picker": "picker-end",
                                  disabled: !_ctx.form_state.validity,
                                  value: _ctx.form.v_end,
                                  min: _ctx.form.v_start,
                                  onOnChange: _ctx.changeValidityEnd
                                }, null, 8, ["disabled", "value", "min", "onOnChange"])
                              ]),
                              _: 1
                            }, 8, ["disabled"]),
                            _createVNode(_component_ion_item, {
                              disabled: !_ctx.form_state.validity,
                              class: "itemDays"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Días:")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_AppDayPicker, {
                                  value: _ctx.form.v_days,
                                  onOnChange: _ctx.changeValidityDays
                                }, null, 8, ["value", "onOnChange"])
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (!_ctx.ismobile)
                      ? (_openBlock(), _createBlock(_component_ion_col, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_list, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('m_content_view.title_uses')), 1)
                                  ]),
                                  _: 1
                                }),
                                (_ctx.show_snackbar)
                                  ? (_openBlock(), _createBlock(_component_AppSnackbar, {
                                      key: 0,
                                      type: "info",
                                      onOnClose: _ctx.hide_snackbar
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('m_content_view.uses_info')), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["onOnClose"]))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (device) => {
                                  return (_openBlock(), _createBlock(_component_ion_item, {
                                    lines: "none",
                                    key: device.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_checkbox, {
                                        slot: "start",
                                        disabled: _ctx.deviceHasContent(device.id),
                                        modelValue: device.checked,
                                        "onUpdate:modelValue": ($event: any) => ((device.checked) = $event),
                                        "label-placement": "end",
                                        color: "accent",
                                        onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.form_state.edituse = true))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(device.name), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["disabled", "modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }))
        : (_ctx.segment_select == 'transition')
          ? (_openBlock(), _createBlock(_component_ion_content, { key: 2 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ion_row, {
                    class: _normalizeClass(['rowTran', _ctx.ismobile? 'col-reverse': ''])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "5"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transitions, (transition) => {
                                return (_openBlock(), _createBlock(_component_ion_col, {
                                  key: transition.value,
                                  size: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_card, {
                                      button: "",
                                      class: _normalizeClass(['ion-text-center', transition.value==_ctx.form.transition?'transition selected':'transition']),
                                      onClick: ($event: any) => ( _ctx.onChangeTransition(transition.value))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_card_content, null, {
                                          default: _withCtx(() => [
                                            _createElementVNode("img", {
                                              src: 'assets/'+transition.image
                                            }, null, 8, _hoisted_8),
                                            _createElementVNode("p", null, _toDisplayString(_ctx.$t(transition.title)), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["class", "onClick"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        "size-xs": "12",
                        "size-md": "7"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            (_ctx.content_show.media_type == 'image')
                              ? _withDirectives((_openBlock(), _createBlock(_component_ion_img, {
                                  key: 0,
                                  class: _normalizeClass([_ctx.form.transition? _ctx.form.transition: ''])
                                }, null, 8, ["class"])), [
                                  [_directive_auth_src, _ctx.content_show.local_path]
                                ])
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["class"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
      _createVNode(_component_ion_footer, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "modal-footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, {
                    fill: false,
                    color: "accent",
                    onClick: _ctx.handleCloseModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button_cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_AppButton, {
                    fill: true,
                    disabled: _ctx.isFormInvalid,
                    color: "accent",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleSave()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button_save')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "icon", "onOnClose"]))
}