
import { defineComponent, onMounted, ref } from 'vue';
    
    export default defineComponent({
        name: 'DraggableItem',
    
        components: {
        },
    
        props: {
            dropData:{
                type:Object,
                required: true
            },
            dropZoneRef:{
                type: Object,
                required: false
            },
            disableDrag:{
                type: Boolean,
                required: false,
                default: false
            }
        },

        emits:['onDragEnter'],
        
        setup(props){  
            const draggableItem = ref();
            onMounted(()=>{      
                const hostElement= draggableItem.value as HTMLDivElement
                hostElement.addEventListener("dragend", (e:Event)=> props.dropZoneRef?.complete(e, props.dropData))
            })
            return {
                draggableItem
            }
        }
    });
    
    