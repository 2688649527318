import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "segments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_toolbar, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_segment, {
          color: "accent",
          "model-value": _ctx.active,
          onIonChange: _ctx.handleChangeSegment
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segments, (segment) => {
              return (_openBlock(), _createBlock(_component_ion_segment_button, {
                key: segment.value,
                value: segment.value,
                disabled: segment.disabled,
                layout: "icon-start"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppIcon, {
                    icon: segment.icon
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(segment.title)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value", "disabled"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["model-value", "onIonChange"])
      ]),
      _: 1
    })
  ]))
}