
import {
  IonToolbar, IonImg, IonButtons, IonButton, IonAvatar, 
  useIonRouter,
modalController
} from '@ionic/vue';
import { defineComponent, } from 'vue';
import TheSelectLang from '@/view_components/TheSelectLang.vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
	name: 'TheManagerHeader',

	components: {
		IonToolbar,
		IonImg,
		IonButtons, IonButton,
		IonAvatar,
		//AppButton,
		TheSelectLang
	},


	setup() {
		const logoe4u = "assets/logoeyecatch.png"
		const ionRouter = useIonRouter();
		const router = useRouter();
		//const lang = this.$route.params['lang']
		const route = useRoute();
		const currentpath =route.path
		const menumanager =[
			{path: '/dashboard', title: 'main_menu.dashboard', current: currentpath== '/dashboard'},
			{path: '/workspace', title: "main_menu.publish", current: currentpath== '/workspace'},
			//{path: '/editor', title: "main_menu.editor", current: currentpath== '/editor'},
		]
		return { 
			logoe4u, 
			ionRouter, 
			router,
			menumanager
		}
	}
});
