import { Playlist, PlaylistUpdate } from '../interfaces/Playlist';
import HttpService from './HttpService';

export const PlaylistService = {

    async getPlaylists(): Promise<Playlist[]> {
        const response = await HttpService.jsonPost('/manager/playlist/list', {});
        return response ? response : [];
    },

    async createPlaylist(data: PlaylistUpdate): Promise<Playlist>{
        const response  = await HttpService.jsonPost('manager/playlist/create', data);
        return response
    },

    async updatePlaylistInfo(id_playlist:number, data:PlaylistUpdate){
        const payload = {'id_playlist': id_playlist, ...data}
        const response = await HttpService.jsonPost('/manager/playlist/update-info', payload);
        return response;
    },

    async deletePlaylist(id_playlist:number){
        const payload = {'id_playlist': id_playlist}
        const response = await HttpService.jsonPost('/manager/playlist/delete', payload);
        return response;

    },

    async getPlaylistItems(id_playlist: number):Promise<any[]>{
        const payload = {
            "id_playlist": id_playlist
        }
        const response = await HttpService.jsonPost('/manager/playlist/items', payload);
        return response ? response : [];
    }
    
};

export default PlaylistService;