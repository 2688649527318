
import {
    IonContent, IonToolbar,  IonSelect, IonSelectOption,
     IonFooter, IonButtons,
    IonRow, IonCol, modalController,isPlatform
} from '@ionic/vue';
import { computed, defineComponent, reactive, ref } from 'vue';
import { baseEditor,  order_types, EditorExports } from '@/core/interfaces/Content';
import { getStrSize, shortenTitle } from '@/core/utils';
import AppButton from '@/uicomponents/AppButton.vue';
import AppButtonIcon from '@/uicomponents/AppButtonIcon.vue';
import AppSearchbar from '@/uicomponents/AppSearchbar.vue';
import AppModal from '@/uicomponents/AppModal.vue';
import AppCardMedia from '@/uicomponents/AppCardMedia.vue';
import useContentStore from '@/core/stores/contentStore';

export default defineComponent({
    name: 'ModalContentFromEditor',

    components: {
        IonContent, IonToolbar, //IonImg, IonThumbnail,
        IonCol, IonRow, //IonLabel, IonGrid,
        IonFooter, //IonList, IonItem,
        AppButton, AppButtonIcon, IonButtons,
        AppSearchbar,
        AppModal,
        AppCardMedia,
        IonSelect, IonSelectOption,
    },

    props: {
        files: {
            type: Array<EditorExports>,
            required: true,
        },
    },

    setup(props) {
        const ismobile = isPlatform('mobile')
        const view_type = ref('grid');
        const contentStore = useContentStore();
        const types_filter = computed(() => { return contentStore.getTypesFilter})
        const change_viewtype = () => {
            view_type.value = view_type.value == 'grid' ? 'table' : 'grid';
        };
        const text_search = ref('');
        const set_text_search = (value: string) => {
            text_search.value = value.toLowerCase();
        }
        
        const type_search = ref('all');
        const set_type_search = (value: string | null | undefined) => {
            type_search.value = !value ? 'all' : value;
        }
        const order_select = ref('new');
        const set_order_select = (value: string | null | undefined) => {
            order_select.value = !value ? 'new': value;
        }
        const files_selected = reactive<EditorExports[]>([])
        const filtered_files= computed(() => {
            let filtered = props.files
            if (text_search.value.length > 0)
                filtered = filtered.filter(x =>
                    x.name.toLowerCase().includes(text_search.value))
            if (type_search.value != 'all')
                filtered = filtered.filter((content: EditorExports) => content.type == type_search.value)
            // order
            if (order_select.value == 'az')
                return filtered.sort((a,b) => a.name.localeCompare(b.name))
            else if (order_select.value == 'new')
                return filtered.sort((a,b) => a.updated > b.updated ? 1 : -1)
            else 
                return filtered.sort((a,b) => a.updated < b.updated ? 1 : -1)
        })

        const handleClickContent = (file: EditorExports) => {
            const index = files_selected.findIndex(x => x.name == file.name);
            if (index == -1) { //si no está lo agrega
                files_selected.push(file);
            } else {
                files_selected.splice(index, 1);
            }
            //console.log(this.files_selected)
        };

        const isSelected = (file: EditorExports) => {
            return files_selected.findIndex(x => x.name == file.name) == -1 ? undefined : 'primary';
        };

        const handleCloseModal = () => {
            return modalController.dismiss([], 'cancel');
        };

        const handleSave = () => {
            //console.log(this.contents_selected)
            return modalController.dismiss(files_selected, 'save');
        }
        return {
            ismobile,
            baseEditor,
            order_select, set_order_select,
            view_type, change_viewtype,
            text_search, set_text_search,
            type_search, set_type_search,
            files_selected,
            filtered_files,
            getStrSize, shortenTitle,
            types_filter, order_types,
            handleClickContent,
            isSelected,
            handleCloseModal,
            handleSave
        }
    }
});

