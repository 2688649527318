
import { defineComponent, } from 'vue';

export default defineComponent({
    name: 'AppIcon',

    props:{
        icon:{
            type:  String,  
            required: true
        },
        type:{
            type: String,
            required:false,
            default: 'outlined',
            validator(value:string) {
                return ['outlined', 'round', 'sharp'].includes(value)
            }
        },
        symbols:{
            type: Boolean,
            default: false
        },
        color:{
            type: String,
            required: false,
            default: '',
            validator(value:string) {
                return ['success', 'medium', ''].includes(value)
            }
        }
    },
})
