import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "snackbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_chip, {
      color: _ctx.type == 'info' ? 'tertiary' : _ctx.type,
      outline: false,
      class: _normalizeClass(['snackbar', 'snackbar-' + _ctx.type])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }),
        (_ctx.buttonText)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              fill: "clear",
              class: "snack-btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onAction')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_button, {
          fill: "clear",
          class: "snack-btn-close",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppIcon, { icon: "close" })
          ]),
          _: 1
        })
      ]),
      _: 3
    }, 8, ["color", "class"])
  ]))
}