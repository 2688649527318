import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppCardMedia = _resolveComponent("AppCardMedia")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_AppPopoverMenu = _resolveComponent("AppPopoverMenu")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_ion_row, null, {
    default: _withCtx(() => [
      (_ctx.isOpenFolder)
        ? (_openBlock(), _createBlock(_component_ion_col, {
            key: 0,
            "size-xs": "6",
            "size-md": "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppCardMedia, {
                label: "..",
                data: {},
                "img-src": _ctx.folder_imgback,
                onOnDblClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.check_enable?_ctx.$emit('onClickFolder', ''):null))
              }, null, 8, ["img-src"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.isOpenFolder)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.folders, (folder) => {
            return (_openBlock(), _createBlock(_component_ion_col, {
              key: folder.Name,
              "size-xs": "6",
              "size-md": "4"
            }, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_AppCardMedia, {
                  label: folder.Name,
                  data: folder,
                  "img-src": _ctx.folder_img,
                  class: _normalizeClass([folder.check ? 'card_media_actions card_selected': 'card_media_actions']),
                  onOnClick: ($event: any) => (_ctx.handleClickFolder(folder)),
                  onOnDblClick: ($event: any) => (!_ctx.check_enable?_ctx.$emit('onClickFolder', folder.Name):null)
                }, {
                  start: _withCtx(() => [
                    _createVNode(_component_ion_checkbox, {
                      slot: "start",
                      modelValue: folder.check,
                      "onUpdate:modelValue": ($event: any) => ((folder.check) = $event),
                      checked: folder.check,
                      class: _normalizeClass(_ctx.check_enable?'show':'hide')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "checked", "class"])
                  ]),
                  end: _withCtx(() => [
                    _createVNode(_component_AppPopoverMenu, {
                      "id-menu": 'folder-'+folder.Name,
                      items: _ctx.folderMenuItems,
                      data: folder,
                      slot: "end",
                      class: _normalizeClass(_ctx.check_enable?'hide':'show'),
                      onOnClick: _ctx.handleClickMenuFolder
                    }, null, 8, ["id-menu", "items", "data", "class", "onOnClick"])
                  ]),
                  _: 2
                }, 1032, ["label", "data", "img-src", "class", "onOnClick", "onOnDblClick"])), [
                  [_directive_tooltip, folder.Name]
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentList, (content) => {
        return (_openBlock(), _createBlock(_component_ion_col, {
          key: 'content-'+content.id_content,
          "size-xs": "6",
          "size-md": "4"
        }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_AppCardMedia, {
              label: _ctx.shortenTitle(content.title, true)+'.'+ content.extension,
              "img-src": "",
              "icon-editor": content.is_from_editor,
              duration: content.media_type=='video' ? _ctx.getStrTime(content.duration) : undefined,
              "is-video": content.media_type=='video',
              validity: content.validity_start!=0,
              data: content,
              "enable-drag": _ctx.deviceIsSelect && !_ctx.check_enable,
              "drop-zone-ref": _ctx.contentDrop,
              class: _normalizeClass([content.check ? 'card_media_actions card_selected': 'card_media_actions']),
              onOnDblClick: ($event: any) => (!_ctx.check_enable?_ctx.$emit('onView', content):null),
              onOnClick: ($event: any) => (_ctx.handleClickCard(content)),
              onOnDragEnter: ($event: any) => (_ctx.handleDragEnter(content))
            }, {
              start: _withCtx(() => [
                _createVNode(_component_ion_checkbox, {
                  slot: "start",
                  modelValue: content.check,
                  "onUpdate:modelValue": ($event: any) => ((content.check) = $event),
                  checked: content.check,
                  class: _normalizeClass(_ctx.check_enable?'show':'hide')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "checked", "class"])
              ]),
              end: _withCtx(() => [
                _createVNode(_component_AppPopoverMenu, {
                  "id-menu": 'content-'+content.id_content,
                  items: _ctx.menuItems,
                  data: content,
                  slot: "end",
                  class: _normalizeClass(_ctx.check_enable?'hide':'show'),
                  onOnClick: _ctx.handleClickMenu
                }, null, 8, ["id-menu", "items", "data", "class", "onOnClick"])
              ]),
              _: 2
            }, 1032, ["label", "icon-editor", "duration", "is-video", "validity", "data", "enable-drag", "drop-zone-ref", "class", "onOnDblClick", "onOnClick", "onOnDragEnter"])), [
              [_directive_tooltip, content.title+'.'+ content.extension]
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}