
import {  IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'AppButton',

    components:{
        IonButton, 
    },

    props:{
        fill:{
            type: Boolean, 
            required: true,
            default: true
        },
        disabled:{
            type: Boolean,
            required: false,
            default: false
        },
        href:{  
            type: String,
            required:false
        },
        target:{  
            type: String,
            required:false
        },
        slot:{
            type:String,
            required:false
        },
        color:{
            type: String,
            required: false,
            default: 'primary'
        },
        expand:{
            type: Boolean,
            required: false,
            default: false
        }
    },

    emits:["click"],

    setup(){
        return {
        }
    }
})
