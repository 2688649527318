import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheManagerHeader = _resolveComponent("TheManagerHeader")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_TheContentSection = _resolveComponent("TheContentSection")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ThePlaylistSection = _resolveComponent("ThePlaylistSection")!
  const _component_TheDeviceSection = _resolveComponent("TheDeviceSection")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ContentUploadSheet = _resolveComponent("ContentUploadSheet")!
  const _component_TheMobileMenuToggle = _resolveComponent("TheMobileMenuToggle")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    id: "main-content",
    class: "ion-page"
  }, {
    default: _withCtx(() => [
      (!_ctx.ismobile)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ion_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_TheManagerHeader)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_content, {
              fullscreen: true,
              "scroll-y": false,
              class: _normalizeClass(_ctx.show_sheet?'show-sheet':'hide-sheet')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, { class: "main-row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { class: "main-section" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TheContentSection)
                          ]),
                          _: 1
                        }),
                        (_ctx.plan_type>1)
                          ? (_openBlock(), _createBlock(_component_ion_col, {
                              key: 0,
                              class: "main-section"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ThePlaylistSection)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_col, { class: "main-section" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TheDeviceSection)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ContentUploadSheet)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.ismobile)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_ion_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_TheMobileMenuToggle)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_content, {
              class: _normalizeClass(['main-work'])
            }, {
              default: _withCtx(() => [
                (_ctx.sectionselect=='content')
                  ? (_openBlock(), _createBlock(_component_TheContentSection, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.sectionselect =='displays')
                  ? (_openBlock(), _createBlock(_component_TheDeviceSection, { key: 1 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_footer, null, {
              default: _withCtx(() => [
                _createVNode(_component_ContentUploadSheet),
                _createVNode(_component_ion_segment, {
                  color: "accent",
                  "model-value": _ctx.sectionselect,
                  onIonChange: _ctx.set_sectionselect
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segments, (segment) => {
                      return (_openBlock(), _createBlock(_component_ion_segment_button, {
                        key: segment.value,
                        value: segment.value,
                        layout: "icon-start"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AppIcon, {
                            icon: segment.icon
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(segment.title)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["model-value", "onIonChange"])
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}