
import {  IonLabel, IonButton, } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AppDayPicker',

    components:{
        IonLabel, IonButton,
        
    },

    props:{
        value:{
            type: Array<number>,
            required: false,
            default: [0,0,0,0,0,0,0]
        }
    },

    emits:["onChange"],


    setup(props, context){
        const days_form= [
            { id: 0, label: 'l'},
            { id: 1, label: 'm'},
            { id: 2, label: 'c'},
            { id: 3, label: 'j'},
            { id: 4, label: 'v'},
            { id: 5, label: 's'},
            { id: 6, label: 'd'},
        ]

        const handleDayClick = (day:number) => {
            const new_value = props.value[day] == 0 ? 1: 0
            context.emit('onChange', new_value, day)
        }

        return {
            days_form,
            handleDayClick
        }
    }
})
