import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "empty back-txt"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "droppZone",
    ref: "droppZone",
    onDragover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDragover && _ctx.handleDragover(...args))),
    onDragleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args))),
    class: _normalizeClass(['droppZone'])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('empty_txt.playlist')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 544))
}