import { alertController } from '@ionic/vue';
import axios, { AxiosResponse } from 'axios';
import { AuthService } from './AuthService';
import useAuthStore from '../stores/authStore';
import { useI18n } from 'vue-i18n';

const SERVER_URL = process.env.VUE_APP_URL_API 

export interface StdResponse<T>{
    status: string,
    data:   T
}

export const HttpService= {
    init(){
        axios.defaults.baseURL = SERVER_URL;
        axios.defaults.headers.common[ "Authorization"] = AuthService.getToken();
        //console.log('axios init');
    },

    getAbortCtrl(){ 
        return new AbortController();
    },

    jsonGet(url_get: string):Promise<any>{
        return axios.get(SERVER_URL+url_get, {headers:{'Authorization':AuthService.getToken()}})
        .then(async (response:AxiosResponse<any>) => {
            return response.data
        }).catch(async (error) => {
            console.log('Error:', error.message);              
            if(error.response.status == '401'){
                console.error("sesión expirada")
                const authStore = useAuthStore();
                const { t } = useI18n({ useScope: 'global' })
                const alert = await alertController.create({
                    header: 'Sesión caducada',
                    message: t("sesion_expired"),
                    buttons: ['OK'],
                    cssClass: 'sessionexp'
                });
                await alert.present();
                await alert.onWillDismiss();
                await authStore.sesionexpired();
            }
        })
    },

    jsonPost(url_post: string, payload: any, direct=false):Promise<any>{
        return axios.post(url_post, payload)
            .then(async (response:AxiosResponse<any>) => {
                const data_axios: StdResponse<any> = response.data
                if(direct){
                    return data_axios
                }
                //console.log('response', data_axios.status)
                if(data_axios.status == 'Error'){
                    console.log('server error');
                    const alert = await alertController.create({
                        header: 'Error',
                        message: "Server Error",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return null
                }
                else
                    return data_axios.data
            }).catch(async (error) => {
                console.log('Error:', error.message);                
                if(error.response.status == '401'){
                    console.error("sesión expirada")
                    const authStore = useAuthStore();
                    const { t } = useI18n({ useScope: 'global' })
                    const alert = await alertController.create({
                        header: 'Sesión caducada',
                        message: t("sesion_expired"),
                        buttons: ['OK'],
                        cssClass: 'sessionexp'
                    });
                    await alert.present();
                    await alert.onWillDismiss();
                    await authStore.sesionexpired();
                }else{
                    const alert = await alertController.create({
                        header: 'Error',
                        message: "Network Error",
                        buttons: ['OK']
                    });
                    await alert.present();
                }
                return null
            }
        );
    },

    batchRequest(path1:string, payload1:any, path2:string, payload2:any){
        return Promise.all([
                axios.post(path1, payload1),
                axios.post(path2, payload2),
            ]).then( async (response:AxiosResponse<any>[]) => {
                const data_axios1: StdResponse<any> = response[0].data
                const data_axios2: StdResponse<any> = response[1].data    
                if(data_axios1.status == 'Error' || data_axios2.status == 'Error'){
                    console.log('server error');
                    const alert = await alertController.create({
                        header: 'Error',
                        message: "Server Error",
                        buttons: ['OK']
                    });
                    await alert.present();
                    return null
                }
                return [data_axios1.data, data_axios2.data]
            }).catch( async (error) => {
                console.log('Error:', error.message);
                if(error.response.status == '401'){
                    console.error("sesión expirada")
                }else{
                    const alert = await alertController.create({
                        header: 'Error',
                        message: "Network Error",
                        buttons: ['OK']
                    });
                    await alert.present();
                }
                return null
            }
        )
    },

    formdataPost(url_post: string, form_data: FormData, onUploadProgress: any, ctrl:AbortController){
        return axios.post(url_post, form_data,{onUploadProgress, signal: ctrl.signal});
    },

    downloadFile(url:string, title:string){
        return axios.get(url, {responseType: 'arraybuffer'}).then((response)=>{
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

        }).catch(async (error)=>{
            console.log('Error:', error.message);
            const alert = await alertController.create({
                header: 'Error',
                message: "No se ha podido descargar el archivo",
                buttons: ['OK']
            });
            await alert.present();
        })
    },

    async getFile(fileURL:string, attempt: number):Promise<any>{
        if(attempt < 6){
            try {
                const resp = await axios({
                    method: 'get',
                    url: fileURL,
                    responseType: 'blob' //'arraybuffer'
                })
                const imgBase64 = await this.blobToData(resp.data); 
                return imgBase64
            }
            catch( e ) {
                // reintentar cada 5 seg hasta 5 veces
                await new Promise(f => setTimeout(f, 5000));
                return this.getFile(fileURL, attempt+1)
            }
        }
    },

    blobToData(blob:any):Promise<string | ArrayBuffer |null> {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
        })
    },

    async getSrc(url:string){
        const response = await  fetch(SERVER_URL+url, {
          method: 'GET',
          headers: {
            [ "Authorization"] : AuthService.getToken()
          }
        })
        const blob = await response.blob();
        const urlObject = URL.createObjectURL(blob);
        return urlObject
    }

};

export default HttpService; 




/*
axios.interceptors.request.use(function(config){
    const auth_token = AuthService.getToken();
    if(auth_token){
        if(!config.headers){
            config.headers = {}
        }
        config.headers.Authorization = auth_token
    }
    return config;
}, function(err){
    console.log("nerror :C")
    return Promise.reject(err);
})*/