import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "content-preview" }
const _hoisted_2 = ["data-swiper-autoplay"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "videoplay"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 2,
  "slides-per-view": 6,
  "centered-slides": false,
  navigation: true,
  loop: true,
  "watch-slides-progress": true,
  autoplay: {disableOnInteraction: false, waitForTransition: false},
  "free-mode": true,
  class: "swiperthumbs"
}
const _hoisted_9 = ["data-swiper-autoplay"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _directive_auth_src = _resolveDirective("auth-src")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("swiper-container", {
      effect: 'fade',
      ref: "swipermain",
      "slides-per-view": 1,
      "centered-slides": true,
      navigation: true,
      loop: true,
      autoplay: {disableOnInteraction: false, waitForTransition: false, delay:3000},
      "thumbs-swiper": ".swiperthumbs",
      class: "swipermain",
      onAutoplay: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAutoplay && _ctx.onAutoplay(...args))),
      onAutoplaytimeleft: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTimeLeft && _ctx.onTimeLeft(...args))),
      onSlidechange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSlideChange && _ctx.onSlideChange(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("swiper-slide", {
          key: item.id,
          class: _normalizeClass([item.transition? item.transition : '']),
          "data-swiper-autoplay": item.duration*1000
        }, [
          (!_ctx.playback)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _withDirectives(_createElementVNode("img", null, null, 512), [
                  [_directive_auth_src, _ctx.auth ? item.url_small : null]
                ]),
                (item.type == 'video')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.playback)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (item.type == 'image')
                  ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_6, null, 512)), [
                      [_directive_auth_src, _ctx.auth ? item.url : null]
                    ])
                  : _createCommentVNode("", true),
                (item.type == 'video')
                  ? _withDirectives((_openBlock(), _createElementBlock("video", {
                      key: 1,
                      muted: "",
                      autoplay: "",
                      loop: "",
                      src: item.url
                    }, null, 8, _hoisted_7)), [
                      [_directive_auth_src, _ctx.auth ? item.url : null]
                    ])
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      }), 128))
    ], 544),
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
          key: 0,
          value: _ctx.current_time * _ctx.percent_second
        }, null, 8, ["value"]))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("h1", null, _toDisplayString(_ctx.$t('labels.display')) + ": " + _toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, { slot: "end" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getStrTime(_ctx.current_time)) + " / " + _toDisplayString(_ctx.getStrTime(_ctx.total_time)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.thumbnails)
      ? (_openBlock(), _createElementBlock("swiper-container", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("swiper-slide", {
              key: item.id,
              "data-swiper-autoplay": item.duration*1000
            }, [
              _withDirectives(_createElementVNode("img", null, null, 512), [
                [_directive_auth_src, _ctx.auth ? item.url_small : null]
              ])
            ], 8, _hoisted_9))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}