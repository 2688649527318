import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  slot: "start",
  class: "ion-padding-start"
}
const _hoisted_2 = ["accept"]
const _hoisted_3 = { class: "content workspace" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppButtonIcon = _resolveComponent("AppButtonIcon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_TheContentMenuOrder = _resolveComponent("TheContentMenuOrder")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_AppSearchbar = _resolveComponent("AppSearchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_TheFolderBreadcrumb = _resolveComponent("TheFolderBreadcrumb")!
  const _component_ContentGrid = _resolveComponent("ContentGrid")!
  const _component_ContentList = _resolveComponent("ContentList")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_DropFileUploader = _resolveComponent("DropFileUploader")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_toolbar, {
      class: _normalizeClass([_ctx.show_searchbar? 'show first-toolbar' : 'first-toolbar'])
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("content_section.title")), 1),
        _createVNode(_component_ion_buttons, { slot: "start" }, {
          default: _withCtx(() => [
            (_ctx.check_enable)
              ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                  key: 0,
                  checked: _ctx.content_check,
                  disabled: !_ctx.check_enable,
                  onIonChange: _ctx.checkAllContents
                }, null, 8, ["checked", "disabled", "onIonChange"]))
              : _createCommentVNode("", true),
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                  key: 1,
                  id: "menu-add",
                  color: "medium",
                  class: _normalizeClass('custom'),
                  fill: false,
                  onClick: _ctx.handleUploadClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" + " + _toDisplayString(_ctx.$t('button_add')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.add_content')]
                ])
              : _createCommentVNode("", true),
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButtonIcon, {
                  key: 2,
                  icon: "create_new_folder",
                  color: "medium",
                  class: _normalizeClass('custom'),
                  disabled: _ctx.currentFolder!='',
                  onClick: _ctx.handleFolderCreate
                }, null, 8, ["disabled", "onClick"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.add_folder')]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_buttons, { slot: "end" }, {
          default: _withCtx(() => [
            (_ctx.ismobile)
              ? (_openBlock(), _createBlock(_component_AppButtonIcon, {
                  key: 0,
                  id: "open-add-modal",
                  color: "medium",
                  class: _normalizeClass('custom'),
                  icon: "add",
                  onClick: _ctx.handleUploadClick
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_AppButtonIcon, {
              color: "medium",
              class: _normalizeClass('custom'),
              icon: "search",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.set_show_searchbar(!_ctx.show_searchbar)))
            }, null, 512), [
              [_directive_tooltip, _ctx.$t('tooltip.search')]
            ]),
            _createVNode(_component_TheContentMenuOrder, {
              id: "content",
              selected: _ctx.order_select,
              onOnMenuClick: _ctx.set_order_select
            }, null, 8, ["selected", "onOnMenuClick"]),
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                  key: 1,
                  fill: false,
                  color: "medium",
                  class: _normalizeClass('custom'),
                  onClick: _ctx.contentStore.changeStateSelect
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('button_select')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.btn_select')]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("input", {
              id: "fileUpload",
              ref: "fileUpload",
              type: "file",
              multiple: true,
              accept: _ctx.extension_accepted,
              style: {"display":"none"},
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args)))
            }, null, 40, _hoisted_2),
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButtonIcon, {
                  key: 2,
                  icon: "file_download",
                  disabled: !_ctx.anyIsChecked,
                  onClick: _ctx.handleDownloadFiles,
                  color: "medium",
                  class: _normalizeClass('custom')
                }, null, 8, ["disabled", "onClick"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.download')]
                ])
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_AppButtonIcon, {
              color: "medium",
              class: _normalizeClass('custom'),
              icon: _ctx.view_type=='grid' ? 'format_list_bulleted':'grid_view',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.change_viewtype()))
            }, null, 8, ["icon"]), [
              [_directive_tooltip, _ctx.$t('tooltip.view_type')]
            ]),
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButtonIcon, {
                  key: 3,
                  color: "medium",
                  class: _normalizeClass('custom'),
                  icon: "delete",
                  disabled: !_ctx.anyIsChecked,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleDelete()))
                }, null, 8, ["disabled"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.delete')]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.show_searchbar)
      ? (_openBlock(), _createBlock(_component_ion_toolbar, {
          key: 0,
          class: "action-toolbar third-toolbar"
        }, {
          default: _withCtx(() => [
            (!_ctx.ismobile)
              ? (_openBlock(), _createBlock(_component_ion_select, {
                  key: 0,
                  interface: "popover",
                  placeholder: _ctx.$t(_ctx.types_filter[0].value),
                  "aria-label": "file type",
                  slot: "start",
                  onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.set_type_search($event.detail.value))),
                  class: "select-type"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types_filter, (option) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: option.key,
                        value: option.key,
                        disabled: option.disabled
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(option.value)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["placeholder"]))
              : _createCommentVNode("", true),
            _createVNode(_component_AppSearchbar, { onOnInput: _ctx.set_text_search }, null, 8, ["onOnInput"]),
            _withDirectives(_createVNode(_component_AppButtonIcon, {
              icon: "expand_less",
              color: "medium",
              class: _normalizeClass('custom'),
              slot: "end",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.set_show_searchbar(false)))
            }, null, 512), [
              [_directive_tooltip, _ctx.$t('tooltip.hide')]
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.ismobile && _ctx.show_searchbar)
      ? (_openBlock(), _createBlock(_component_ion_toolbar, {
          key: 1,
          class: "action-toolbar third-toolbar"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types_filter.slice(1), (option) => {
              return (_openBlock(), _createBlock(_component_ion_chip, {
                key: option.key,
                color: "primary",
                class: _normalizeClass(_ctx.type_search== 'all' || _ctx.type_search == option.key ? 'show' : 'ion-hide'),
                onClick: ($event: any) => (_ctx.handleChip(option.key))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(option.value)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_button, { fill: "clear" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AppIcon, { icon: "close" })
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["class", "onClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.currentFolder!='')
      ? (_openBlock(), _createBlock(_component_TheFolderBreadcrumb, {
          key: 2,
          current: _ctx.currentFolder,
          onOnItemClick: _ctx.handleOpenFolder
        }, null, 8, ["current", "onOnItemClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DropFileUploader, {
        total: _ctx.filteredContentList.length,
        searching: _ctx.show_searchbar,
        onOnFilesDrop: _ctx.handleUpload
      }, {
        default: _withCtx(() => [
          (_ctx.view_type == 'grid')
            ? (_openBlock(), _createBlock(_component_ContentGrid, {
                key: 0,
                "content-list": _ctx.filteredContentList,
                folders: _ctx.filteredFolderList,
                "menu-items": _ctx.menu_items,
                "folder-menu-items": _ctx.menu_items_folder,
                "is-open-folder": _ctx.currentFolder!='',
                onOnView: _ctx.handleViewContent,
                onOnClickMenu: _ctx.handleClickMenu,
                onOnClickMenuFolder: _ctx.handleClickMenuFolder,
                onOnClickFolder: _ctx.handleOpenFolder
              }, null, 8, ["content-list", "folders", "menu-items", "folder-menu-items", "is-open-folder", "onOnView", "onOnClickMenu", "onOnClickMenuFolder", "onOnClickFolder"]))
            : _createCommentVNode("", true),
          (_ctx.view_type == 'table')
            ? (_openBlock(), _createBlock(_component_ContentList, {
                key: 1,
                "content-list": _ctx.filteredContentList,
                folders: _ctx.filteredFolderList,
                "menu-items": _ctx.menu_items,
                "folder-menu-items": _ctx.menu_items_folder,
                "is-open-folder": _ctx.currentFolder!='',
                onOnView: _ctx.handleViewContent,
                onOnClickMenu: _ctx.handleClickMenu,
                onOnClickMenuFolder: _ctx.handleClickMenuFolder,
                onOnClickFolder: _ctx.handleOpenFolder
              }, null, 8, ["content-list", "folders", "menu-items", "folder-menu-items", "is-open-folder", "onOnView", "onOnClickMenu", "onOnClickMenuFolder", "onOnClickFolder"]))
            : _createCommentVNode("", true),
          (_ctx.ismobile)
            ? (_openBlock(), _createBlock(_component_ion_refresher, {
                key: 2,
                slot: "fixed",
                onIonRefresh: _ctx.handleRefresh
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_refresher_content, {
                    refreshingText: _ctx.$t('loader.load')
                  }, null, 8, ["refreshingText"])
                ]),
                _: 1
              }, 8, ["onIonRefresh"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["total", "searching", "onOnFilesDrop"])
    ])
  ], 64))
}