import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "empty-devices content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_AppPopoverMenu = _resolveComponent("AppPopoverMenu")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.devices.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_list, { class: "m-devices-list" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (device) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'device-'+device.id_device,
            class: _normalizeClass(device.id_device == _ctx.deviceSelectedId ? 'selected-device' : '')
          }, [
            _createVNode(_component_ion_item, {
              button: "",
              class: _normalizeClass(device.id_device == _ctx.deviceSelectedId ? 'selected-device' : ''),
              onClick: ($event: any) => (_ctx.$emit('onItemClick',device))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    (!device.is_player)
                      ? (_openBlock(), _createBlock(_component_AppIcon, {
                          key: 0,
                          icon: "tv",
                          color: device.connected? 'success':'medium',
                          class: _normalizeClass(['icon-tv'])
                        }, null, 8, ["color"]))
                      : (_openBlock(), _createBlock(_component_AppIcon, {
                          key: 1,
                          icon: "audio_video_receiver",
                          symbols: true,
                          color: device.connected? 'success':'medium',
                          class: _normalizeClass(['icon-tv'])
                        }, null, 8, ["color"])),
                    _createTextVNode(" " + _toDisplayString(_ctx.shortenTitle(device.name, true)), 1)
                  ]),
                  _: 2
                }, 1024),
                (device.publish_status == _ctx.STATUS_WORKING && device.percentage_number != undefined)
                  ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                      key: 0,
                      color: "secondary",
                      value: device.percentage_number / 100
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
                ( !_ctx.ismobile)
                  ? (_openBlock(), _createBlock(_component_ion_label, {
                      key: 1,
                      slot: "end"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(device.publish_status == _ctx.STATUS_WORKING ? device.percentage_text : _ctx.$t(_ctx.getStatusText(device.publish_status))), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["class", "onClick"]),
            _createVNode(_component_ion_buttons, null, {
              default: _withCtx(() => [
                _createVNode(_component_AppPopoverMenu, {
                  "id-menu": 'device-'+device.id_device,
                  items: _ctx.menu_items,
                  data: device,
                  onOnClick: _ctx.handleClickMenu
                }, null, 8, ["id-menu", "items", "data", "onOnClick"]),
                (device.id_device == _ctx.deviceSelectedId)
                  ? _withDirectives((_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      fill: "clear",
                      color: "medium",
                      class: _normalizeClass('custom'),
                      onClick: ($event: any) => (_ctx.$emit('onItemClick',device))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AppIcon, { icon: "close_fullscreen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])), [
                      [_directive_tooltip, _ctx.$t('button_close')]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}