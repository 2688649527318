import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
    slot: "end",
    class: "lang"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_select, {
        modelValue: _ctx.$i18n.locale,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
        "aria-label": "lang",
        interface: "popover",
        "interface-options": _ctx.class_lang,
        onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLanguage($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_select_option, { value: "en" }, {
            default: _withCtx(() => [
              _createTextVNode("EN")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_select_option, { value: "es" }, {
            default: _withCtx(() => [
              _createTextVNode("ES")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "interface-options"])
    ]),
    _: 1
  })), [
    [_directive_tooltip, _ctx.$t('tooltip.lang')]
  ])
}