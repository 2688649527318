import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (menu) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: menu.key,
          button: true,
          disabled: menu.disabled,
          class: "item-menu",
          onClick: ($event: any) => (_ctx.handleClickMenu(menu))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              slot: "start",
              fill: "outline",
              class: "icon-menu custom",
              color: "medium"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AppIcon, {
                  icon: menu.icon
                }, null, 8, ["icon"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(menu.title)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}