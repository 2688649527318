
//import { Swiper, SwiperSlide } from 'swiper/vue';
import {  IonItem, IonLabel, IonProgressBar  } from '@ionic/vue';
//import  { Autoplay, Navigation, Thumbs } from 'swiper/modules';
//import { SwiperOptions } from 'swiper/types';
import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/thumbs"
import '@ionic/vue/css/ionic-swiper.css';
import { defineComponent, ref } from 'vue';
import { AppSlideItem } from '@/core/interfaces/AppComponents';
import { getStrTime } from '@/core/utils'

    /*
        https://swiperjs.com/vue#usage
    */

register()
export default defineComponent({
    name: 'AppSlides',

    components:{
        IonItem, IonLabel,
        IonProgressBar,
        //IonButton,
        //AppIcon
    },

    props:{
        title: {
            type: String,
            required: false
        },
        items:{
            type:  Array<AppSlideItem>,
            required: true,
        },
        auth:{
            type: Boolean,
            required: false,
            default: false
        },
        thumbnails:{
            type: Boolean,
            required: false,
            default: false
        },
        playback:{
            type: Boolean,
            default: true
        },
    },
    
    setup(props){
        const swipermain = ref()
        const play =ref(true)
        const current_slide =ref(0)
        //seconds
        const current_time =ref(0)
        let total_time = 0 
        let times :number[] = []
        props.items.forEach(x=>{
            total_time += x.duration
            times.push(total_time)
        })
        const percent_second = 1/total_time

        const onTimeLeft = (e:any) =>{
            // eslint-disable-next-line
            const [swiper, time, progress] = e.detail;
            const correct_time= time < 0 ? 0 : time / 1000
            //const current_slidetime = props.items[current_slide.value].duration
            current_time.value = times[current_slide.value ] - Math.round(correct_time) 
            //console.log(time)
            //console.log('time', current_slidetime - Math.round(time/1000))//,'progress', progress)
        }
        const onAutoplay= (e:any) =>{
            /*const [swiper] = e.detail;
            current_slide.value = swiper.realIndex*/
        }
        const onSlideChange = (e:any) => {
            const [swiper] = e.detail;
            current_slide.value = swiper.realIndex
            //console.log("change slide",swiper.realIndex)
        }
        const handlePlay = () =>{
            play.value = !play.value
            if(play.value)
                swipermain.value.swiper.autoplay.start()
            else
            swipermain.value.swiper.autoplay.stop()
        }
        return {
            swipermain,
            //modules: [Autoplay, /*Pagination,*/ Thumbs, Navigation], 
            play, handlePlay,
            percent_second,
            total_time, current_time,
            getStrTime,
            onTimeLeft,
            onAutoplay,
            onSlideChange,
        }
    }  
})
