import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65b5b61a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { draggable: "false" }
const _hoisted_2 = {
  slot: "end",
  class: "list-icons"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_reorder = _resolveComponent("ion-reorder")!
  const _component_AppPopoverMenu = _resolveComponent("AppPopoverMenu")!
  const _component_ion_reorder_group = _resolveComponent("ion-reorder-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _directive_thumb = _resolveDirective("thumb")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.deviceItems.length>0)
    ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_reorder_group, {
            disabled: _ctx.check_enable,
            onIonItemReorder: _ctx.handleReorder
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceItems, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'item-'+item.id_playlist_item,
                  class: _normalizeClass([item.check ? 'reorder-list item-selected' : 'reorder-list'])
                }, [
                  (!_ctx.ismobile)
                    ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                        key: 0,
                        slot: "start",
                        modelValue: item.check,
                        "onUpdate:modelValue": ($event: any) => ((item.check) = $event),
                        checked: item.check,
                        class: _normalizeClass([_ctx.check_enable?'show':'hide'])
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "checked", "class"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_reorder, {
                    class: _normalizeClass(_ctx.check_enable && !_ctx.ismobile? '':'bg-drag')
                  }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
                        class: "list-item",
                        onClick: ($event: any) => (_ctx.handleClickCard(item))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("img", _hoisted_1, null, 512), [
                                [_directive_thumb, item.Content]
                              ]),
                              _createElementVNode("span", null, _toDisplayString(item.index), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, _toDisplayString(_ctx.shortenTitle(item.Content.title, false)) + "." + _toDisplayString(item.Content.extension), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.getStrTime(item.duration)), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.getStrSize(item.Content.File.weight)), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("div", _hoisted_2, [
                            (item.Content.is_from_editor)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: _ctx.logoe4u,
                                  slot: "end",
                                  class: "list-editor-icon"
                                }, null, 8, _hoisted_3))
                              : _createCommentVNode("", true),
                            (item.Content.validity_start!=0)
                              ? (_openBlock(), _createBlock(_component_AppIcon, {
                                  key: 1,
                                  slot: "end",
                                  icon: "access_alarm",
                                  class: "icon-alarm list-editor-icon"
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["onClick"])), [
                        [_directive_tooltip, item.Content.title+'.'+ item.Content.extension]
                      ])
                    ]),
                    _: 2
                  }, 1032, ["class"]),
                  _createVNode(_component_AppPopoverMenu, {
                    "id-menu": 'item-'+item.id_playlist_item,
                    items: _ctx.menuItems,
                    data: item,
                    slot: "end",
                    class: _normalizeClass(_ctx.check_enable?'hide':'show'),
                    onOnClick: _ctx.handleClickMenu
                  }, null, 8, ["id-menu", "items", "data", "class", "onOnClick"])
                ], 2))
              }), 128))
            ]),
            _: 1
          }, 8, ["disabled", "onIonItemReorder"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}