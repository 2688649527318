import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "playlist workspace" }
const _hoisted_3 = { draggable: "false" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_AppPopoverMenu = _resolveComponent("AppPopoverMenu")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_reorder = _resolveComponent("ion-reorder")!
  const _component_ion_reorder_group = _resolveComponent("ion-reorder-group")!
  const _component_DroppableArea = _resolveComponent("DroppableArea")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_auth_src = _resolveDirective("auth-src")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_toolbar, { class: "first-toolbar" }, {
      default: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('playlist_section.title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_toolbar, {
      class: _normalizeClass('action-toolbar')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_buttons, { slot: "start" }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
              fill: false,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalPlaylist(null)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" + " + _toDisplayString(_ctx.$t('button_add')), 1)
              ]),
              _: 1
            })), [
              [_directive_tooltip, _ctx.$t('tooltip.add_playlist')]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_list, { class: "m-playlist-list" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playlists, (playlist) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'item-'+playlist.id_playlist
            }, [
              _createVNode(_component_ion_item, {
                class: "list-item",
                style: _normalizeStyle('border-left: 5px solid '+playlist.color),
                onClick: ($event: any) => (_ctx.handlePlaylistClick(playlist))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, _toDisplayString(playlist.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  (playlist.shuffle)
                    ? (_openBlock(), _createBlock(_component_AppIcon, {
                        key: 0,
                        icon: "shuffle",
                        slot: "end",
                        class: "icon-shuffle"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["style", "onClick"]),
              _createVNode(_component_AppPopoverMenu, {
                "id-menu": 'playlist-'+playlist.id_playlist,
                items: _ctx.menu_items,
                data: playlist,
                onOnClick: _ctx.handleClickMenu
              }, null, 8, ["id-menu", "items", "data", "onOnClick"])
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, { class: "playlist-items" }, {
        default: _withCtx(() => [
          _createVNode(_component_DroppableArea, {
            ref: "dropRef",
            onOnDropp: _ctx.handleDrop,
            isEmpty: _ctx.items.length>0
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_reorder_group, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'item-'+item.id_playlist_item
                        }, [
                          _createVNode(_component_ion_reorder, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { class: "list-item" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                    default: _withCtx(() => [
                                      _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
                                        [_directive_auth_src,  item.Content.local_path_small]
                                      ]),
                                      _createElementVNode("span", null, _toDisplayString(item.index), 1),
                                      (item.Content.validity_start!=0)
                                        ? (_openBlock(), _createBlock(_component_AppIcon, {
                                            key: 0,
                                            icon: "access_alarm",
                                            class: "icon-alarm"
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h3", null, _toDisplayString(_ctx.shortenTitle(item.Content.title, false)) + "." + _toDisplayString(item.Content.extension), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (item.Content.is_from_editor)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: _ctx.logoe4u,
                                        slot: "end",
                                        class: "list-editor-icon"
                                      }, null, 8, _hoisted_4))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onOnDropp", "isEmpty"])
        ]),
        _: 1
      })
    ])
  ], 64))
}