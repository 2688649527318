
import {
    IonContent, IonToolbar, IonFooter, IonButtons,
    IonRow, IonCheckbox,
    IonList, IonItem, IonLabel, IonInput, 
    IonTitle,  modalController, //IonThumbnail, 
} from '@ionic/vue';
import { defineComponent, reactive } from 'vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppModal from '@/uicomponents/AppModal.vue';
import { computed } from '@vue/reactivity';
import { Playlist, PlaylistUpdate, playlist_types } from '@/core/interfaces/Playlist';

export default defineComponent({
    name: 'ModalPlaylist',

    components: {
        IonContent,
        IonToolbar,
        IonFooter,
        IonTitle,
        IonRow,
        IonLabel,
        IonItem,
        IonInput,
        AppButton,
        AppModal,
        IonButtons,
        IonList,
        IonCheckbox,
        
    },

    props: {
        playlist: {
            type: Object as () => Playlist,
            required: false
        }
    },

    setup(props) {
        const form_state = reactive({
            edited: false,
        })
        //console.log(props.playlist)

        /*------------------form--------------------*/
        const form = reactive<PlaylistUpdate>(props.playlist?? {
            name: '',
            shuffle: false, 
            mute: false, 
            color: ''
        });
        const isFormInvalid = computed(() => {
            return (
                form.name == '' ||
                (!form_state.edited ) 
            )
        });

        const handleSaveData = () => {
            //console.log(this.form)
            if (form.name != '') {
                var data: PlaylistUpdate | null = null
                if (form_state.edited) {
                    data = form
                }
                modalController.dismiss(data, 'save');
            }
        };

        const handleCloseModal = () => {
            modalController.dismiss(null, 'cancel');
        };
        return {
            form_state, form,
            isFormInvalid,
            playlist_types,
            handleSaveData,
            handleCloseModal
        }
    }
});

