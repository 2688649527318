
import {  IonChip, IonLabel, IonButton,  } from '@ionic/vue';
  import { close} from 'ionicons/icons';
import { defineComponent,  } from 'vue';
import AppIcon from '@/uicomponents/AppIcon.vue';


export default defineComponent({
    name: 'AppSnackbar',

    components:{
        IonChip, 
        IonLabel,
        IonButton, 
        AppIcon
    },

    props:{
        type:{
            type: String,
            required: true,
            validator(value:string) {
                // The value must match one of these strings
                return ['success', 'info', 'warning', 'danger'].includes(value)
            },
        },
        buttonText:{
            type:String,
            required: false
        }
    },

    emits: ['onClose', 'onAction'],

    setup(){
        return {
            close
        }
    }
})
