import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2135c00a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "src", "onVnodeMounted"]
const _hoisted_2 = ["id", "src", "title", "onLoadeddata"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_AppBottomSheet = _resolveComponent("AppBottomSheet")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.show_sheet)
    ? (_openBlock(), _createBlock(_component_AppBottomSheet, {
        key: 0,
        title: _ctx.$t('sheet_upload.title', {cant: _ctx.uploaded, total: _ctx.total}) ,
        progress: _ctx.total_percent,
        "enable-close":  _ctx.finisherr == _ctx.total,
        color: _ctx.upload_error > 0 ? 'secondary' : _ctx.uploaded == 0 || _ctx.finisherr < _ctx.total ? 'accent': 'primary',
        onOnClose: _ctx.handleClose
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_AppIcon, { icon: "add_photo_alternate" })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (item, index) => {
                return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_thumbnail, {
                      slot: "start",
                      class: "content-img"
                    }, {
                      default: _withCtx(() => [
                        (item.file.type.includes('image'))
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              id: 'img-'+index,
                              src: item.path,
                              onVnodeMounted: ($event: any) => (_ctx.onLoadedImg(item, index))
                            }, null, 8, _hoisted_1))
                          : _createCommentVNode("", true),
                        (item.file.type.includes('video'))
                          ? (_openBlock(), _createElementBlock("video", {
                              key: 1,
                              id: 'video-'+index,
                              src: item.path+'#t=5',
                              title: item.file.name,
                              onLoadeddata: ($event: any) => (_ctx.onLoadedVideo($event, index))
                            }, null, 40, _hoisted_2))
                          : _createCommentVNode("", true),
                        _createElementVNode("canvas", {
                          id: 'canvas-'+index,
                          class: "ion-hide",
                          width: "128",
                          height: "72"
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_label, {
                      slot: "start",
                      class: "label-name-upload"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h6", null, _toDisplayString(_ctx.shortenTitle(item.file.name, false)), 1),
                        (_ctx.ismobile)
                          ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                              key: 0,
                              value: item.progress,
                              type: item.progress == 1 ? 'indeterminate':'determinate'
                            }, null, 8, ["value", "type"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    (!_ctx.ismobile)
                      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                          key: 0,
                          value: item.progress,
                          type: item.progress == 1 ? 'indeterminate':'determinate'
                        }, null, 8, ["value", "type"]))
                      : _createCommentVNode("", true),
                    (item.progress >=0 && item.progress < 1)
                      ? _withDirectives((_openBlock(), _createBlock(_component_ion_button, {
                          key: 1,
                          slot: "end",
                          fill: "clear",
                          onClick: ($event: any) => (_ctx.handleCancel(index))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AppIcon, { icon: "cancel" })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_tooltip, _ctx.$t('button_cancel')]
                        ])
                      : _createCommentVNode("", true),
                    (item.progress==-2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('state_cancel')), 1))
                      : _createCommentVNode("", true),
                    (item.progress==-1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _createTextVNode(_toDisplayString(_ctx.$t('state_error')) + " ", 1),
                          _withDirectives((_openBlock(), _createBlock(_component_ion_button, {
                            fill: "clear",
                            onClick: ($event: any) => (_ctx.handleRetry(index))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AppIcon, { icon: "sync" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])), [
                            [_directive_tooltip, _ctx.$t('button_retry')]
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (item.progress==1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('state_process')), 1))
                      : _createCommentVNode("", true),
                    (item.progress == 2)
                      ? (_openBlock(), _createBlock(_component_AppIcon, {
                          key: 5,
                          slot: "end",
                          icon: "check_circle"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title", "progress", "enable-close", "color", "onOnClose"]))
    : _createCommentVNode("", true)
}