import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheManagerHeader = _resolveComponent("TheManagerHeader")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_TheUserMenu = _resolveComponent("TheUserMenu")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    id: "main-content",
    class: "ion-page"
  }, {
    default: _withCtx(() => [
      (!_ctx.ismobile)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ion_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_TheManagerHeader)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_content, {
              fullscreen: true,
              "scroll-y": false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, { class: "main-row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, {
                          size: "4",
                          class: "menu-section"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TheUserMenu, {
                              menu: _ctx.usermenu,
                              selected: _ctx.menuselect.id,
                              onOnMenuClick: _ctx.set_menuselect
                            }, null, 8, ["menu", "selected", "onOnMenuClick"]),
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_1, "version " + _toDisplayString(_ctx.versionmanager), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, {
                          size: "8",
                          class: "body-section"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}