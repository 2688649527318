
const CACHE_KEY= "_thumbcache_";
export const CacheService = {

    saveTumbCache(filekey: string, data: string){
        const key = `${CACHE_KEY}${filekey}`;
        return localStorage.setItem(key, data);

    },

    getCachedTumb(filekey: string){
        const key = `${CACHE_KEY}${filekey}`;
        const storedValue = localStorage.getItem(key);
        return storedValue;
    },

    removeCacheEntry(filekey: string){
        const key = `${CACHE_KEY}${filekey}`;
        localStorage.removeItem(key)
    },

    removeCacheEntries(filekey: string[]){
        filekey.forEach(element => {
            const key = `${CACHE_KEY}${element}`;
            localStorage.removeItem(key)            
        });
    }
}

export default CacheService;