import { DeviceContent } from '../interfaces/Content';
import { Device, DeviceUpdate, MemoryInfo } from '../interfaces/Device';
import HttpService from './HttpService';

export const DeviceService = {
    pathGetDevices :'/manager/device/list',

    async getDevices(): Promise<Device[]> {
        const response = await HttpService.jsonPost('/manager/device/list', {});
        return response ? response : [];
    },
    
    async getDevicesConnection(): Promise<Device[]> {
        const response = await HttpService.jsonPost('/manager/device/list-connection', {});
        return response ? response : [];
    },
    
    async getDeviceInfo(id_device: number): Promise<{'info':Device,'items':any[]}> {
        const payload = {
            "id_device": id_device
        }
        const response = await HttpService.jsonPost('/manager/device/info', payload);
        return response;
    },

    async getDeviceMemoryInfo(id_device: number): Promise<MemoryInfo[]> {
        const payload = {
            "id_device": id_device
        }
        const response = await HttpService.jsonPost('/manager/device/memory-info', payload);
        return response;
    },

    async getDeviceContent(id_device: number): Promise<any[]> {
        const payload = {
            "id_device": id_device
        }
        const response = await HttpService.jsonPost('/manager/device/content', payload);
        return response ? response : [];
    },

    async addItemsToDevices(id_devices: number[], items:any): Promise<{Updated:number}> {
        const payload = {
            "id_devices": id_devices,
            "items": items
        }
        const response = await HttpService.jsonPost('/manager/device/add-items-to', payload);
        return response;
    },
    
    async updateDeviceContent(id_device: number, items:any): Promise<{Updated:number,Items:DeviceContent[]}> {
        const payload = {
            "id_device": id_device,
            "items": items
        }
        const response = await HttpService.jsonPost('/manager/device/update-content', payload);
        return response;
    },

    async updateDeviceInfo(id_device: number, data: DeviceUpdate): Promise<any> {
        const payload = {
            "id_device": id_device,
            "name": data.name,
            "description": data.description,
        }
        const response = await HttpService.jsonPost('/manager/device/update-info', payload);
        return response;
    },
    
    async publishDevice(id_device: number): Promise<any> {
        const payload = {
            "id_device": id_device
        }
        const response = await HttpService.jsonPost('/manager/device/publish', payload);
        return response;
    },

    async getPublishStatus(id_device:number):Promise<{text:string,number:number}>{
        const payload = {
            "id_device": id_device
        }
        const response = await HttpService.jsonPost('/manager/device/publish-status', payload);
        return response;
    },

    async updateControl(id_device:number, option: string):Promise<{text:string,number:number}>{
        const payload = {
            "id_device": id_device,
            "option": option
        }
        const response = await HttpService.jsonPost('/manager/device/update-control', payload);
        return response;
    }
};

export default DeviceService;