import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "time-picker" }
const _hoisted_2 = { class: "input-sel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_input, {
      inputmode: "numeric",
      type: "number",
      step: "1",
      min: "7",
      max: "3600",
      maxlength: 4,
      onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
      value: _ctx.time_value,
      slot: _ctx.slot,
      "aria-labelledby": "time",
      disabled: _ctx.disabled,
      onIonInput: _ctx.handleInputChange
    }, null, 8, ["value", "slot", "disabled", "onIonInput"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('time_sec')), 1)
  ]))
}