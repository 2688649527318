
import { isPlatform } from '@ionic/vue';
import { defineComponent, onMounted, onUnmounted, ref, } from 'vue';
    
    export default defineComponent({
        name: 'DropFileUploader',
    
        components: {
        },
    
        props: {
            total:{
                type: Number,
                required: true
            },
            searching: {
                type: Boolean,
                required: true
            }
        },

        emits:['onFilesDrop'],
        
        setup(props, context){  
            const ismobile = isPlatform('mobile') 
            const isDragging = ref(false)

            const handleDragover = (e:DragEvent) => {
                e.preventDefault();
                isDragging.value = true;
                document.querySelectorAll('.dropfile')[0].classList.add('onDrop');
            };

            const handleDragleave = () =>{
                isDragging.value = false;
                document.querySelectorAll('.dropfile')[0].classList.remove('onDrop');
            };

            const handleDrop = (event:DragEvent) => {
                document.querySelectorAll('.dropfile')[0].classList.remove('onDrop');
                const datatransfer = event.dataTransfer
                if(datatransfer && datatransfer.files.length>0)
                    context.emit('onFilesDrop', datatransfer.files)
            };

            const preventDefaults = (e:Event) => {  e.preventDefault()  }
            const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

            onMounted(()=> {
                events.forEach((eventName) => { document.body.addEventListener(eventName, preventDefaults)  })
            });

            onUnmounted(()=>{
                events.forEach((eventName) => { document.body.removeEventListener(eventName, preventDefaults) })
            })
            return {
                ismobile,
                handleDragover,
                handleDragleave,
                handleDrop,
            }
        }
    })
