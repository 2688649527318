export const es= {
    display: "Pantalla",
    searchbar: "¿Qué estás buscando?",
    type_all: "Todos",
    type_files: 'Archivos',
    type_video: 'Video',
    type_image: 'Imagen',
    type_folder: "Carpetas",
    order_by: "Ordenar por",
    type_new: 'Más recientes',
    type_az: 'Nombre A-Z',
    type_za: 'Nombre Z-A',
    type_tipo: 'Tipo',
    type_peso: 'Peso',
    button_add: "Agregar",
    button_cancel: "Cancelar",
    button_retry: "Reintentar",
    button_close: "Cerrar",
    button_accept: "Aceptar",
    button_ok: "Ok",
    button_save: "Guardar",
    button_save_changes: "Guardar Cambios",
    button_select: "Seleccionar",
    lang: "Idioma",
    menu_open: "Abrir",
    menu_properties: "Propiedades",
    menu_rename: "Renombrar",
    menu_download: "Descargar",
    menu_delete: "Eliminar",
    menu_remove: "Quitar",
    menu_on: "Prender",
    menu_off: "Apagar",
    menu_restart: "Reiniciar",
    menu_copy: "Copiar contenidos a..",
    menu_publish: "Publicar",
    menu_preview: "Previsualizar",
    main_menu: {
        dashboard: "Dashboard",
        publish: "Publicación"
    },
    //Content states
    state_cancel: "Cancelado",
    state_error: "Error",
    state_process: "Procesando",
    selected: "Seleccionados:",
    time_sec: "segundos",
    //tuto
    tutorial:{
        header: "es muy fácil de usar",
        section: "Sección de {name}",
        btn_next: 'Siguiente',
        btn_skip: 'Saltar tutorial'
    },
    plan:{
        basic: 'Plan Básico'
    },
    dashboard:{
        greeting: "Hola {name}",
        last_login: "Último ingreso {date}",
        description: "Este es tu dashboard con toda tu información",
        profile_btn: "Ir a mi perfil",
        my_displays: "Mis dispositivos",
        display_info: "Selecciona el dispositivo que deseas ver",
        display_info_no: "Aún no tienes ningún player o pantalla. \n Debes comprar una o activar tu licencia",
        activate_btn : "Activar licencia",
        ad_title: "¿Quieres añadir una nueva pantalla?",
        ad_info: "Puedes añadir una nueva pantalla una vez que tengas tu reproductor EC4Y.",
        ad_btn: "Comprar Pantalla",
        displays: "Dispositivos",
        plan_from: "Activo desde",
        plan_payment: "Fecha de cobro",
        plan_to: "Activo hasta",
        plan_btn: "Modificar subscripción",
        memory: "Memoria",
        memory_no: "No tienes dispositivos disponibles",
        info_title: "Información Dispositivo",
        data_transfer: "Transferencia de datos último mes",
        m_videos: 'Videos',
        m_images:'Imágenes',
        m_used: 'Memoria ocupada',
        m_free: 'Espacio libre'
    },
    empty_txt:{
        files:'Arrastra tus archivos, o usa el botón agregar',
        files_mob: 'Para agregar archivos usa el botón agregar',
        search: 'Oops! No encontramos coincidencias con tu búsqueda',
        playlist: 'Agrega contenido a tu playlist'
    },
    msg_err:{
        startdot: 'No puedes utilizar un nombre que comience por un punto "."',
        badchars: "El nombre no puede contener los siguientes caracteres \\ / * : < > {'|'}",
        existname: 'El nombre "{name}" ya existe. Por favor, coloca otro.'

    },
    content_section:{
        title: "Media",
        load_more: "Cargar más",
        delete_title: "Eliminar contenidos",
        delete_info: "Alguno de los contenidos seleccionados está siendo usado. ¿Seguro desea eliminarlo?",
        delete_folder: "Eliminar carpetas",
        delete_alert: "Una carpeta que desea eliminar tiene contenidos, ¿Está seguro de eliminarlos?",
        //size_alert: "El archivo {name} podría no reproducirse/verse correctamente, te recomendamos que subas archivos con calidad HD o hasta 4K",
        size_alert: "El archivo {name} podría no verse correctamente, te recomendamos que subas archivo con calidad mínima HD y máximo 4K",
        max_size_alert: "El archivo {name} excede el tamaño máximo permitido.",
        ext_alert: "El archivo {name} seleccionado tiene un formato no soportado. Los formatos válidos son {extension}"
    },
    folder:{
        title_new: "Crear carpeta nueva",
        info_new: "Ingrese el nombre de la nueva carpeta a crear",
        title_rename: "Renombrar carpeta ",
        info_rename: "Ingrese el nuevo nombre de la carpeta",
    },
    sheet_upload:{
        title: "Cargando {cant} archivo(s) de {total}",
    },
    day_picker:{
        l: "Lu",
        m: "Ma",
        c: "Mi",
        j: "Ju",
        v: "Vi",
        s: "Sa",
        d: "Do"
    },
    m_content_view:{
        button_download: "Descargar",
        button_update: "Reemplazar",
        title_display: "Propiedades visualización",
        title_uses: "Contenido usado en:",
        segment_info: "Info",
        segment_props: "Propiedades",
        transition: "Transición",
        trans_none: "Ninguna",
        uses_info:"Al seleccionar una pantalla estarás añadiendo el contenido seleccionado"
    },
    transition:{
        none: 'Ninguna',
        slide: 'Slide left',
        zoom: 'Zoom out',
        fade: 'Fade in'
    },
    device_section:{
        title: "Dispositivos",
        button_buy: "Comprar pantallas",
        label_items: "Playlist",
        add_content: "Agregar contenidos a {device}"
    },
    device_state:{
        nothing: 'Nada que publicar',
        error: 'Error de conexión',
        pending: 'Publicación pendiente',
        done: 'Publicado'
    },
    m_device_view:{
        segment_info: "Info",
        segment_content: "Contenidos",
        segment_logs: "Logs"
    },
    m_device_copy_items:{
        title: "Copiar items a ",
        label: "Seleccione la(s) pantallas a donde quiere copiar"
    },
    labels:{
        name: "Nombre",
        description: "Descripción",        
        size: "Tamaño",
        weight: "Peso",
        dimensions: "Dimensiones",
        width: "Ancho",
        height: "Alto",
        duration: "Duración",
        default_duration: "Duración predeterminada",
        mute: "Mute",
        version: "Versión",
        create: "Creado",
        update: "Modificado",
        validity: "Fecha de vigencia",
        undefined: "Indefinido", // sin fecha de vigencia
        start: "Inicio",
        end: "Fin",
        content: "Contenido",
        state: "Estado",
        active: "Activo",
        inactive: "Inactivo",
        type: "Tipo",
        
        display: "Dispositivo",
        model: "Modelo",
        serial: "Serial",
        mac: "Dirección MAC",
        ip: "IP",
        resolution: "Resolución",        
        last_conection: "Última conexión",
        publish_date: "Fecha publicación",
        publish_status: "Estado de publicación",
        memory: "Memoria total",
        free_space: "Espacio disponible",
        total_time: "Duración total",
        orientation: "Orientación",
        landscape: "Horizontal",
        portrait: "Vertical",
        transition: "Transición",
    },
    tooltip: {
        profile: "Ver mi perfil",
        lang: "Cambiar idioma",
        add_content: "Importar contenidos",
        add_folder: "Crear nueva carpeta",
        download: "Descargar archivos",
        btn_select: "Habilitar/deshabilitar selección",
        view_type: "Cambiar modo de vista",
        delete: "Eliminar contenidos seleccionados",
        search: "Buscar contenidos",
        hide: "Ocultar buscador",
        add_playlist: "Agregar nueva playlist",
        preview: "Previsualizar",
        publish: "Publicar",
        properties: "Propiedades",
        add_item: "Agregar contenido a la playlist",
        remove: "Quitar contenido de la playlist",
        order: "Ordenar por"
    },
    loader:{
        load: "Cargando",
        delete: "Eliminando",
        copy: "Copiando",
        save: "Guardando",
        add: "Agregando",
        off: "Apagando",
        on: "Encendiendo",
        restart: "Reiniciando"
    },
    user_section:{
        // menus
        m_info: 'Información de la cuenta',
        t_info: 'Información de la cuenta',
        d_info: 'Edita tu información personal',
        m_password: 'Cambio de clave',
        m_suscription: 'Suscripción',
        m_logout: "Cerrar sesión",
        //secciones
        s_account: 'Mi cuenta'
    },
    sesion_expired: "Tu sesión ha caducado, vuelve a ingresar.",
    err_404:{
        sorry: 'Lo sentimos',
        text: 'Esta página no existe :(',
        btn: 'Regresar al inicio'
    },
    err_internet:{
        title: 'Sin conexión',
        sorry: 'Lo sentimos',
        text: 'No tienes conección a internet',
        btn: 'Recargar'
    }
}