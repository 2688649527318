import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheMobileMenu = _resolveComponent("TheMobileMenu")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, {
    class: _normalizeClass(_ctx.ismobile ? 'm-mobile' : 'm-web')
  }, {
    default: _withCtx(() => [
      (_ctx.ismobile)
        ? (_openBlock(), _createBlock(_component_ion_menu, {
            key: 0,
            "content-id": "main-content"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TheMobileMenu)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_router_outlet)
    ]),
    _: 1
  }, 8, ["class"]))
}