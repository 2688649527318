
import {IonContent,  IonToolbar,  IonLabel, IonFooter,  IonButtons,  IonItem, IonCheckbox,
     modalController} from '@ionic/vue';
import { defineComponent, reactive} from 'vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppModal from '@/uicomponents/AppModal.vue';
import useDeviceStore from '@/core/stores/deviceStore';
import { Device } from '@/core/interfaces/Device';
import { DeviceContent } from '@/core/interfaces/Content';


export default defineComponent({
    name: 'DeviceModal',

    components:{
        IonContent,  IonToolbar, 
        IonLabel, 
        IonFooter,  IonItem,
        AppButton, IonButtons,
        AppModal,
        IonCheckbox,
    },

    props: {
        device:{
            type: Object as () => Device, 
            required: true,
        },
        items:{
            type: Array<DeviceContent>,
            required: true
        }
    },

    setup(props){            
        const deviceStore = useDeviceStore();
        const devices=  deviceStore.$state.all.filter(x=> x.id_device!= props.device.id_device)

        const form = reactive(devices.map(x=>({id:x.id_device, name:x.name, checked:false})))

        const handleCloseModal = () => {
            return modalController.dismiss([], 'cancel');
        };

        const handleSave = () =>{
            //console.log(this.form)
            const checked= form.filter(x=> x.checked).map(x=>x.id)
            if(checked.length > 0)
                return modalController.dismiss(checked, 'save');
            else 
                handleCloseModal()
        }
        return{
            form, 
            handleCloseModal,
            handleSave,
        }
    }
});

