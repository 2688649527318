import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    mode: "ios",
    disabled: _ctx.disabled,
    fill: _ctx.fill?'solid':'outline',
    slot: _ctx.slot,
    color: _ctx.color,
    href: _ctx.href,
    target: _ctx.target,
    expand: _ctx.expand ? 'block': undefined,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["disabled", "fill", "slot", "color", "href", "target", "expand"]))
}