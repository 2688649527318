/*import i18n from '@/i18n';
import { IonRouterOutlet } from '@ionic/vue';*/
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue'
import DashboardPage from '@/views/DashboardPage.vue'
import WorkspacePage from '@/views/WorkspacePage.vue'
import EditorPage from '@/views/EditorPage.vue'
import UserPage from '@/views/UserPage.vue'
import AuthPage from '@/views/AuthPage.vue'
import AuthLoginPage from '@/views/AuthLoginPage.vue'
import useAuthStore from '@/core/stores/authStore';
import ErrorPage from '@/views/ErrorPage.vue'
//import ComponentsPage from '@/views/ComponentsPage.vue'

const routes: Array<RouteRecordRaw> = [
 /* {
    path: '/',
    redirect: `/${i18n.global.locale}`
  },
  {  
    path: '/:lang',
    component: IonRouterOutlet,
    children: [*/
      {
        path: '/',
        redirect: 'dashboard'
      },
      {
        path: '/home',
        name: 'Home',
        component: HomePage
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardPage,
        meta: {requiredAuth: true}
      },
      {
        path: '/workspace',
        name: 'Manager',
        component: WorkspacePage,
        meta: {requiredAuth: true}
      },
      {
        path: '/editor',
        name: 'Editor',
        component: EditorPage,
        meta: {requiredAuth: true}
      },
      {
        path: '/profile',
        name: 'Profile',
        component: UserPage,
        meta: {requiredAuth: true}
      },
      {
        path: '/profile/:menuitem',
        name: 'User',
        component: UserPage,
        meta: {requiredAuth: true}
      },
      // auth
      {
        path: '/auth',
        name: 'Auth',
        component: AuthPage
      },
      {
        path: '/login/ionic',
        name: 'Login',
        component: AuthLoginPage,
      },
      // page error
      {
        path: '/err/:errtype',
        name: 'Error',
        component: ErrorPage,
      },

      // UI Components
      {
        path: '/uicomponents',
        name: 'UI Components',
        component: () => import('@/views/ComponentsPage.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/err/404'
      },
    /*]
  }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/*------------------------------------- guard ------------------------------- */

//beforeEach
router.beforeEach((to, from, next) =>{
  if (to.meta && to.meta.requiredAuth) {
    const authStore = useAuthStore();
    if(authStore.isLogged){
      return next();
    } else{
      return next({ path: "/auth" });
    }
  } else {
    return next();
  }
})
export default router

