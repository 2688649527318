import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheManagerHeader = _resolveComponent("TheManagerHeader")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    id: "main-content",
    class: "ion-page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_TheManagerHeader)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-text-center" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.current_err.class)
          }, [
            _createVNode(_component_ion_text, {
              class: "ion-color md ion-color-accent",
              color: "accent"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.current_err.title)), 1)
              ]),
              _: 1
            })
          ], 2),
          _createVNode(_component_ion_text, { class: "error-info" }, {
            default: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(_ctx.$t(_ctx.current_err.sub)), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.current_err.text)), 1),
              _createVNode(_component_AppButton, {
                fill: true,
                color: "primary",
                href: "/",
                class: _normalizeClass(['btn-activate'])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.current_err.btn_text)) + " ", 1),
                  _createVNode(_component_AppIcon, { icon: "arrow_forward" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}