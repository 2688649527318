
import { computed, defineComponent, onMounted, ref } from 'vue';
import { IonToolbar, IonLabel, IonButtons, IonRow, IonCheckbox,
    loadingController, modalController, ItemReorderEventDetail, CheckboxCustomEvent, isPlatform, } from '@ionic/vue';
import { baseImg, Content, DeviceContent, } from '@/core/interfaces/Content';
import ModalDeviceAddContent from '@/views/ModalDeviceAddContent.vue';
import useDeviceStore from '@/core/stores/deviceStore';
import DroppableArea from '@/view_components/DroppableArea.vue'
import useContentStore from '@/core/stores/contentStore';
import AppButton from '@/uicomponents/AppButton.vue';
import AppButtonIcon from '@/uicomponents/AppButtonIcon.vue';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import { getStrTime, shortenTitle } from '@/core/utils';
//import DeviceContentsGrid from './DeviceContentsGrid.vue';
import DeviceContentsList from './DeviceContentsList.vue';
import ModalContentView from '@/views/ModalContentView.vue';
import ContentService from '@/core/services/ContentService';
import { useI18n } from 'vue-i18n'

export default defineComponent({
    name: 'TheDeviceSectionContents',

    components: {
    IonToolbar,
    IonLabel,
    IonRow,
    IonButtons,
    DroppableArea,
    AppButton,
    AppButtonIcon,
   // DeviceContentsGrid,
    DeviceContentsList,
    IonCheckbox
    },

    setup(){
        const view_type = ref('list');
        const change_viewtype = () => {
            view_type.value = view_type.value == 'grid' ? 'list' : 'grid';
        };
        const deviceStore = useDeviceStore();
        const contentStore = useContentStore();
        const contentsSelected = computed(()=>{ return contentStore.getContentsChecked });
        const deviceSelected = computed(()=>{ return deviceStore.selected });
        const deviceItems = computed(()=>{ return deviceStore.deviceItems });
        const dropRef= ref(null)
        const menu_items:AppPopoverMenuItem[]=[
            {title:'menu_properties', key:'view', icon: 'tune', disabled:false },
            {title:'menu_remove', key:'remove', icon: 'delete', disabled:false }
        ];
        const anyIsChecked= computed(()=>{ return deviceStore.isAnyChecked });
        const items_check= computed(()=>{ return deviceStore.$state.check });
        const check_enable= computed(()=>{ return deviceStore.$state.check_enable });
        
        const ismobile = isPlatform('mobile')

        const checkAllItems = async(e:CheckboxCustomEvent) => {
            //console.log(e.detail)
            await deviceStore.checkAllItems(e.detail.checked);
        };

        const { t } = useI18n({ useScope: 'global' })

        const handleDrop = async(content:Content) => {
            console.log('drop',content)
            //console.log(this.contentsSelected)
            let contents_to_add= []
            //si hay varios checks 
            if(contentsSelected.value.length>0){
                //entonces los agrega
                contents_to_add=[...contentsSelected.value]
            } else{
                //sino hay cheks
                // agrega el contenido
                contents_to_add=[content]
            }
            //console.log('agrega',contents_to_add.length)
            const loading = await loadingController.create({
                message: t("loader.add")
            });
            loading.present();
            await deviceStore.addItemsToDevice(contents_to_add);
            loading.dismiss();
        };

        const handleReorderItems = async(event: CustomEvent<ItemReorderEventDetail>) => {
            console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
            console.log(event.detail.complete(false));
            const from = event.detail.from
            const to = event.detail.to
            const loading = await loadingController.create({
                message: t("loader.save")
            });
            loading.present();
            await deviceStore.reorderItems(from, to)
            loading.dismiss()   
        };

        const openModalAddContent = async() =>{
            const modal = await modalController.create({
                component: ModalDeviceAddContent,
                componentProps: {
                    'device' : deviceSelected.value?.name
                },
                cssClass: 'big-modal',
                backdropDismiss:false
            });
            modal.present();
            const { data, role}  = await modal.onWillDismiss();
            if( role == 'save'){
                const loading = await loadingController.create({
                    message: t("loader.add")
                });
                loading.present();
                    //save device items
                await deviceStore.addItemsToDevice(data);
                loading.dismiss();
            }
        };

        const clickMenu = (menu:string, item: DeviceContent) => {
            //console.log(menu, id_item)
            switch (menu) {
                case 'view':
                    handleViewItem(item)
                    break;
                case 'remove':
                    deleteItems([item.id_playlist_item])
                    break;
            
                default:
                    break;
            }
        };

        const handleViewItem = async(item: DeviceContent) => {
            //console.log('view', item);
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            const response = await ContentService.getContentUses([item.Content.id_content]);
            const uses= response[0].devices.map(x=>x.id_device)
            loading.dismiss();
            const modal = await modalController.create({
                component: ModalContentView,//ModalDeviceItem,
                componentProps: {
                    /*'device' : this.deviceSelected?.name,
                    'item': item*/
                    'content' : item.Content,
                    'uses': uses
                },
                cssClass: 'big-modal properties',
                backdropDismiss:false,
            });
            modal.present();

            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
                const loading = await loadingController.create({
                    message: t("loader.save")
                });
                loading.present();
                if (data.form != null)
                    await contentStore.updateContent(item.Content, data.form);
                if (data.addto.length > 0){
                    // si se va agregar a pantallas
                    const device_ids = data.addto.map((x: {id: number, name:string}) => x.id)
                    const itemadd= {id_content: item.Content.id_content, duration: item.Content.duration}
                    await deviceStore.addContentsToDevices([itemadd],device_ids);
                }
                loading.dismiss();
            }         
        };
        
        const deleteItems = async(id_items: number[]) => {
            const loading = await loadingController.create({
                message: t("loader.delete")
            });
            loading.present();
            await deviceStore.removeItemsToDevice(id_items);
            loading.dismiss()   
        };
 
        const handleDelete = () =>{
            //console.log(this.contentStore.checks)
            const ids= deviceStore.getItemsChecked.map(x=> x.id_playlist_item)
            deleteItems(ids)
        }

        onMounted(() =>{
            contentStore.setDropRef(dropRef.value)
        })
        return {
            view_type, change_viewtype,
            check_enable,
            items_check,
            anyIsChecked,
            baseImg,
            contentsSelected,
            deviceStore,
            deviceSelected,
            deviceItems,
            dropRef,
            menu_items,
            getStrTime, shortenTitle,
            ismobile,       
            checkAllItems, 
            handleDrop,    
            handleReorderItems,
            openModalAddContent,
            clickMenu,
            handleViewItem,
            handleDelete,
        }
    }
})
