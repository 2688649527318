import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "popmenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_AppListMenu = _resolveComponent("AppListMenu")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      id: 'menu-'+_ctx.idMenu,
      fill: "clear",
      color: "medium",
      class: _normalizeClass(['ellipsis custom'])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, { icon: _ctx.ellipsisVertical }, null, 8, ["icon"])
      ]),
      _: 1
    }, 8, ["id"]),
    (!_ctx.ismobile)
      ? (_openBlock(), _createBlock(_component_ion_popover, {
          key: 0,
          trigger: 'menu-'+_ctx.idMenu,
          alignment: 'end',
          "dismiss-on-select": true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_AppListMenu, {
                  items: _ctx.items,
                  onOnClick: _ctx.handleClickMenu
                }, null, 8, ["items", "onOnClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["trigger"]))
      : _createCommentVNode("", true),
    (_ctx.ismobile)
      ? (_openBlock(), _createBlock(_component_ion_modal, {
          key: 1,
          ref: "modalMenuItem",
          trigger: 'menu-'+_ctx.idMenu,
          "initial-breakpoint": _ctx.items.length>5?0.4:_ctx.items.length* 0.08,
          breakpoints: [0.25,  0.5],
          "dismiss-on-select": true,
          class: _normalizeClass(['mobile-modal', 'menu-mob'])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_AppListMenu, {
                  items: _ctx.items,
                  onOnClick: _ctx.handleClickMenu
                }, null, 8, ["items", "onOnClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["trigger", "initial-breakpoint"]))
      : _createCommentVNode("", true)
  ]))
}