
import {
  IonToolbar, IonImg,  IonAvatar, IonItem,
  useIonRouter, IonHeader, IonContent,  IonMenuToggle, IonLabel, IonFooter
} from '@ionic/vue';
import { defineComponent, } from 'vue';
import AppButton from '@/uicomponents/AppButton.vue';
import { useRoute, useRouter } from 'vue-router';
import TheSelectLang from '@/view_components/TheSelectLang.vue';
import useAuthStore from '@/core/stores/authStore';
import { computed } from '@vue/reactivity';
import AppIcon from '@/uicomponents/AppIcon.vue';
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TheManagerMenu',

  components: {
    IonToolbar,
    IonImg,
    AppButton,
    IonAvatar,
    IonItem,
    IonLabel,
    IonHeader, IonContent, IonMenuToggle,
    IonFooter,
    TheSelectLang,
    AppIcon
  },


  setup() {
    const logoe4u = "assets/logoeyecatch.png"
    const ionRouter = useIonRouter();
    const router = useRouter();
    const route = useRoute();
    const currentpath =route.path
    const menumanager =[
      {path: '/dashboard', icon: 'dashboard', title: 'main_menu.dashboard', current: currentpath== '/dashboard'},
      {path: '/workspace', icon: 'tv', title: "main_menu.publish", current: currentpath== '/workspace'},
      //{path: '/editor', icon: 'photo_library', title: "main_menu.editor", current: currentpath== '/editor'},
    ]
    const authStore = useAuthStore();
    const currentuser = computed(() =>{ return authStore.getUser });

    const { locale } = useI18n({ useScope: 'global' })

    const handleChangeLang = ($event: any) => {
      const new_lang = $event.detail.value
      //const new_route = this.$route.path.replace("/"+this.$i18n.locale, "/"+new_lang)
      //this.ionRouter.replace(new_route) 
      //this.ionRouter.navigate(new_route, 'forward')
      locale.value = new_lang;
    };

    const handleLogout = async() =>{
          await authStore.logout();
          ionRouter.navigate('/auth', 'forward');
    }
    return { 
      logoe4u, 
      ionRouter,
      route,
      router,
      menumanager,
      currentuser,
      handleChangeLang,
      handleLogout
    }
  }
});
