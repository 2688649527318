
import { IonContent, IonHeader, IonPage, IonSelect, IonSelectOption, IonToolbar,
	IonCol, IonRow, IonButton, IonItem, IonCard, IonCardContent, IonBadge,
	IonRadio,IonRadioGroup, loadingController, isPlatform, modalController} from '@ionic/vue';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import TheManagerHeader from '@/view_components/TheManagerHeader.vue';
import AppButton from '@/uicomponents/AppButton.vue';
import useDeviceStore from '@/core/stores/deviceStore';
import { computed } from '@vue/reactivity';
import { Device, getStatusText } from '@/core/interfaces/Device';
import {getStrDateTime, bytesToGB, getStrDate} from '@/core/utils';
import TheMobileMenuToggle from '@/view_components/TheMobileMenuToggle.vue';
import TheChartDonut from '@/view_components/TheChartDonut.vue';
//import {Chart, Legend} from 'chart.js'
import useAuthStore from '@/core/stores/authStore';
import AppIcon from '@/uicomponents/AppIcon.vue'
import { useI18n } from 'vue-i18n'
import { DonutChartProps } from '@/core/interfaces/Charts';
import ModalTutorial from './ModalTutorial.vue';
import TheBubbleButton from '@/view_components/TheBubbleButton.vue'
import AuthService from '@/core/services/AuthService';

export default defineComponent({
	name: 'DashboardPage',

	components: {
		IonContent,
		IonPage,
		IonHeader,
		//IonSkeletonText,
		IonCol,
		IonRow,
		IonButton,
		IonItem,
		IonRadio,IonRadioGroup,
		TheManagerHeader,
		AppButton,
		IonCard, IonCardContent,
		//IonSelect, IonSelectOption,
		IonToolbar,
		TheMobileMenuToggle,
		AppIcon,
		IonBadge,
		TheChartDonut,
		//TheBubbleButton
	},

	setup(){
		const ismobile = isPlatform('mobile') 
		const COMMERCE_URL = process.env.VUE_APP_COMMERCE_DEVICE 
		const authStore = useAuthStore();
		const userlang = computed(() => {return authStore.language})
		const currentuser = computed(() =>{ return authStore.getUser });
		const deviceStore = useDeviceStore();
		const devices = computed(() =>{ return deviceStore.all });
		const form = reactive<{device_id:number, selected: Device|any,has_data:boolean}>({
			device_id: 0,
			selected: {
				name :'',device_model:'', resolution:'', last_connection:0,
				published_date:0, publish_status:0, free_memory:0, is_player:false
			},
			has_data: false
		})

		//load devices
		const { t } = useI18n({ useScope: 'global' })
		const loadDevices = async() =>{
			const loading = await loadingController.create({
				message: t("loader.load")
			});
			loading.present();
			await deviceStore.fetchDevices();
			loading.dismiss()  
			if(devices.value.length>0){
				form.device_id=devices.value[0]?.id_device
				form.selected= devices.value[0]
				form.has_data = devices.value[0].last_connection != 0
			}
		}

		const handleSelectDevice = async() => {
			form.selected = devices.value.find(x=>x.id_device == form.device_id)
			form.has_data = form.selected.last_connection != 0
			console.log(form.selected)
			if (form.selected.free_memory != 0){
				//console.log(form.memory)
				chart_config.data= [
					form.selected.free_memory,
					bytesToGB(form.selected.memory_image),
					bytesToGB(form.selected.memory_video),
				
				]
				const used_memory_gb =bytesToGB(form.selected.used_memory)
				const total_memory = form.selected.free_memory + used_memory_gb
				chart_config.percent = Math.round(used_memory_gb*10000/total_memory)/100 
				chart_config.isEmpty=false
			} else{
				chart_config.percent = 0
				chart_config.isEmpty=true
			}
		}

		//--------------------- chart---------------------------------
		const chart_config = reactive<DonutChartProps>({
			labels: ['dashboard.m_free', 'dashboard.m_images', 'dashboard.m_videos'],
			data: [0 ,0 ,0],
			centerText: 'dashboard.m_used',
			percent:0,
			isEmpty: true
		})
		//console.log(form.selected)
		//const last_login = ref(0)
		const info_plan= ref({ status: 1, time_end: 1711384380, time_start:1702500811 })
		onMounted(async()=>{
			//last_login.value = await AuthService.getLastConnection();//authStore.setLastConnection()
			//info_plan.value = await AuthService.getPlanInfo()
			//console.log(info_plan.value)
			// verifica si se cargaron las pantallas
			if(devices.value.length==0){
				await loadDevices() 	 /*******************/
			}else{
				form.device_id=devices.value[0]?.id_device
				form.selected= devices.value[0]
				form.has_data = devices.value[0].last_connection != 0
			}
			//carga info diagrama
			if (form.selected.free_memory != 0){
				//console.log(form.memory)
				chart_config.data= [
					form.selected.free_memory,
					bytesToGB(form.selected.memory_image),
					bytesToGB(form.selected.memory_video),
				
				]
				const used_memory_gb =bytesToGB(form.selected.used_memory)
				const total_memory = form.selected.free_memory + used_memory_gb
				chart_config.percent = Math.round(used_memory_gb*10000/total_memory)/100 
				chart_config.isEmpty=false
			} 
			// tutorial
			/*if(!ismobile){
				const modal = await modalController.create({
					component: ModalTutorial,
					backdropDismiss: false,
				});
				modal.present();
			}*/
		})
		console.log(process.env.VUE_APP_VERSION)
		return { 
			currentuser,
			devices,
			form,
			//last_login,
			info_plan,
			getStrDateTime,
			getStrDate,
			getStatusText,
			ismobile,
			COMMERCE_URL,
			handleSelectDevice,
			chart_config,
			userlang
		}
	},
});
