import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_breadcrumb = _resolveComponent("ion-breadcrumb")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_breadcrumbs = _resolveComponent("ion-breadcrumbs")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_breadcrumbs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_breadcrumb, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick('')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Media "),
              _createVNode(_component_ion_icon, {
                slot: "separator",
                icon: _ctx.chevronForward
              }, null, 8, ["icon"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_breadcrumb, { color: "accent" }, {
            default: _withCtx(() => [
              _createVNode(_component_AppIcon, {
                slot: "start",
                icon: "folder"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.current), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}