import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ion-padding-start" }
const _hoisted_2 = { class: "contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheManagerHeader = _resolveComponent("TheManagerHeader")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_TheMobileMenuToggle = _resolveComponent("TheMobileMenuToggle")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_AppSearchbar = _resolveComponent("AppSearchbar")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_AppPopoverMenu = _resolveComponent("AppPopoverMenu")!
  const _component_AppCardMedia = _resolveComponent("AppCardMedia")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    id: "main-content",
    class: "ion-page"
  }, {
    default: _withCtx(() => [
      (!_ctx.ismobile)
        ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_TheManagerHeader)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.ismobile)
        ? (_openBlock(), _createBlock(_component_ion_header, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_TheMobileMenuToggle)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        "scroll-y": false,
        class: "editor-page"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, {
            class: _normalizeClass(['first-toolbar', 'editor-toolbar'])
          }, {
            default: _withCtx(() => [
              _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("content_section.title")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_toolbar, {
            class: _normalizeClass(['action-toolbar', 'editor-toolbar'])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  (!_ctx.ismobile)
                    ? (_openBlock(), _createBlock(_component_ion_select, {
                        key: 0,
                        interface: "popover",
                        placeholder: _ctx.$t(_ctx.types_filter[0].value),
                        slot: "start",
                        onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.set_type_search($event.detail.value)))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types_filter, (option) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: option.key,
                              value: option.key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(option.value)), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["placeholder"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_AppSearchbar, { onOnInput: _ctx.set_text_search }, null, 8, ["onOnInput"])
                ]),
                _: 1
              }),
              (!_ctx.ismobile)
                ? (_openBlock(), _createBlock(_component_ion_buttons, {
                    key: 0,
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("order_by")) + ": ", 1),
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        placeholder: _ctx.$t(_ctx.order_types[1].value),
                        slot: "start",
                        onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.set_order_select($event.detail.value)))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order_types, (option) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: option.key,
                              value: option.key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(option.value)), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["placeholder"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEditorList, (content) => {
                  return (_openBlock(), _createBlock(_component_ion_col, {
                    key: 'content-'+content.id_content,
                    "size-xs": "6",
                    "size-md": "2"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_AppCardMedia, {
                        label: _ctx.shortenTitle(content.title, true)+'.'+ content.extension,
                        "img-src": content.local_path_small,
                        "auth-src": true,
                        "icon-editor": true,
                        duration: content.media_type=='video' ? _ctx.getStrTime(content.duration) : undefined,
                        "is-video": content.media_type=='video',
                        validity: content.validity_start!=0,
                        data: content,
                        "enable-drag": false,
                        class: _normalizeClass([content.check ? 'card_media_actions card_selected': 'card_media_actions']),
                        onOnDblClick: ($event: any) => (_ctx.handleViewContent(content)),
                        onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickItem()))
                      }, {
                        end: _withCtx(() => [
                          _createVNode(_component_AppPopoverMenu, {
                            "id-menu": 'content-'+content.id_content,
                            items: _ctx.menu_items,
                            data: content,
                            slot: "end",
                            class: _normalizeClass('show'),
                            onOnClick: _ctx.handleClickMenu
                          }, null, 8, ["id-menu", "items", "data", "onOnClick"])
                        ]),
                        _: 2
                      }, 1032, ["label", "img-src", "duration", "is-video", "validity", "data", "class", "onOnDblClick"])), [
                        [_directive_tooltip, content.title+'.'+ content.extension]
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}