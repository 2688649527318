import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "playlist modal-playlist" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_AppModal = _resolveComponent("AppModal")!

  return (_openBlock(), _createBlock(_component_AppModal, {
    title: 'Playlist',
    icon: 'playlist_play',
    onOnClose: _ctx.handleCloseModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('m_playlist.new')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, {
                          position: "stacked",
                          class: _normalizeClass(['label-input']),
                          for: "form-title"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('labels.name')) + ":", 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_input, {
                          id: "form-title",
                          modelValue: _ctx.form.name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
                          required: true,
                          onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.form_state.edited = true))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { for: "form-shuffle" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Shuffle:")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_checkbox, {
                          id: "form-shuffle",
                          slot: "end",
                          modelValue: _ctx.form.shuffle,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.shuffle) = $event)),
                          onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.form_state.edited = true))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { for: "form-mute" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Mute:")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_checkbox, {
                          id: "form-mute",
                          slot: "end",
                          modelValue: _ctx.form.mute,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.mute) = $event)),
                          onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.form_state.edited = true))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { for: "form-color" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Color:")
                          ]),
                          _: 1
                        }),
                        _withDirectives(_createElementVNode("input", {
                          id: "form-color",
                          type: "color",
                          slot: "end",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.color) = $event)),
                          onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.form_state.edited = true))
                        }, null, 544), [
                          [_vModelText, _ctx.form.color]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "modal-footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, {
                    fill: true,
                    disabled: _ctx.isFormInvalid,
                    color: "accent",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleSaveData()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button_save_changes')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onOnClose"]))
}