
import {
  IonContent, IonHeader, IonPage, //IonSkeletonText,
  IonGrid, IonCol, IonRow, isPlatform, IonLabel, 
  IonFooter, IonSegment, IonSegmentButton,
} from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import TheManagerHeader from '@/view_components/TheManagerHeader.vue';
import TheContentSection from '@/view_components/TheContentSection.vue';
import ThePlaylistSection from '@/view_components/ThePlaylistSection.vue';
import TheDeviceSection from '@/view_components/TheDeviceSection.vue';
import ContentUploadSheet from '@/view_components/ContentUploadSheet.vue'
import AppIcon from '@/uicomponents/AppIcon.vue';
import TheMobileMenuToggle from '@/view_components/TheMobileMenuToggle.vue';
import useAuthStore from '@/core/stores/authStore';
import { AppSegment } from '@/core/interfaces/AppComponents';
import TheBubbleButton from '@/view_components/TheBubbleButton.vue'
import useContentStore from '@/core/stores/contentStore';

export default defineComponent({
	name: 'WorkspacePage',

	components: {
		IonContent,
		IonPage,
		IonHeader,
		//IonSkeletonText,
		IonGrid,
		IonCol,
		IonRow,
		TheManagerHeader,
		TheContentSection,
		ThePlaylistSection,
		TheDeviceSection,
		ContentUploadSheet,
		IonLabel,
		IonFooter, IonSegment, IonSegmentButton,
		AppIcon,
		TheMobileMenuToggle,
		//TheBubbleButton
	},

	setup() {
		const ismobile = isPlatform('mobile')
		const authStore = useAuthStore();
		const plan_type = computed(() => { return authStore.plan_type})
		const segments: AppSegment[] = [
            { icon: 'perm_media', value: 'content', title: 'content_section.title' },
            { icon: 'tv', value: 'displays', title: 'device_section.title' },
        ]
		const sectionselect = ref<string>('content');
		const set_sectionselect = (event: CustomEvent  )=> {
			if( event.detail.value){
				sectionselect.value =  event.detail.value;
			}
		}
		const contentStore = useContentStore();
		const show_sheet = computed(()=>{ return contentStore.show_sheet });
		return {
			ismobile,
			plan_type,
			segments,
			sectionselect,set_sectionselect,
			show_sheet
		}
	},
});
