
import { defineComponent, onMounted } from 'vue';
import { IonPage, IonContent,  } from '@ionic/vue';
import {SDK_URL} from '@/core/services/AuthService'
import { Browser } from '@capacitor/browser';

declare var SSOe4u:{
    config:unknown,
    content: HTMLElement,
    //init: ()=>{},
    //show: ()=>{}
}


export default defineComponent({
    name: 'authPage',

    components: {
        //IonButton,
        IonPage, IonContent, 
    },

    setup(){
        const origin=window.location.origin
        // eslint-disable-next-line
        // @ts-ignore
        const ssomodal= new SSOe4u({
            sesshandler: origin+"/login/ionic",
            ssourl: SDK_URL,
        })
        //window.open(ssomodal, '_self') //_system

        onMounted(() => {
            Browser.open({...ssomodal, windowName: '_self'})
            ssomodal.show()            
        });
        return {
        }
    }
});
