import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_AppSlides = _resolveComponent("AppSlides")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, {
    class: _normalizeClass([_ctx.ismobile?'preview-mobile':'preview-desktop'])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        translucent: "",
        slot: "fixed",
        class: _normalizeClass(['no-border'])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, {
            slot: "end",
            class: _normalizeClass(['close-btn'])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                slot: "icon-only",
                fill: "clear",
                color: "light",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppIcon, { icon: "close" })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_AppSlides, {
        items: _ctx.slides,
        auth: true,
        thumbnails: true,
        title: _ctx.title
      }, null, 8, ["items", "title"])
    ]),
    _: 1
  }, 8, ["class"]))
}