import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  slot: "start",
  class: "ion-padding-start"
}
const _hoisted_2 = { class: "content workspace" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButtonIcon = _resolveComponent("AppButtonIcon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_DeviceList = _resolveComponent("DeviceList")!
  const _component_TheDeviceSectionContents = _resolveComponent("TheDeviceSectionContents")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, { class: "first-toolbar" }, {
          default: _withCtx(() => [
            _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("device_section.title")), 1),
            (_ctx.deviceSelected)
              ? (_openBlock(), _createBlock(_component_ion_buttons, {
                  key: 0,
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_AppButtonIcon, {
                      color: "medium",
                      class: _normalizeClass('custom'),
                      icon: "visibility",
                      disabled: !_ctx.deviceHasItems,
                      onClick: _ctx.handleOpenPreview
                    }, null, 8, ["disabled", "onClick"]), [
                      [_directive_tooltip, _ctx.$t('tooltip.preview')]
                    ]),
                    _withDirectives(_createVNode(_component_AppButtonIcon, {
                      icon: "publish",
                      disabled: !_ctx.deviceHasItems,
                      color: "medium",
                      class: _normalizeClass('custom'),
                      onClick: _ctx.handlePublishDevice
                    }, null, 8, ["disabled", "onClick"]), [
                      [_directive_tooltip, _ctx.$t('tooltip.publish')]
                    ]),
                    _withDirectives(_createVNode(_component_AppButtonIcon, {
                      color: "medium",
                      class: _normalizeClass('custom'),
                      icon: "tune",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleDeviceView(null)))
                    }, null, 512), [
                      [_directive_tooltip, _ctx.$t('tooltip.properties')]
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.ismobile)
      ? (_openBlock(), _createBlock(_component_ion_refresher, {
          key: 0,
          slot: "fixed",
          onIonRefresh: _ctx.handleRefresh
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_refresher_content, {
              refreshingText: _ctx.$t('loader.load')
            }, null, 8, ["refreshingText"])
          ]),
          _: 1
        }, 8, ["onIonRefresh"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.devices_loaded)
        ? (_openBlock(), _createBlock(_component_DeviceList, {
            key: 0,
            onOnItemClick: _ctx.handleSelectDevice,
            onOnView: _ctx.handleDeviceView,
            onOnPublish: _ctx.handlePublish
          }, null, 8, ["onOnItemClick", "onOnView", "onOnPublish"]))
        : _createCommentVNode("", true),
      (_ctx.deviceSelected)
        ? (_openBlock(), _createBlock(_component_TheDeviceSectionContents, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}