import CacheService from '@/core/services/CacheService';
import HttpService from "../services/HttpService";

export const Thumb=  {
    beforeMount: (el:HTMLImageElement, binding: any)=> {
        setThumbSrc(el, binding);   
    },
    updated: (el:HTMLImageElement, binding: any)=> {
        //console.log('updated')
        //setImgSrc(el, binding, 1);
    }  
}

const setThumbSrc = async(el:HTMLImageElement, binding: any) => {     
    if ((binding.oldValue === undefined || binding.value !== binding.oldValue) && binding.value !== null) {
        const content = binding.value;
        const fileUrl = content.local_path_small
        if(fileUrl){
            const cachedThumb = CacheService.getCachedTumb(fileUrl);
            // si esta en cache lo carga
            if(cachedThumb != null){
                el.src= cachedThumb
            }else{
                if(content.path_temp){      
                    el.src= content.path_temp  
                    setTimeout(()=>{
                        HttpService.getFile(fileUrl, 1).then(file64 =>{
                            if(typeof file64 == 'string'){
                                CacheService.saveTumbCache(fileUrl, file64);
                                //URL.revokeObjectURL(path_temp)
                                el.src= file64
                            }
                        })
                    }, 5000);
                }else{
                    el.src= 'assets/loading.gif'
                    // sino esta lo descarga y guarda en cache
                    HttpService.getFile(fileUrl, 1).then(file64 =>{
                        if(typeof file64 == 'string'){
                            CacheService.saveTumbCache(fileUrl, file64);
                            el.src= file64
                        }
                    })
                }
            }
        }
    }
}