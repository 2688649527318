
import { IonToolbar, IonBreadcrumb,IonBreadcrumbs,IonIcon} from '@ionic/vue';
import { defineComponent } from 'vue';
import useAuthStore from '@/core/stores/authStore';
import AppIcon from '@/uicomponents/AppIcon.vue'
import {  chevronForward } from 'ionicons/icons';


export default defineComponent({
  name: 'TheFolderBreadcrumb',

  components: {
    IonToolbar, 
    IonBreadcrumb,IonBreadcrumbs,
    AppIcon,
    IonIcon
  },

  props:{
      current:{
          type: String,
          required: true
      },
  },

  emits: [ 'onItemClick'],

  setup(props, context) {

    const handleClick = (key:string) =>{
        context.emit('onItemClick', key)
    };
    return {
      handleClick,
      chevronForward
    }
  }
});
