export const STATUS_NOTHING = 0
export const STATUS_PENDING = 1
export const STATUS_WORKING = 2
export const STATUS_ERROR = 3
export const STATUS_DONE = 4

export interface Device{
    id_device: number,
    remote_id: number,
    id_user_manager: number,
    name: string,
    description: string,
    serial_number: string,
    id_schedule: number,
    last_connection: number,
    published_date: number,
    publish_status: number,
    percentage_text:   string,
    percentage_number: number,
    know_ip: string,
    mac_address: string,
    resolution: string,
    screen_size: string,
    device_model: string,
    is_player: boolean,
    orientation: string,
    //total_memory: number,
    used_memory: number,
    free_memory: number,
    memory_image:number,
    memory_video:number,
    create_date: number,
    last_update: number,

    connected: boolean
}

export interface MemoryInfo{
    media_type:string,
    weight:number
}

export interface DeviceUpdate{
    name: string,
    description: string,
}

export const getStatusText=(status: number)=>{
    switch (status) {
        case STATUS_NOTHING:
            return 'device_state.nothing'
        case STATUS_ERROR:
            return 'device_state.error'
        case STATUS_PENDING:
            return 'device_state.pending'
        case STATUS_DONE:
            return 'device_state.done'
    
        default:
            return 'device_state.error'
    }
}
