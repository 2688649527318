import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_ion_input, {
        value: _ctx.disabled? null : _ctx.date_formatted,
        slot: _ctx.slot,
        "aria-labelledby": 'date-'+_ctx.idPicker,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleOpenPopover($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppIcon, {
            class: "icon-date-picker",
            icon: "expand_more"
          })
        ]),
        _: 1
      }, 8, ["value", "slot", "aria-labelledby"])
    ]),
    (!_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_ion_popover, {
          key: 0,
          ref: "popdate",
          event: _ctx.popover_data.event,
          "keep-contents-mounted": true,
          "is-open": _ctx.popover_data.is_open,
          onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.popover_data.is_open = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_datetime, {
              id: _ctx.idPicker,
              ref: "datepicker",
              value: _ctx.value,
              min: _ctx.min,
              max: _ctx.max,
              presentation: "date",
              showDefaultButtons: true,
              "done-text": _ctx.$t('button_accept'),
              "cancel-text": _ctx.$t('button_cancel'),
              onIonChange: _ctx.handleChangeDate
            }, null, 8, ["id", "value", "min", "max", "done-text", "cancel-text", "onIonChange"])
          ]),
          _: 1
        }, 8, ["event", "is-open"]))
      : _createCommentVNode("", true)
  ], 64))
}