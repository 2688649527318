
import {IonContent,  IonToolbar,  IonText, IonFooter,  IonButtons,  IonItem, 
    IonInput, modalController, InputCustomEvent} from '@ionic/vue';
import { defineComponent, reactive,computed, ref} from 'vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppModal from '@/uicomponents/AppModal.vue';
import { Folder } from '@/core/interfaces/Content';
import useContentStore from '@/core/stores/contentStore';


export default defineComponent({
    name: 'ModalFolderForm',

    components:{
        IonContent,  IonToolbar, 
        IonText, IonInput,
        IonFooter,  IonItem,
        AppButton, IonButtons,
        AppModal,
    },

    props: {
        folder: {
            type: Object as () => Folder,
            required: false
        },
    },

    setup(props){            
        const contentStore = useContentStore();
        const folder_names= computed(()=>{ 
            return contentStore.$state.folders.map(x=>x.Name)
        });
        const input = ref();
        const form = reactive({
            name: ""
        })
        const isInvalid= ref(true)
        let labels= {
            title: 'folder.title_new',
            description: 'folder.info_new'
        }
        if (props.folder != undefined){
            labels={
                title: 'folder.title_rename',
                description: 'folder.info_rename'
            }
        }
       // console.log(folder_names)
        const validateName= (ev:InputCustomEvent)=>{
            let value = ev.target.value
            if(value){
             //   console.log(value)
                //console.log(form.name)
                value = value.toString().replace(/[/\\]+/g,"")
                value = value.toString().replace(/^\./g, "")
                form.name=value
                input.value.$el.value= value
                //validate  nombre no repetido
                const value_temp= value.trim()
                const repeat = folder_names.value.some(x=>x== value_temp)
               // console.log(repeat)
                isInvalid.value= repeat
                //isInvalid.value= false
            }
            /*this.$refs.input.$el.classList.remove('ion-valid');
            this.$refs.input.$el.classList.remove('ion-invalid');
            if(value === ''){
                this.$refs.input.$el.classList.add('ion-invalid');
            }*/
            //console.log(input)
            //input.value.$el.classList.add('ion-invalid');
        }
        /*const validateEmail=(email:string)=> {
            return email.match(
            /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            );
        }

        const validate=(ev:any)=> {
            const value = ev.target.value;

            input.value.$el.classList.remove('ion-valid');
            input.value.$el.classList.remove('ion-invalid');

            if (value === '') return;

            validateEmail(value)
            ? input.value.$el.classList.add('ion-valid')
            : input.value.$el.classList.add('ion-invalid');
        }

        const markTouched=()=> {
            input.value.$el.classList.add('ion-touched');
        }*/
        const handleCloseModal = () => {
            return modalController.dismiss([], 'cancel');
        };

        const handleSave = () =>{
            if(form.name.length > 0)
                return modalController.dismiss(form.name.trim(), 'save');
            else 
                handleCloseModal()
        }
        return{
            form, 
            input,
            labels,
            isInvalid,
            validateName,
            handleCloseModal,
            handleSave,
           // validate,markTouched
        }
    }
});

