
import { defineComponent } from 'vue';
import { IonPage, IonContent, useIonRouter } from '@ionic/vue';
import useAuthStore from '@/core/stores/authStore';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'AuthLoginPage',

    components: {
        IonPage, IonContent,
    },

    setup(){
        const route = useRoute();
        //const { token } = route.params;
        const { t } =route.query;
        const authStore = useAuthStore();
        if(t){
            authStore.setAuth(typeof t == 'string' ? t : t[0] ? t[0] : '')
            const ionRouter = useIonRouter();
            ionRouter.navigate('/dashboard', 'root', 'replace');
        }
        const handleLogout = async() =>{
            console.log('logout')
            await authStore.logout()
        }
        return {
            handleLogout
        }
    }
});
