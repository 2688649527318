import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppButtonIcon = _resolveComponent("AppButtonIcon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_DeviceContentsList = _resolveComponent("DeviceContentsList")!
  const _component_DroppableArea = _resolveComponent("DroppableArea")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_toolbar, { class: "action-toolbar" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_buttons, { slot: "start" }, {
          default: _withCtx(() => [
            (_ctx.check_enable)
              ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                  key: 0,
                  checked: _ctx.items_check,
                  disabled: !_ctx.check_enable,
                  onIonChange: _ctx.checkAllItems
                }, null, 8, ["checked", "disabled", "onIonChange"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('device_section.label_items')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_buttons, { slot: "end" }, {
          default: _withCtx(() => [
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                  key: 0,
                  color: "medium",
                  class: _normalizeClass('custom'),
                  fill: false,
                  onClick: _ctx.openModalAddContent
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" + Media ")
                  ]),
                  _: 1
                }, 8, ["onClick"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.add_item')]
                ])
              : _createCommentVNode("", true),
            (_ctx.ismobile)
              ? (_openBlock(), _createBlock(_component_AppButtonIcon, {
                  key: 1,
                  color: "medium",
                  class: _normalizeClass('custom'),
                  icon: "add",
                  onClick: _ctx.openModalAddContent
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                  key: 2,
                  disabled: _ctx.deviceItems.length==0,
                  color: "medium",
                  class: _normalizeClass('custom'),
                  fill: false,
                  onClick: _ctx.deviceStore.changeStateSelect
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('button_select')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.btn_select')]
                ])
              : _createCommentVNode("", true),
            (!_ctx.ismobile)
              ? _withDirectives((_openBlock(), _createBlock(_component_AppButtonIcon, {
                  key: 3,
                  icon: "delete",
                  disabled: !_ctx.anyIsChecked,
                  color: "medium",
                  class: _normalizeClass('custom'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleDelete()))
                }, null, 8, ["disabled"])), [
                  [_directive_tooltip, _ctx.$t('tooltip.remove')]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_row, {
      class: _normalizeClass(['screens', 'content'])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DroppableArea, {
          ref: "dropRef",
          onOnDropp: _ctx.handleDrop,
          isEmpty: _ctx.deviceItems.length==0
        }, {
          default: _withCtx(() => [
            (_ctx.view_type == 'list')
              ? (_openBlock(), _createBlock(_component_DeviceContentsList, {
                  key: 0,
                  "device-items": _ctx.deviceItems,
                  "menu-items": _ctx.menu_items,
                  onOnClickMenu: _ctx.clickMenu,
                  onOnReorder: _ctx.handleReorderItems
                }, null, 8, ["device-items", "menu-items", "onOnClickMenu", "onOnReorder"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onOnDropp", "isEmpty"])
      ]),
      _: 1
    })
  ], 64))
}