import axios, {  AxiosResponse } from "axios";
import CacheService from '@/core/services/CacheService';
import HttpService from "../services/HttpService";

export const AuthSrc=  {
    beforeMount: (el:HTMLImageElement, binding: any)=> {
        setImgSrc(el, binding);        
    },
    updated: (el:HTMLImageElement, binding: any)=> {
        //console.log('updated')
        //setImgSrc(el, binding, 1);
    }  
}

const setImgSrc = async(el:HTMLImageElement, binding: any) => {
    //console.log('attempt', attempt)
    if ((binding.oldValue === undefined || binding.value !== binding.oldValue) && binding.value !== null) {
        const fileUrl = binding.value;
        //console.log(fileUrl)
        HttpService.getFile(fileUrl, 1).then(file64 =>{
            if(typeof file64 == 'string'){
                el.src= file64
            }
        })
    }
}