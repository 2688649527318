
import { computed, defineComponent, ref } from 'vue';
import { IonToolbar, IonButtons, IonCheckbox, IonLabel, IonButton,  IonChip,
     IonSelect, IonSelectOption, IonRefresher, IonRefresherContent,
    loadingController, modalController, alertController, CheckboxCustomEvent, isPlatform, RefresherCustomEvent} from '@ionic/vue';
import { Content,  extension_accepted, isExtensionValid, Folder, extension_valid } from '@/core/interfaces/Content';
import ContentService from '@/core/services/ContentService';
import ContentGrid from '@/view_components/ContentGrid.vue';
import ContentList from '@/view_components/ContentList.vue';
import TheContentMenuOrder from '@/view_components/TheContentMenuOrder.vue';
import ModalContentViewVue from '@/views/ModalContentView.vue';
import useContentStore from '@/core/stores/contentStore';
import DropFileUploader from './DropFileUploader.vue';
import AppButton from '@/uicomponents/AppButton.vue';
import AppButtonIcon from '@/uicomponents/AppButtonIcon.vue';
import AppIcon from '@/uicomponents/AppIcon.vue';
import AppSearchbar from '@/uicomponents/AppSearchbar.vue';
import { AppPopoverMenuItem } from '@/core/interfaces/AppComponents';
import useDeviceStore from '@/core/stores/deviceStore';
import ModalContentFromEditor from '@/views/ModalContentFromEditor.vue';
//import TheMobileMenuToggle from '@/view_components/TheMobileMenuToggle.vue';
import { useI18n } from 'vue-i18n'
import ModalFolderForm from '@/views/ModalFolderForm.vue';
import TheFolderBreadcrumb from '@/view_components/TheFolderBreadcrumb.vue';
import CacheService from '@/core/services/CacheService';

export default defineComponent({
    name: 'TheContentSection',

    components: {
        IonToolbar,
        AppButton,
        IonButton,
        IonButtons,
        IonCheckbox,
        IonLabel,
        //IonContent, 
        ContentGrid,
        ContentList,
        DropFileUploader,    
        AppButtonIcon,
        AppSearchbar,
        IonSelect, IonSelectOption,
        IonChip,
        AppIcon,
        //IonModal,
        //IonPopover,
        //TheContentMenuAdd,
        TheContentMenuOrder,
        IonRefresher, IonRefresherContent,
        TheFolderBreadcrumb
    },

    setup(){
        //------------------
        const fileUpload = ref()
        const handleUploadClick = () =>{
            // activa el click del input
            (fileUpload.value as HTMLInputElement).click();
            fileUpload.value.value="";
        };

        const handleFileChange= () => {
            const fileInputElement = fileUpload.value as HTMLInputElement
            //console.log(fileInputElement.files)
            const files = fileInputElement.files
            if(files && files.length>0){  
                handleUpload(files)
            }
        };
        ///----------------
        const menu_items:AppPopoverMenuItem[]=[
            {title:'menu_properties', key:'view', icon: 'tune', disabled:false  },
            {title:'menu_download', key:'download', icon: 'file_download', disabled:false  },
            {title:'menu_delete', key:'delete', icon: 'delete', disabled:false  },
        ]
        const menu_items_folder:AppPopoverMenuItem[]=[
            {title:'menu_open', key:'open', icon: 'open_in_browser', disabled:false  },
            {title:'menu_rename', key:'rename', icon: 'drive_file_rename_outline', disabled:false  },
            {title:'menu_delete', key:'delete', icon: 'delete', disabled:false  },
           // {title:'menu_download', key:'download', icon: 'file_download', disabled:false  }
        ]
        const deviceStore = useDeviceStore();
        const contentStore = useContentStore();
        const view_type = ref('grid');
        const change_viewtype = () => {
            view_type.value = view_type.value == 'grid' ? 'table' : 'grid';
        };
        const text_search = ref('');
        const set_text_search = (value: string | null | undefined) => {
            text_search.value = !value ? '': value.toLowerCase();
        }
        const type_search = ref('all');
        const set_type_search = (value: string | null | undefined) => {
            type_search.value = !value ? 'all': value;
        }
        const order_select = ref('new');
        const set_order_select = (value: string | null | undefined) => {
            order_select.value = !value ? 'new': value;
        }
        const handleChip = (option: string) =>{
            if(type_search.value == 'all'){
                if(option == 'video') set_type_search('image')
                else  set_type_search('video')
            }else{
                set_type_search('all')
            }
        };
        const show_searchbar = ref(false);
        const set_show_searchbar = (value: boolean) => {
            show_searchbar.value = value;
            text_search.value = '';
            type_search.value = 'all'
        }
        //const max_toshow= computed(()=>{ return contentStore.getContentCount });
        //const cant_toshow = ref(9);
        //const set_cant_toshow = () => {cant_toshow.value = Math.min(cant_toshow.value + 9, max_toshow.value) }

        const filteredContentList= computed(()=>{ 
            return contentStore.filteredContents(text_search.value,type_search.value, order_select.value)
        });
        const filteredFolderList= computed(()=>{ 
            return contentStore.filteredFolders(text_search.value,type_search.value, order_select.value)
        });
        const currentFolder = computed(()=>{ return contentStore.$state.current_folder})
        const totalContents = computed(()=>{ return contentStore.getContentCount})
        const anyIsChecked= computed(()=>{ return contentStore.isAnyChecked });
        const content_check= computed(()=>{ return contentStore.$state.check });
        const check_enable= computed(()=>{ return contentStore.$state.check_enable });
        const maxfilesize= computed(()=>{ return contentStore.$state.max_file_size });
        const types_filter = computed(() => { return contentStore.getTypesFilter})

        const modalAddMenu= ref()
        const handleMenuClose = () => {
            modalAddMenu.value.$el.dismiss();
        }

        const events = ['dragenter', 'dragover', 'dragleave', 'drop']
        
        const ismobile = isPlatform('mobile')

        const checkAllContents = async(e:CheckboxCustomEvent) => {
            //console.log(e.detail)
            await contentStore.checkAllContents(e.detail.checked);
        };

        const { t } = useI18n({ useScope: 'global' })

        const handleFolderCreate= async()=>{
            const modal = await modalController.create({
                component: ModalFolderForm,
                componentProps: {
                },
                cssClass: 'small-modal',
                backdropDismiss:false,
            });
            modal.present();

            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
                const loading = await loadingController.create({
                    message: t("loader.load")
                });
                loading.present();
                await contentStore.folderCreate(data);
                loading.dismiss();
            }
        }
        const handleOpenFolder= (folder: string)=>{
            set_show_searchbar(false)
            contentStore.selectFolder(folder)
        }
        const handleRenameFolder= async( folder: Folder)=>{
            const modal = await modalController.create({
                component: ModalFolderForm,
                componentProps: {
                    folder: folder,
                },
                cssClass: 'small-modal',
                backdropDismiss:false,
            });
            modal.present();
            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
               const loading = await loadingController.create({
                    message: t("loader.load")
                });
                loading.present();
                await contentStore.folderRename(folder.Name, data);
                loading.dismiss();
            }
        }

        const handleViewContent = async(content: Content) => {
            //console.log('view', content.id_content);
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            const response = await ContentService.getContentUses([content.id_content]);
            const uses= response[0].devices.map(x=>x.id_device)
            loading.dismiss();
            const modal = await modalController.create({
                component: ModalContentViewVue,
                componentProps: {
                    'content' : content,
                    'uses': uses,
                    'backButton': ismobile
                },
                cssClass: 'big-modal properties',
                backdropDismiss:false,
            });
            modal.present();

            const{ data, role}= await modal.onWillDismiss();
            if(role == 'save'){
                const loading = await loadingController.create({
                    message: t("loader.save")
                });
                loading.present();
                if (data.form != null)
                    await contentStore.updateContent(content, data.form);
                if (data.addto.length > 0){
                    // si se va agregar a pantallas
                    //console.log(data.addto)
                    const device_ids = data.addto.map((x: {id: number, name:string}) => x.id)
                    const item= {id_content: content.id_content, duration: content.duration}
                    await deviceStore.addContentsToDevices([item],device_ids);
                }
                loading.dismiss();
            }         
        };
                
        const deleteContents = async(id_contents: number[], folders: Folder[]) => {
            //console.log('delete', id_contents)
            const folders_delete = folders.map(x=>x.Name)
            //console.log(folders_delete)
            const folder_items = contentStore.$state.all.filter(x=> folders_delete.includes(x.folder) ).map(x=> x.id_content)
            //console.log(folder_items)

            const loading = await loadingController.create({
                message: t("loader.delete")
            });
            loading.present();
            const all_ids = [...id_contents, ...folder_items]
            const response = await ContentService.getContentUses(all_ids);
            //let to_delete = [];
            let usados = 0
            for( const content_uses of response){
                if(content_uses.devices.length>0){
                    usados+=1
                }/*else{
                    to_delete.push(content_uses.id_content)
                }*/
            }
            loading.dismiss();
            let do_delete = false;
            if(usados > 0){
                const alert = await alertController.create({
                    header: t('content_section.delete_title'),
                    message:  t('content_section.delete_info'),
                    buttons: [
                        { text: t('button_cancel'), role: 'no' },
                        { text: t('menu_delete'), role: 'si' },
                    ]
                });
                await alert.present();
                const { role } = await alert.onDidDismiss();
                if( role == 'si') { // se dió aceptar a eliminarlos
                    //to_delete= id_contents
                    do_delete = true;
                }
                // else 
            }
            else{
                //to_delete= id_contents
                do_delete= true
                //si tiene carpetas a eliminar con contenidos
                if(folder_items.length > 0){
                    const alert = await alertController.create({
                        header: t('content_section.delete_folder'),
                        message:  t('content_section.delete_alert'),
                        buttons: [
                            { text: t('button_cancel'), role: 'no' },
                            { text: t('menu_delete'), role: 'si' },
                        ]
                    });
                    await alert.present();
                    const { role } = await alert.onDidDismiss();
                    if( role == 'no') { // se dió no a eliminarlos
                        do_delete= false
                    }
                }
            }
            if(do_delete){
                loading.present();
                const smalls = (contentStore.$state.all.filter(x=> all_ids.includes(x.id_content))).map(x=>x.local_path_small)
                CacheService.removeCacheEntries(smalls)
                await contentStore.deleteContents(id_contents, folders_delete);
                loading.dismiss();      
            }
            contentStore.checkAllContents(false)
        };

        const handleDelete = async()=>{
            //console.log(this.contentStore.checks)
            const ids= contentStore.getContentsChecked.map(x=> x.id_content)
            const folders_del = contentStore.getFilesChecked
            await deleteContents(ids, folders_del)
        };

        const handleClickMenu = (menu:string, content: Content) => {
            //console.log(menu, id_content)
            switch (menu) {
                case 'view':
                    handleViewContent( content)
                    break;
                
                case 'download':
                    ContentService.downloadFile(content) 
                    break;

                case 'delete':
                    deleteContents([content.id_content],[])
                    break;
            
                default:
                    break;
            }
        };
        const handleClickMenuFolder = (menu:string, folder:Folder)=>{
            switch (menu) {
                case 'open':
                    handleOpenFolder(folder.Name)
                    break;
                case 'rename':
                    handleRenameFolder( folder)
                    break;

                case 'delete':
                    deleteContents([],[folder])
                    break;
            
                case 'download':
                    break;

                default:
                    break;
            }
        }
        
        const handleUpload = async(files: FileList) => {
            //handleMenuClose()
            contentStore.checkAllContents(false)
                        
            const index_global= contentStore.getUploadingCount
            let files_arr = []
            for (let i = 0; i < files.length; i++) {
                const element = files[i];
                const media_type =  element.type.split("/")[0]
                //console.log(element)

                //si no tiene extension valida
                if(!isExtensionValid(element.name)){
                    const alert = await alertController.create({
                        header: 'Error',
                        message: t("content_section.ext_alert", {name: element.name, extension: extension_valid.join(", ").toUpperCase( )}),
                        buttons: [t('button_ok')]
                    });
                    await alert.present();
                    await alert.onWillDismiss();
                } else if(element.size > maxfilesize.value[media_type as keyof typeof maxfilesize.value]){
                    //si excede el tamaño maximo
                    const alert = await alertController.create({
                        header: 'Error',
                        message: t("content_section.max_size_alert", {name: element.name}),
                        buttons: [t('button_ok')]
                    });
                    await alert.present();
                    await alert.onWillDismiss();
                }else{
                    // si es valido
                    files_arr.push(element)
                }
            }
            if(files_arr.length>0){
                contentStore.addFilesUpload(files_arr);
                for (let index = 0; index < files_arr.length; index++) {
                    contentStore.uploadFile(index_global + index)
                }
            }
        };

        const handleImportFromEditor = async() => {
            handleMenuClose()
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            const response = await ContentService.getEditorExports();
            loading.dismiss();
            const modal = await modalController.create({
                component: ModalContentFromEditor,
                componentProps: {
                    'files' : response
                },
                cssClass: 'big-modal',
                backdropDismiss:false,
            });
            modal.present();

            const{ data, role}= await modal.onWillDismiss();
            if( role == 'save'){
                const loading = await loadingController.create({
                    message: t("loader.save")
                });
                loading.present();
                await contentStore.importFromEditor(data);
                loading.dismiss();
            }
        };

        const handleDownloadFiles = async() => {
            contentStore.getContentsChecked.forEach(x =>
                ContentService.downloadFile(x)    
            )
            contentStore.checkAllContents(false)
        };

        const handleRefresh = async(event: RefresherCustomEvent) => {
            await contentStore.fetchContents();
            //await loadContents()
            event.detail.complete();
        }

        const loadContents = async()  =>{
            const loading = await loadingController.create({
                message: t("loader.load")
            });
            loading.present();
            contentStore.GetMaxFileSize()
            await contentStore.fetchContents();
            loading.dismiss()   
        }
        loadContents()
        return {
            extension_accepted, handleUploadClick, handleFileChange, fileUpload,
            menu_items, menu_items_folder,
            events,
            view_type, change_viewtype, handleChip,
            text_search, set_text_search,
            type_search, set_type_search,
            show_searchbar, set_show_searchbar,
            //cant_toshow, set_cant_toshow,
            order_select, set_order_select,
            //folders, 
            types_filter,
            currentFolder,
            //max_toshow,
            check_enable,
            content_check,
            contentStore,
            filteredContentList,
            filteredFolderList,
            anyIsChecked,
            totalContents,
            //file_types,
            ismobile,
            modalAddMenu,
            handleMenuClose,
            checkAllContents,
            handleClickMenu,
            handleClickMenuFolder,
            handleViewContent,
            handleDelete,
            handleUpload,
            handleFolderCreate,
            handleImportFromEditor,
            handleDownloadFiles,
            handleRefresh,
            handleOpenFolder
        }
    }
});

