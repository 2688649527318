
import {  IonToolbar, IonSegment, IonSegmentButton, 
    IonLabel, SegmentCustomEvent  } from '@ionic/vue';
import { defineComponent } from 'vue';
import { AppSegment } from '@/core/interfaces/AppComponents';
import AppIcon from '@/uicomponents/AppIcon.vue'


export default defineComponent({
    name: 'AppSegments',

    components:{
        IonToolbar,
        IonSegment,
        IonSegmentButton,
        IonLabel,
        AppIcon
    },

    props:{
        segments:{
            type:  Array<AppSegment>,
            required: true,
        },
        active:{
            type: String,
            required: true
        }
    },

    emits: ['onChange'],

    setup(props, context){      
        const handleChangeSegment = (event:SegmentCustomEvent ) => {
            if(event.detail.value)
                context.emit('onChange', event.detail.value)
        };
        return {
            handleChangeSegment
        }
    }   
})
