
import {
  IonContent, IonHeader, IonPage, IonText,
  IonGrid, IonCol, IonRow, isPlatform} from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import TheManagerHeader from '@/view_components/TheManagerHeader.vue';
//import TheMobileMenuToggle from '@/view_components/TheMobileMenuToggle.vue';
import useAuthStore from '@/core/stores/authStore';
import TheUserMenu from '@/view_components/TheUserMenu.vue';
import { UserMenu } from '@/core/interfaces/User';
//import TheUserProfile from '@/view_components/TheUserProfile.vue';

export default defineComponent({
  name: 'UserPage',

  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonGrid,
    IonCol,
    IonRow,
    TheManagerHeader,
    IonText,
    TheUserMenu,
   // TheUserProfile
},

  setup() {
    const ismobile = isPlatform('mobile')
    const urlpage= process.env.VUE_APP_URL_API
    const versionmanager = process.env.VUE_APP_VERSION
    const usermenu=[
      {type: "section", id: 'account', title: 's_account', icon:'', url: ''},
      {type: "menu", id: 'info', title: 'm_info', icon: 'perm_identity', url:'/sso/user_zone/profile'},
      {type: "menu", id: 'password', title: 'm_password', icon: 'lock', url: '/sso/user_zone/change_password'},
      {type: "menu", id: 'suscription', title: 'm_suscription', icon: 'receipt_long', url: '/sso/user_zone/social_accounts'},
      {type: "line", id: 'line1', title:'', icon:'', url:''},
    //  {type: "section", id: 'users', title: 'Usuarios'},
    //  {type: "menu", id: 'adminusers', title: 'Administración de usuarios', icon: 'perm_contact_calendar'},
    //  {type: "line", id: 'line2'},
    //  {type: "section", id: 'mydevices', title: 'Mis pantallas'},
    //  {type: "menu", id: 'devices', title: 'Dispositivos', icon: 'devices'},
    //  {type: "line", id: 'line3'},
      {type: "menu", id: 'logout', title: 'm_logout', icon: 'logout', url: ''}
    ]

    //const ionRouter = useIonRouter();
    const menuselect = ref<UserMenu>(usermenu[1]);

    const set_menuselect = async (value: UserMenu )=> {
      if(value.type == 'menu'){
        if(value.id == 'logout'){
          await authStore.logout();
          //ionRouter.navigate('/auth', 'forward');
        }else{
          menuselect.value = value
          //const objpage = await AuthService.getPage(value.url)
          //document.querySelector('iframe')?.setAttribute("src", objpage)
        }
      }
    }
    //const route = useRoute()
    //const { menuitem } = route.params;
    /*if (menuitem != undefined ){
      menuselect.value  = typeof menuitem == 'string' ? menuitem : menuitem[0] ? menuitem[0] : 'info';
    }*/
    const authStore = useAuthStore();
    const currentuser = computed(() =>{ return authStore.getUser });
    return {
      ismobile,
      usermenu,
      menuselect,set_menuselect,
      currentuser,
      urlpage,versionmanager
    }
  }
});
