
import {
    IonHeader,
    IonToolbar, IonTitle,
    IonButtons, IonButton
} from '@ionic/vue';
import { close } from 'ionicons/icons';
import { defineComponent } from 'vue';
import AppIcon from '@/uicomponents/AppIcon.vue'

export default defineComponent({
    name: 'AppModal',

    components: {
        IonHeader,
        IonToolbar, IonTitle,
        IonButtons, IonButton,
        AppIcon
    },

    props: {
        icon: {
            type: String,
            required: false
        },
        backButton: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: true
        }
    },

    emits: ['onClose'],

    setup(props, context) {
        const handleCloseModal = ()  =>{
            context.emit('onClose')
        }
        return {
            close,
            handleCloseModal
        }
    }
})
