
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonIcon, IonButton, 
    IonProgressBar, AccordionGroupCustomEvent, isPlatform } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { close, chevronUp } from 'ionicons/icons';


export default defineComponent({
    name: 'AppBottomSheet',

    components:{
        IonAccordionGroup, IonAccordion,
        IonItem, IonLabel, IonIcon, IonButton,
        IonProgressBar
    },

    props:{
        title:{
            type: String, 
            required: true
        },
        enableClose:{
            type: Boolean,
            required: false,
            default: false
        },
        color:{
            type: String,
            required: false,
            default: 'accent'
        },
        progress:{
            type: Number,
            required: false,
            default: -1
        }
    },

    emits:["onClose"],

    setup(){
        const ismobile = isPlatform('mobile')  
        const sheet_is_open = ref(false);
        const change_sheet_open = (ev: AccordionGroupCustomEvent) => {
            sheet_is_open.value = ev.detail.value != undefined
        };
        return {
            ismobile,
            close, chevronUp, //icon
            sheet_is_open, change_sheet_open
        }
    }
})
