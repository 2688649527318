
import { defineComponent, onMounted,  ref } from 'vue';
import { DonutChartProps } from '@/core/interfaces/Charts';
import Chart from 'chart.js/auto'
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'TheChartDonut',

    props:{
        config:{
            type: Object as ()=> DonutChartProps,
            required: true
        },
		canvas_width:{
			type: String,
			default: '400'
		},
		lang:{
			type: String,
			required: true
		}
    },
    watch: {
        $props: {
			handler() {
				this.updateChart()
			},
			deep: true,
			immediate: true,
        },
	},

    setup(props){
		const { t } = useI18n({ useScope: 'global' })
		const donutchart = ref();
        const loaded = ref(false)
		var donutObj:Chart<'doughnut',number[], string>
		const plugin_empty = {
			id: 'doughnutEmpty',
			afterDraw(chart:Chart, args:any, options:any) {
				const {is_empty} = options
				const radiusDecrease = 56;
				if (is_empty) {
					const {chartArea: {left, top, right, bottom}, ctx} = chart;
					const centerX = (left + right) / 2;
					const centerY = (top + bottom) / 2;
					const r = Math.min(right - left, bottom - top) / 2;

					ctx.beginPath();
					ctx.lineWidth =  56;
					ctx.strokeStyle = '#B8BDC3';
					ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
					ctx.stroke();
				}
			}
		};
		const plugin_label={
			id: 'doughnutLabel',
			beforeDatasetsDraw(chart:Chart, args:any, pluginOptions:any){
				const {percent} = pluginOptions
				// eslint-disable-next-line
				const {ctx, data} =chart
				ctx.save()
				const xCoor = chart.getDatasetMeta(0).data[0].x
				const yCoor = chart.getDatasetMeta(0).data[0].y 
				ctx.font = 'bold 20px sans-serif'
				ctx.fillStyle = '#757D8A'
				ctx.textAlign = 'center'
				ctx.textBaseline = 'middle'
				ctx.fillText(t(props.config.centerText) , xCoor, yCoor -30)
				/*percentage */
				ctx.font = 'bold 50px sans-serif'
				ctx.fillStyle = '#757D8A'
				ctx.textAlign = 'center'
				ctx.textBaseline = 'middle'
				ctx.fillText(percent + ' %', xCoor, yCoor +20)
			}
		}

		const chart_data={
			labels: props.config.labels.map(x=> t(x)),
			datasets: [{                
				data: props.config.data,
				backgroundColor: ['#079488', '#dc9a38', '#6d2679' ],
			}],      
		}
        const updateChart = ()=>{
            if(loaded.value){
				donutObj.data.labels= props.config.labels.map(x=> t(x))
                if(props.config.isEmpty){
                    // eslint-disable-next-line
                    //@ts-ignore
                    donutObj.options.plugins!.doughnutLabel.percent = 0
                    // eslint-disable-next-line
                    //@ts-ignore
                    donutObj.options.plugins!.doughnutEmpty.is_empty= true
                    donutObj.update('reset')
                }else{
                    donutObj.data.datasets[0].data= props.config.data
                    // eslint-disable-next-line
                    //@ts-ignore
                    donutObj.options.plugins!.doughnutLabel.percent = props.config.percent 
                    // eslint-disable-next-line
                    //@ts-ignore
                    donutObj.options.plugins!.doughnutEmpty.is_empty= false
                    donutObj.update('active')
                }
            }
        }
		const handleTooltip = (context:any) =>{
			return context.formattedValue + ' GB'
		}
		onMounted(() =>{
			//Chart.register([plugin_label])
			donutObj =new Chart(donutchart.value as HTMLCanvasElement,{
				type: 'doughnut',
				data: chart_data,
				options: {
					cutout: '70%',
					radius: '90%',
					responsive: false,
					elements:{
						arc:{
							borderWidth: 0,
						//	borderRadius: 20,
						//	hoverOffset: 5,
							//spacing: -15,                            
						}
					} ,
					plugins:{
						legend:{
							position: 'bottom',   
						},
						tooltip:{
							displayColors: false,
							callbacks: {
								label :handleTooltip
							}
						}, 
                        // eslint-disable-next-line
						//@ts-ignore 
						doughnutLabel:{
							percent: 0
						},
						doughnutEmpty:{
							is_empty: true
						}
					}
				},
				plugins:[plugin_label, plugin_empty]
			});
            loaded.value= true
		})
        return{
            donutchart,
            updateChart
        }
    }
})
