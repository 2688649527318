import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButtonIcon = _resolveComponent("AppButtonIcon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_AppButtonIcon, {
      id: 'btnorder-'+_ctx.id,
      color: "medium",
      class: _normalizeClass('custom'),
      icon: "filter_list"
    }, null, 8, ["id"]), [
      [_directive_tooltip, _ctx.$t('tooltip.order')]
    ]),
    _createVNode(_component_ion_popover, {
      trigger: 'btnorder-'+_ctx.id,
      "trigger-action": "click",
      "dismiss-on-select": true,
      "keep-contents-mounted": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order_types, (option) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: option.key,
                    button: true,
                    class: _normalizeClass(['item-menu-order', option.key == _ctx.selected ? 'order-selected': null]),
                    onClick: ($event: any) => (_ctx.handleClick(option.key))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(option.value)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["trigger"])
  ], 64))
}