
import { PlanInfo } from '@/core/interfaces/User';
import axios from 'axios';
import { todayUnix } from '../utils';
import HttpService from './HttpService';

const TOKEN_KEY = "token";
export const SDK_URL = process.env.VUE_APP_URL_API+'/sso/login/sdk'

export const AuthService = {

    jwtDecrypt(token:string) {
        const base64Url = token.split(".")[1];
        const jsonPayload = JSON.parse(atob(base64Url))
        return jsonPayload;
    },

    tokenExpired(dateexp:number):boolean{
        if(dateexp < todayUnix()){
            localStorage.removeItem(TOKEN_KEY);
            axios.defaults.headers.common= {};
            return true
        }
        return false
    },

    getToken():string{
        const token_value =  localStorage.getItem(TOKEN_KEY);
        return token_value ? token_value: ''
    },

    saveToken(accessToken: string) {
        localStorage.setItem(TOKEN_KEY, accessToken);
        axios.defaults.headers.common[ "Authorization"] = accessToken;
    },

    getLang():string{
        const value =  localStorage.getItem('lang');
        return value ? value: 'en'
    },
    saveLang(lang: string){
        localStorage.setItem('lang', lang);
    },

    getPage(url:string){
        return HttpService.getSrc(url)
    },

    async getLastConnection(){
        const response= await HttpService.jsonGet('/sso/last_login/auth');
        const last = todayUnix() - response.last_session < 18000 ? response.last_session_2nd :response.last_session
        return last
    },

    async getPlanInfo():Promise<PlanInfo>{
        const response= await HttpService.jsonPost('/manager/user/plan-info', {},false);
        return response
    },

    async logout(){
        const response= await HttpService.jsonPost('/sso/logout_token',{}, true);
        if(response.logout){
            localStorage.removeItem(TOKEN_KEY);
            axios.defaults.headers.common= {};
        }
        return response.logout
    },
};

export default AuthService;