import { baseImg, Content, ContentUpdate, ContentUses, EditorExports, Folder } from '@/core/interfaces/Content';
import HttpService, { StdResponse } from './HttpService';


export const ContentService = {
    async getFolders(): Promise<Folder[]>{        
        const response = await HttpService.jsonPost('/manager/folder/list', {});
        return response ? response : [];
    },

    async folderCreate(name: string): Promise<any> {
        const payload={
            'name': name
        }
        const response = await HttpService.jsonPost('/manager/folder/create', payload);
        return response;
    },
    async folderRename(foldername:string, new_name:string): Promise<any> {
        const payload={
            "old_name": foldername,
            "new_name": new_name
        }
        const response = await HttpService.jsonPost('/manager/folder/rename', payload);
        return response;
    },

    async getMaxFileSize(): Promise<any> {
        const response = await HttpService.jsonPost('/manager/content/getmaxfilesize', {});
        return response ? response : {};
    },

    async getEditorExports(): Promise<EditorExports[]> {
        const response = await HttpService.jsonPost('/manager/editor-exports', {});
        return response ? response : [];
    },

    async getContents(): Promise<Content[]> {
        const response = await HttpService.jsonPost('/manager/content/list', {});
        return response ? response : [];
    },
    
    async getContentsEditor(): Promise<Content[]> {
        const response = await HttpService.jsonPost('/manager/content/list-editor', {});
        return response ? response : [];
    },

    async importFromEditor(file:string[]): Promise<{"Created": Content[], "Errors": string[]}>{
        const payload={
            'filename': file
        }
        const response = await HttpService.jsonPost('/manager/content/import-editor', payload);
        return response;
    },

    async uploadContent(file:File, folder: string, onUploadProgress: any,ctrl:AbortController): Promise<StdResponse<any>>{
        const form_data = new FormData();
        form_data.append("file", file);
        form_data.append('folder',folder);
        const response = await HttpService.formdataPost('/manager/content/upload-file', form_data,onUploadProgress, ctrl);
        return response.data;
    },

    async updateContentFile(file:File, id_content:number, onUploadProgress: any): Promise<StdResponse<any>>{
        const form_data = new FormData();
        form_data.append("file", file);
        form_data.append("id_content", id_content.toString());
        const response = await HttpService.formdataPost('/manager/content/update-file', form_data,onUploadProgress, HttpService.getAbortCtrl());
        return response.data;
    },

    async updateContentInfo(id_content: number, data:ContentUpdate){
        const payload={
            'id_content': id_content,
            'title': data.title,
            'description': data.description,
            'mute': data.mute,
            'validity_start': data.validity_start,
            'validity_end': data.validity_end,
            'validity_days': data.validity_days,
            'duration': data.duration,
            'transition': data.transition
        }
        const response = await HttpService.jsonPost('/manager/content/update-info', payload);
        return response;

    },

    async getContentUses(id_contents: number[]): Promise<ContentUses[]>{
        const payload={
            "id_contents": id_contents
        }
        const response = await HttpService.jsonPost('/manager/content/uses', payload);
        return response;
    },

    async deleteContents(id_contents: number[], folders: string[]): Promise<any>{
        const payload={
            "id_contents": id_contents,
            "folders": folders
        }
        const response = await HttpService.jsonPost('/manager/content/delete', payload);
        return response;
    },

    downloadFile(content:Content){
        const file_name =content.title+'.'+content.extension
        //const url= baseImg + content.local_path
        const url=  content.local_path
        //console.log(baseImg)
        //console.log(content.local_path)
        HttpService.downloadFile(url, file_name)
    },


};

export default ContentService;
 