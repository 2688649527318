
import { IonItem, IonAvatar, IonLabel,
   isPlatform, IonItemDivider
} from '@ionic/vue';
import { defineComponent, computed } from 'vue';
//import TheManagerHeader from '@/view_components/TheManagerHeader.vue';
//import TheMobileMenuToggle from '@/view_components/TheMobileMenuToggle.vue';
import useAuthStore from '@/core/stores/authStore';
import AppIcon from '@/uicomponents/AppIcon.vue'
import { UserMenu } from '@/core/interfaces/User';

export default defineComponent({
  name: 'TheUserMenu',

  components: {
    IonItem,
    IonAvatar, IonLabel,
    IonItemDivider,
    //TheMobileMenuToggle,
    AppIcon
  },

  props:{
      selected:{
          type: String,
          required: true
      },
      menu:{
        type: Array<UserMenu>,
        required: true
      }
  },

  emits: [ 'onMenuClick'],

  setup(props, context) {
    const ismobile = isPlatform('mobile')

    const authStore = useAuthStore();
    const currentuser = computed(() =>{ return authStore.getUser });

    const handleClick = (key:any) =>{
        context.emit('onMenuClick', key)
    };
    return {
      ismobile,
      currentuser,
      handleClick
    }
  }
});
