
import { defineComponent } from 'vue';
import { IonLabel,  IonList, IonItem, IonPopover, IonContent} from '@ionic/vue';
import { order_types } from '@/core/interfaces/Content';
import AppButtonIcon from '@/uicomponents/AppButtonIcon.vue';

export default defineComponent({
    name: 'TheContentMenuOrder',

    components: {
        IonLabel, 
        IonList, IonItem,
        IonPopover, IonContent,
        AppButtonIcon
    },
    props:{
        id:{
            type: String,
            required: true
        },
        selected:{
            type: String,
            required: true
        }
    },

    emits: [ 'onMenuClick'],

    setup(props, context){
        const handleClick = (key:string) =>{
            context.emit('onMenuClick', key)
        };

        return{
            order_types,
            handleClick
        }
    }
})
