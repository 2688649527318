import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, { class: "ion-no-border modal-header" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.backButton)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  slot: "start",
                  fill: "clear",
                  onClick: _ctx.handleCloseModal
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AppIcon, { icon: "arrow_back" })
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.icon)
              ? (_openBlock(), _createBlock(_component_AppIcon, {
                  key: 1,
                  slot: "start",
                  icon: _ctx.icon,
                  class: "icon-start"
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_title, { slot: "start" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, {
              slot: "end",
              class: _normalizeClass(['close-btn'])
            }, {
              default: _withCtx(() => [
                (!_ctx.backButton)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      slot: "icon-only",
                      fill: "clear",
                      color: "medium",
                      onClick: _ctx.handleCloseModal
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AppIcon, { icon: "close" })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "toolbar")
      ]),
      _: 3
    }),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}